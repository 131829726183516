import React from 'react';
import * as Yup from 'yup';
import { faLink, faCheck, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { faScrewdriverWrench, faBoxOpen } from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import formatNumber from '../../helpers/formatNumber';
import formatCurrency from '../../helpers/formatCurrency';
import { colors, Div, fonts, spaces } from '../../../styles/style';
import CheckboxAutoCode from '../../../components/Checkbox/CheckboxAutoCode';
import { Paragraph } from '../../../components/Text/Text';
import Button from '../../../components/Button/Button';
import ItemSelect from '../../../components/Select/ItemSelect';
import { idReference as idReferenceYup, code } from '../schema';
import formatBdi from '../../helpers/formatBdi';

const itemSchemaV2 = schemaParams =>
  Yup.object().shape({
    code,
    type: Yup.string().nullable(),
    idCostCenter: Yup.object().nullable(),
    idRefurbishGroup: Yup.object().nullable(),
    idSupplier: Yup.object().nullable(),
    ...(schemaParams?.hasParent ? { idParent: idReferenceYup } : {}),
    link: Yup.string().nullable()
  });

const compositionSchemaV2 = Yup.object();

const compositionInlineFieldsMapping = ({
  idCompany,
  refurbishItemTypeEnum,
  handleCancel,
  handleConfirm,
  type,
  isTemplate,
  idReference,
  isLibrary
}) => values => {
  const { price, quantity, idItem } = values;

  return {
    code: {
      name: 'Código',
      type: 'text',
      placeholder: `123`,
      md: 2,
      disabled: !isLibrary && !!idItem,
      id: 'add-item-code-input'
    },
    icon: {
      type: 'icon',
      icon: type === refurbishItemTypeEnum.product ? faBoxOpen : faScrewdriverWrench,
      fontSize: spaces.space2,
      md: 1,
      style: {
        paddingTop: spaces.space2,
        display: 'flex',
        placeContent: 'center',
        color: colors.neutral400
      }
    },
    name: {
      name: 'Item',
      type: 'custom2',
      placeholder: 'Pesquise na biblioteca ou adicione um item',
      md: 7,
      id: 'add-item-name-input',
      // eslint-disable-next-line react/prop-types
      Component: (value, setField, formState) => {
        const itemValue = {
          name: formState?.values?.name || '',
          id: formState?.values?.idItem || null,
          type
        };

        return (
          <ItemSelect
            value={itemValue}
            onChange={(val, key, _, object) => {
              return newValue => {
                if (key === 'idItem') {
                  // When selecting from dropdown
                  const selectedItem = newValue;
                  if (selectedItem) {
                    setField('name')(object.label);
                    setField('code')(object.code);
                    setField('idUnit')(object.idUnit);
                    setField('price')(object.price);
                    setField('idItem')(object.value);
                  }
                } else {
                  setField('name')(newValue);
                  setField('idItem')(null);
                  setField('code')(null);
                  setField('idUnit')(null);
                  setField('price')(null);
                }
              };
            }}
            initEdit={!formState?.values?.idItem}
            isTemplate={isTemplate}
            idCompany={idCompany}
            placeholder={`Pesquise ou adicione um ${type === refurbishItemTypeEnum.product ? 'produto' : 'serviço'}`}
            footerOptions="composition-item"
            sendFullObject
            fullWidth
            alwaysInput
            useEditContainer={false}
            extraPropsOnOptions={['code', 'idUnit', 'price']}
          />
        );
      }
    },
    quantity: {
      name: 'Quantidade',
      type: 'number',
      placeholder: 'Quantidade',
      defaultValue: '1',
      maskOptions: { allowNegative: true },
      parseFunc: val => formatNumber(val),
      md: 3
    },
    idUnit: {
      name: 'Unidade',
      type: 'select',
      placeholder: 'Un.',
      model: 'unit',
      modelOptions: {
        where: { idCompany, [isTemplate ? 'idTemplate' : 'idRefurbish']: idReference },
        order: [['name', 'asc']]
      },
      allowCustomOptions: true,
      allowClear: true,
      md: 3,
      className: 'footer-form',
      disabled: !isLibrary && !!idItem
    },
    price: {
      name: 'Preço unit.',
      type: 'currency',
      placeholder: 'R$ 0,00',
      defaultValue: '0,00',
      maskOptions: { allowNegative: true },
      parseFunc: val => formatNumber(val),
      className: 'footer-form',
      md: 3
    },
    total: {
      name: 'Total',
      type: 'custom',
      placeholder: 'R$ 0,00',
      defaultValue: 'R$ 0,00',
      md: 3,
      style: { paddingRight: '4px', paddingLeft: '4px' },
      Component: () => (
        <Div
          height={spaces.space4}
          $backgroundColor={colors.neutral75}
          padding={`0 ${spaces.space1}`}
          $borderRadius={spaces.space0}
          border={`1px solid ${colors.neutral100}`}
        >
          <Paragraph color={colors.neutral400} weight={fonts.weight400}>
            {formatCurrency(formatNumber(quantity || 0) * formatNumber(price || 0), { currencySymbol: 'R$' })}
          </Paragraph>
        </Div>
      )
    },
    actions: {
      type: 'custom',
      md: 2,
      disabled: true,
      style: { alignSelf: 'start', paddingTop: spaces.space2 },
      Component: () => {
        return (
          <Div>
            <Button id="add-purchase-item-button" type="primary" size="2xl" onClick={() => handleConfirm(values)}>
              <FontAwesomeIcon color={colors.white} icon={faCheck} />
            </Button>
            <Button type="danger" text onClick={handleCancel} id="cancel composition-item-button">
              <FontAwesomeIcon color={colors.neutral500} size="lg" icon={faTrashCan} />
            </Button>
          </Div>
        );
      }
    }
  };
};

const compositionMappingV2 = ({
  isTemplate,
  isMobile,
  refurbishItemTypeEnum,
  idReference,
  idCompany,
  isLibrary,
  isEdit,
  checkedValue,
  setCheckedValue,
  onlyLibraryFields,
  isSinapi,
  parentIsComposition,
  isCustomer,
  linkedItem,
  columnsToShow,
  readOnly
}) => values => {
  const model = isTemplate ? 'templateItem' : 'refurbishItems';

  const allFields = {
    idParent: {
      name: 'Nível/Subnível',
      type: 'treeSelect',
      displayStringAs: 'name',
      placeholder: 'Nível',
      model,
      startHideOnMobile: true,
      shouldDisplayWhen: !isLibrary && !onlyLibraryFields && !parentIsComposition && !isCustomer && !readOnly,
      modelOptions: {
        attributes: ['name', 'id', 'type'],
        where: {
          isActive: true,
          type: refurbishItemTypeEnum?.parent,
          idParent: null,
          [isTemplate ? 'idTemplate' : 'idRefurbish']: idReference
        },
        include: [
          {
            model,
            as: 'children',
            attributes: ['name', 'id', 'type'],
            required: false,
            where: {
              isActive: true,
              type: refurbishItemTypeEnum?.parent
            }
          }
        ],
        order: [['order']]
      }
    },
    code: {
      name: 'Código',
      dataIndex: 'code',
      type: ['text', ...(!isEdit ? ['custom'] : [])],
      textProps: {
        property: 'code',
        placeholder: `ABC123`,
        disabled: isEdit ? linkedItem : checkedValue
      },
      customProps: {
        property: 'autoCode',
        Component: () => (
          <Div $fullWidth margin={`0 0 0 ${spaces.space0}`}>
            <CheckboxAutoCode
              id="form-auto-code-checkbox"
              value={checkedValue}
              setCheckedValue={setCheckedValue}
              paddingTop="16px"
              noMargin
            />
          </Div>
        )
      }
    },
    quantity: {
      dataIndex: 'quantity',
      name: 'Quantidade prevista',
      type: 'number',
      startHideOnMobile: true,
      decimalCount: 0,
      placeholder: '1',
      clearable: true,
      shouldDisplayWhen: !isLibrary && !onlyLibraryFields
    },
    idUnit: {
      dataIndex: 'idUnit',
      name: 'Unidade',
      type: 'select',
      placeholder: 'Un.',
      model: 'unit',
      modelOptions: {
        where: { idCompany, [isTemplate ? 'idTemplate' : 'idRefurbish']: idReference },
        order: [['name', 'asc']]
      },
      allowCustomOptions: true,
      clearable: true,
      disabled: !!linkedItem
    },
    idCostCenter: {
      dataIndex: 'idCostCenter',
      name: 'Categoria',
      type: 'select',
      placeholder: 'Categoria',
      model: 'costCenter',
      clearable: true,
      modelOptions: { onlyMine: true, where: { idCompany: idCompany || null }, order: [['name']] },
      allowCustomOptions: true,
      shouldDisplayWhen: !onlyLibraryFields
    },
    idClass: {
      dataIndex: 'idClass',
      name: 'Classe',
      type: 'select',
      placeholder: 'Selecione',
      model: 'itemClass',
      propertyAlias: 'class',
      modelOptions: { onlyMine: true, order: [['name']] },
      clearable: true,
      disabled: !!linkedItem,
      extraPropsOnOptions: ['code']
    },
    idRefurbishGroup: {
      dataIndex: 'idRefurbishGroup',
      name: 'Grupo',
      type: 'select',
      displayStringAs: 'name',
      placeholder: 'Grupo',
      model: 'refurbishGroup',
      modelOptions: { onlyMine: true, order: [['name']] },
      allowCustomOptions: true,
      clearable: true,
      shouldDisplayWhen: !onlyLibraryFields,
      startHideOnMobile: true
    },
    idSupplier: {
      dataIndex: 'idSupplier',
      name: 'Fornecedor',
      type: 'select',
      model: 'supplier',
      modelOptions: { onlyMine: true, order: [['name']] },
      aliasOptions: ({ label }) => label,
      placeholder: 'Selecione',
      clearable: true,
      shouldDisplayWhen: !onlyLibraryFields,
      allowCustomOptions: true,
      startHideOnMobile: true
    },
    ...(isSinapi && {
      state: {
        name: 'Estado',
        type: 'text',
        readOnly: true,
        startHide: true
      },
      monthReference: {
        name: 'Mês de referência',
        type: 'text',
        readOnly: true,
        startHide: true,
        formatter: val => (val ? dayjs(val).format('MM-YYYY') : null)
      },
      priceWithSocialCharges: {
        name: 'Desoneração',
        type: 'text',
        readOnly: true,
        startHide: true,
        formatter: val => (val ? 'Desonerado' : 'Não desonerado')
      },
      originPrice: {
        name: 'Origem do preço',
        type: 'text',
        readOnly: true,
        startHide: true,
        placeholder: '-'
      }
    }),
    description: {
      dataIndex: 'description',
      name: isMobile ? 'Descrição' : '',
      type: 'textarea',
      fullWidth: true,
      titleLabel: true,
      placeholder: 'Adicione uma descrição...',
      shouldDisplayWhen: !onlyLibraryFields && (!isCustomer || values?.description)
    }
  };

  if (isCustomer) {
    return Object.entries(allFields).reduce((acc, [key, value]) => {
      if (columnsToShow?.[value?.dataIndex] === true || value?.dataIndex === 'description') {
        acc[key] = value;
      }
      return acc;
    }, {});
  }

  if (isMobile) {
    const mobileFields = {};
    const mobileHiddenFields = {};
    const { description, ...rest } = allFields;

    Object.entries(rest).forEach(([key, value]) => {
      if (!value.startHideOnMobile) {
        mobileFields[key] = value;
      } else {
        mobileHiddenFields[key] = value;
      }
    });

    Object.entries(mobileHiddenFields).forEach(([key, value]) => {
      mobileFields[key] = value;
    });

    mobileFields.description = description;

    return mobileFields;
  }

  return allFields;
};

const itemMappingV2 = ({
  id,
  isTemplate,
  isMobile,
  refurbishItemTypeEnum,
  idReference,
  type,
  idCompany,
  linkedItem,
  isLibrary,
  checkedValue,
  setCheckedValue,
  onlyLibraryFields,
  parentType,
  isCustomer,
  columnsToShow,
  readOnly,
  isSinapi
}) => values => {
  const model = isTemplate ? 'templateItem' : 'refurbishItems';
  const isEditing = Boolean(id);
  const showLevel = !isLibrary && parentType !== refurbishItemTypeEnum.composition && !isCustomer && !readOnly;

  const allFields = {
    idParent: {
      dataIndex: 'idParent',
      name: 'Nível/Subnível',
      type: 'treeSelect',
      displayStringAs: 'name',
      placeholder: 'Nível',
      model,
      modelOptions: {
        attributes: ['name', 'id', 'type'],
        where: {
          isActive: true,
          type: refurbishItemTypeEnum?.parent,
          idParent: null,
          [isTemplate ? 'idTemplate' : 'idRefurbish']: idReference
        },
        include: [
          {
            model,
            as: 'children',
            attributes: ['name', 'id', 'type'],
            required: false,
            where: {
              isActive: true,
              type: refurbishItemTypeEnum?.parent
            }
          }
        ],
        order: [['order']]
      },
      shouldDisplayWhen: !isLibrary && !onlyLibraryFields && showLevel
    },
    code: {
      dataIndex: 'code',
      name: 'Código',
      type: ['text', ...(!isEditing ? ['custom'] : [])],
      textProps: {
        property: 'code',
        placeholder: `ABC123`,
        disabled: (checkedValue && !isEditing) || (!isLibrary && linkedItem)
      },
      customProps: {
        property: 'autoCode',
        Component: () => (
          <Div $fullWidth margin={`0 0 0 ${spaces.space0}`}>
            <CheckboxAutoCode
              id="form-auto-code-checkbox"
              value={checkedValue}
              setCheckedValue={setCheckedValue}
              paddingTop="16px"
              noMargin
            />
          </Div>
        )
      }
    },
    quantity: {
      dataIndex: 'quantity',
      name: 'Quantidade prevista',
      type: 'number',
      decimalCount: 0,
      placeholder: '1',
      clearable: true,
      shouldDisplayWhen: !isLibrary && !onlyLibraryFields
    },
    idUnit: {
      dataIndex: 'idUnit',
      name: 'Unidade',
      type: 'select',
      placeholder: 'Selecione',
      model: 'unit',
      modelOptions: {
        where: { idCompany, [isTemplate ? 'idTemplate' : 'idRefurbish']: idReference },
        order: [['name', 'asc']]
      },
      clearable: true,
      allowCustomOptions: true,
      disabled: !!linkedItem
    },
    price: {
      dataIndex: 'price',
      name: 'Custo unitário',
      type: 'currency',
      prefix: 'R$',
      placeholder: '0,00',
      defaultValue: '0,00',
      shouldDisplayWhen: !onlyLibraryFields,
      ...(isLibrary &&
        isEditing && {
          nameTooltip:
            'Alterar o custo unitário na biblioteca não atualizará automaticamente esse valor ' +
            'nos orçamentos onde o item já está inserido.'
        })
    },
    bdi: {
      dataIndex: 'bdi',
      name: 'BDI',
      type: 'text',
      mask: 'number',
      placeholder: '0,00',
      formatter: val => `${formatCurrency(val)}%`,
      parseFunc: val => formatBdi(val),
      allowNegative: true,
      shouldDisplayWhen: !isLibrary && !onlyLibraryFields
    },
    totalPrice: {
      dataIndex: 'totalPrice',
      name: 'Preço unitário',
      type: 'currency',
      prefix: 'R$',
      readOnly: true,
      value: formatCurrency((1 + formatNumber(values?.bdi || 0) / 100) * formatNumber(values?.price || 0)),
      placeholder: '0,00',
      defaultValue: '0,00',
      nameTooltip: 'Custo unitário x BDI',
      maskOptions: { allowNegative: true },
      shouldDisplayWhen: !isLibrary && !onlyLibraryFields
    },
    idSupplier: {
      dataIndex: 'idSupplier',
      name: 'Fornecedor',
      type: 'select',
      model: 'supplier',
      modelOptions: { onlyMine: true, order: [['name']] },
      aliasOptions: ({ label }) => label,
      placeholder: 'Selecione',
      clearable: true,
      allowCustomOptions: true,
      shouldDisplayWhen: !onlyLibraryFields
    },
    idCostCenter: {
      dataIndex: 'idCostCenter',
      name: 'Categoria',
      type: 'select',
      placeholder: 'Selecione',
      model: 'costCenter',
      clearable: true,
      modelOptions: { onlyMine: true, where: { idCompany: idCompany || null }, order: [['name']] },
      allowCustomOptions: true,
      shouldDisplayWhen: !onlyLibraryFields
    },
    idRefurbishGroup: {
      dataIndex: 'idRefurbishGroup',
      name: 'Grupo',
      type: 'select',
      displayStringAs: 'name',
      placeholder: 'Selecione',
      model: 'refurbishGroup',
      modelOptions: { onlyMine: true, order: [['name']] },
      allowCustomOptions: true,
      clearable: true,
      shouldDisplayWhen: !onlyLibraryFields
    },
    brand: {
      dataIndex: 'brand',
      name: 'Marca',
      type: 'text',
      placeholder: 'Digite o nome da marca',
      clearable: true,
      shouldDisplayWhen: type === refurbishItemTypeEnum.product && !onlyLibraryFields
    },
    link: {
      dataIndex: 'link',
      name: 'Link',
      type: 'text',
      placeholder: `Insira o link do ${type === refurbishItemTypeEnum.product ? 'produto' : 'serviço'}`,
      icon: faLink,
      shouldDisplayWhen: !onlyLibraryFields
    },
    height: {
      dataIndex: 'height',
      name: 'Altura',
      type: 'currency',
      placeholder: '0',
      suffix: 'cm',
      startHide: !onlyLibraryFields && !isCustomer,
      disabled: !!linkedItem,
      shouldDisplayWhen: type === refurbishItemTypeEnum.product
    },
    width: {
      dataIndex: 'width',
      name: 'Largura',
      type: 'currency',
      placeholder: '0',
      suffix: 'cm',
      startHide: !onlyLibraryFields && !isCustomer,
      disabled: !!linkedItem,
      shouldDisplayWhen: type === refurbishItemTypeEnum.product
    },
    length: {
      dataIndex: 'length',
      name: 'Comprimento',
      type: 'currency',
      placeholder: '0',
      suffix: 'cm',
      withoutArrows: true,
      startHide: !onlyLibraryFields && !isCustomer,
      disabled: !!linkedItem,
      shouldDisplayWhen: type === refurbishItemTypeEnum.product
    },
    weight: {
      dataIndex: 'weight',
      name: 'Peso',
      type: 'currency',
      placeholder: '0',
      suffix: 'kg',
      withoutArrows: true,
      startHide: !onlyLibraryFields && !isCustomer,
      disabled: !!linkedItem,
      shouldDisplayWhen: type === refurbishItemTypeEnum.product
    },
    ...(isSinapi && {
      state: {
        name: 'Estado',
        type: 'text',
        readOnly: true,
        startHide: true
      },
      monthReference: {
        name: 'Mês de referência',
        type: 'text',
        readOnly: true,
        startHide: true,
        formatter: val => (val ? dayjs(val).format('MM-YYYY') : null)
      },
      priceWithSocialCharges: {
        name: 'Desoneração',
        type: 'text',
        readOnly: true,
        startHide: true,
        formatter: val => (val ? 'Desonerado' : 'Não desonerado')
      },
      originPrice: {
        name: 'Origem do preço',
        type: 'text',
        readOnly: true,
        startHide: true,
        placeholder: '-'
      },
      subtype: {
        name: 'Subtipo',
        type: 'text',
        readOnly: true,
        startHide: true,
        formatter: val => (val ? val.charAt(0).toUpperCase() + val.slice(1).toLowerCase() : null)
      }
    }),
    description: {
      name: isMobile ? 'Descrição' : '',
      type: 'textarea',
      dataIndex: 'description',
      fullWidth: true,
      titleLabel: true,
      placeholder: 'Adicione uma descrição...',
      shouldDisplayWhen: !onlyLibraryFields && (!isCustomer || !readOnly || values?.description)
    }
  };

  if (isCustomer) {
    return Object.entries(allFields).reduce((acc, [key, value]) => {
      if (columnsToShow?.[value?.dataIndex] === true || value?.dataIndex === 'description') {
        acc[key] = value;
      }
      return acc;
    }, {});
  }

  return allFields;
};

export { itemSchemaV2, itemMappingV2, compositionSchemaV2, compositionMappingV2, compositionInlineFieldsMapping };
