import styled from 'styled-components';
import { breakpoints, colors, fonts, radius, spaces } from '../../styles/style';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: ${props => (props.isForm ? '100%' : '90px')};
  height: ${props => (props.isForm ? spaces.space4 : '27px')};
  padding: 0 calc(${spaces.space1} + ${spaces.space0});
  border-radius: ${radius.radius1};
  ${props => props.isForm && `border: 1px solid ${colors.white};`}
  ${props => props.isForm && `color: ${colors.neutral600};`}
  font-size: ${fonts.sizeSm};
  gap: ${spaces.space0};
  transition: all 0.3s ease-in-out;

  ${props =>
    props.isForm &&
    `
    border: 1px solid ${colors.white};
    color: ${!props.taskTotalHours || props.taskTotalHours === 0 ? colors.neutral400 : colors.neutral600};
  `}

  @media (max-width: ${breakpoints.tablet}) {
    ${props =>
      props.isForm &&
      `
      font-size: ${fonts.sizeMd};

      svg {
        font-size: ${fonts.sizeSm};
      }
    `}
  }

  &:hover {
    border: 1px solid ${colors.neutral500};
    background-color: ${colors.neutral75};
    ${props => props.isForm && `border-color: ${colors.neutral75};`}
  }

  &.ant-dropdown-open {
    background-color: ${colors.white};
    ${props => props.isForm && `border-color: ${colors.neutral100};`}
  }

  .ant-dropdown-menu {
    max-height: 500px;
  }

  .ant-picker-dropdown {
    z-index: 15000;
  }
`;

export const Menu = styled.div`
  min-width: 336px;
  max-height: 416px;
  border-radius: ${radius.radius1};
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${spaces.space8};
  padding: ${spaces.space2} ${spaces.space3} ${spaces.space2} ${spaces.space2};
  overflow: hidden;

  p {
    color: ${colors.neutral600};
    font-weight: 500;
    margin-right: ${props => (props.$offsetTime ? spaces.space6 : spaces.space3)};
  }

  .ant-typography {
    font-size: ${fonts.sizeLg};
    margin-right: auto;
    color: ${colors.neutral600};
  }
`;

export const ResponsibleInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${spaces.space6};
  padding: ${spaces.space2} ${spaces.space2} ${spaces.space2} ${spaces.space2};
  border-top: 1px solid ${colors.neutral100};

  .ant-avatar-circle {
    margin-right: auto;
  }

  svg {
    margin-right: ${spaces.space0};
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${colors.neutral100};
  overflow: hidden;

  p {
    margin: ${spaces.space2};
  }
`;

export const AddHourContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${spaces.space6};
  padding: ${spaces.space2};
  background-color: ${colors.neutral75};
  margin-bottom: ${spaces.space1};
  border-top: 1px solid ${colors.neutral100};
  border-bottom: 1px solid ${colors.neutral100};
`;

export const RightItemsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${spaces.space1} 0;
  ${props => props.dark && `background-color: ${colors.neutral75};`}
  ${props => !props.noBorder && `border-top: 1px solid ${colors.neutral100};`}

  .ant-input {
    width: ${spaces.space9};
    background-color: ${colors.white};
    border: 1px solid ${colors.neutral100};
    text-align: right;
    height: ${spaces.space4};
    font-size: ${fonts.sizeMd};
    color: ${colors.neutral400};
  }

  .ant-picker {
    background-color: ${colors.white};
    width: ${spaces.space10};
    height: ${spaces.space4};
    border-radius: ${radius.radius1};
    color: ${colors.neutral400};
    font-size: ${fonts.sizeMd};
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: ${spaces.space1};
    cursor: pointer;
    height: ${spaces.space4};
    border-color: ${colors.neutral100};
    margin: 0 ${spaces.space1};
  }

  .ant-picker-input input {
    text-align: center;
    font-size: ${fonts.sizeMd};
    color: ${colors.neutral400};
  }

  svg {
    margin: 0 ${spaces.space2} 0 ${spaces.space2};
  }
`;

export const HoursContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 240px;
`;
