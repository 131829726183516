import dayjs from 'dayjs';

import { sumByField } from './helper';
import formatCurrency from './formatCurrency';
import { store } from '../config/redux-store';

const calculatePercentage = (price, total) =>
  total !== 0 ? parseFloat(((parseFloat(price || 0) * 100) / parseFloat(total)).toFixed(10)) : 0;

const calculatePriceByPercentage = (percentage, total) =>
  parseFloat(((parseFloat(percentage || 0) / 100) * parseFloat(total)).toFixed(2));

const sortByDueDate = arr =>
  arr.sort((a, b) => {
    if (dayjs(a.dueDate).isAfter(b.dueDate)) return 1;
    if (dayjs(a.dueDate).isBefore(b.dueDate)) return -1;
    return 0;
  });

const sortByStatus = ({ arr, installmentStatuses }) =>
  arr.sort((a, b) => {
    if (installmentStatuses.allPaid.includes(a.idInstallmentStatus)) return -1;
    if (installmentStatuses.allPaid.includes(b.idInstallmentStatus)) return 1;
    return 0;
  });

const getLastDueDate = arr => {
  if (arr.length === 0) return null;
  return dayjs(arr[arr.length - 1].dueDate);
};

const recalculateInstallmentsPercentages = (installments, _total, quantity) => {
  let totalOfPercentage = 0;

  const newInstallments = installments.map((item, index) => {
    const isLastItemOfArray = index === quantity - 1;
    let percentage = calculatePercentage(item?.price, _total);

    if (!isLastItemOfArray) {
      totalOfPercentage += percentage;
    } else {
      percentage = parseFloat((100 - totalOfPercentage).toFixed(10));
    }

    return { ...item, percentage };
  });

  return sortByDueDate(newInstallments);
};

const calculateInstallmentsPrices = (installments, _total) => {
  let totalPrice = 0;
  const newInstallments = installments.map((item, index) => {
    let price = calculatePriceByPercentage(item.percentage, _total);
    if (index === installments.length - 1) price = _total - totalPrice;
    else totalPrice += price;
    return { ...item, price };
  });
  return sortByDueDate(newInstallments);
};

const remountInstallments = ({
  isEveryInstallmentPaid,
  installments,
  otherData,
  quantity,
  total,
  lastPrice,
  price
}) => {
  const reduxState = store.getState();
  const { installmentStatuses } = reduxState.setup.enums;
  const installmentSortedByStatus = sortByStatus({ arr: installments, installmentStatuses });

  let dueDate = getLastDueDate(installments) || dayjs(otherData?.billingDate || new Date());
  let totalOfPercentage = 0;
  const newArray = Array(quantity).fill(null);
  return newArray.map((_, index) => {
    const isLastItemOfArray = index === quantity - 1;
    const hasInstallment = installmentSortedByStatus[index];
    let newPrice = Math.max(isLastItemOfArray ? lastPrice : price, 0);
    let newDescription = !otherData
      ? hasInstallment?.description
      : `${otherData?.paymentName || 'Sua descrição aqui'} ${hasInstallment?.number || index + 1}/${quantity}`;

    if (installmentStatuses.allPaid.includes(hasInstallment?.idInstallmentStatus)) {
      newPrice = parseFloat(hasInstallment?.price);
      newDescription = hasInstallment?.description;
    }
    let percentage = calculatePercentage(parseFloat(hasInstallment?.originalValue) || newPrice, total);

    if (!isLastItemOfArray || isEveryInstallmentPaid) {
      totalOfPercentage += percentage;
    } else {
      percentage = parseFloat((100 - totalOfPercentage).toFixed(10));
    }

    if (hasInstallment) {
      return {
        ...hasInstallment,
        percentage,
        price: newPrice,
        description: newDescription
      };
    }

    dueDate = dayjs(otherData?.billingDate || undefined).add(index, 'month');

    return {
      idPayment: otherData?.idPayment,
      installmentStatus: {
        name: 'Aguardando'
      },
      percentage,
      price: newPrice,
      idCompany: otherData?.idCompany,
      idInstallmentStatus: installmentStatuses.pendingPayment,
      dueDate,
      description: newDescription
    };
  });
};

const generateInstallments = ({ quantity, total, installments, otherData, resetInstallments = false }) => {
  const reduxState = store.getState();
  const { installmentStatuses } = reduxState.setup.enums;

  const _total = parseFloat(total || 0);
  const paidInstallments =
    installments?.filter(item => installmentStatuses.allPaid.includes(item.idInstallmentStatus)) || [];

  if (paidInstallments.length > quantity) {
    return { error: 'Número de parcelas deve ser maior que a quantidade de parcelas pagas' };
  }

  const installmentsValue = sumByField(installments, 'price', 'originalValue') || 0;

  if ((quantity === installments?.length && Math.abs(installmentsValue - _total) < 0.01) || _total < 0) {
    return recalculateInstallmentsPercentages(installments, _total, quantity);
  }

  if (quantity === installments.length && paidInstallments.length === 0 && !resetInstallments) {
    return calculateInstallmentsPrices(installments, _total);
  }

  const paidValue = sumByField(paidInstallments, 'price', 'originalValue') || 0;

  if (paidValue === _total) return sortByDueDate(paidInstallments);

  const quantityWithoutPaidQuantity = quantity - paidInstallments.length;
  const valueWithoutPaidValue = _total - paidValue;

  const price = parseFloat((valueWithoutPaidValue / quantityWithoutPaidQuantity).toFixed(2));
  const lastPrice = parseFloat((valueWithoutPaidValue - price * (quantityWithoutPaidQuantity - 1)).toFixed(2));

  const isEveryInstallmentPaid = paidInstallments.length === quantity;

  const newArray = remountInstallments({
    isEveryInstallmentPaid,
    installments,
    otherData,
    quantity,
    total: _total,
    lastPrice,
    price
  });

  return sortByDueDate(newArray).map((item, index) => ({ ...item, number: index + 1 }));
};

const calculateRestOfInstallments = ({ totalToDistribute, installments, index }) => {
  if (totalToDistribute <= 0) {
    return installments.slice(index + 1).map(item => ({
      ...item,
      price: 0,
      percentage: 0
    }));
  }
  return installments.slice(index + 1);
};

const calculateInstallmentPercentages = ({ installments, totalValue, isLastInstallment }) => {
  let totalOfPercentage = 0;
  return installments.map((item, i) => {
    let percentage = 0;
    if (i !== installments.length - 1) {
      percentage = calculatePercentage(item.price, totalValue);
      totalOfPercentage += percentage;
    } else {
      percentage = isLastInstallment ? item.percentage : Math.max(100 - totalOfPercentage, 0);
    }
    return { ...item, percentage, number: i + 1 };
  });
};

const recalculateInstallments = ({ current, value, installments }) => {
  if (!current) return null;
  const _installments = [...installments];
  const { index, installment } = current;
  const isLastInstallment = index === _installments?.length - 1;
  _installments[index] = installment;
  const previousInstallments = _installments?.slice(0, index + 1);

  const totalPriceOfPrevious = sumByField(previousInstallments, 'price', 'originalValue') || 0;

  if (totalPriceOfPrevious <= 0) {
    return {
      error: `O valor deve ser maior que 0`
    };
  }

  const totalToDistribute = value - totalPriceOfPrevious;
  const restOfInstallments = calculateRestOfInstallments({ totalToDistribute, installments: _installments, index });

  const _restInstallments =
    totalToDistribute > 0
      ? generateInstallments({
          quantity: restOfInstallments.length,
          total: totalToDistribute,
          installments: restOfInstallments,
          resetInstallments: true
        })
      : restOfInstallments;

  const _newInstallments = calculateInstallmentPercentages({
    installments: previousInstallments.concat(_restInstallments),
    totalValue: value,
    isLastInstallment
  });

  const newInstallmentsTotal = sumByField(_newInstallments, 'price', 'originalValue');
  const difference = Number((newInstallmentsTotal - value).toFixed(2));

  if (difference > 0) {
    return {
      installments: _newInstallments,
      error: `Suas parcelas tem um valor de
        ${formatCurrency(newInstallmentsTotal - value, { currencySymbol: 'R$ ' })}
         a mais que o total de ${formatCurrency(value, { currencySymbol: 'R$ ' })}`
    };
  }

  return _newInstallments;
};

const internalFunctions = {
  calculateInstallmentPercentages,
  calculateRestOfInstallments,
  sortByDueDate,
  recalculateInstallmentsPercentages,
  sortByStatus,
  getLastDueDate,
  calculateInstallmentsPrices,
  remountInstallments
};

export {
  recalculateInstallments,
  generateInstallments,
  calculatePercentage,
  calculatePriceByPercentage,
  internalFunctions
};
