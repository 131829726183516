import {
  faFolderOpen,
  faUserGroup,
  faHandshake,
  faListCheck,
  faPersonDolly,
  faCoins,
  faCartShopping
} from '@fortawesome/pro-duotone-svg-icons';
import { faBoxOpen, faLayerGroup, faScrewdriverWrench, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { faCopy, faEye, faPlus, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { colors, spaces, fonts } from '../../../styles/style';
import {
  nameAsc,
  nameDesc,
  createdAtAsc,
  createdAtDesc,
  topSale,
  priceAsc,
  priceDesc,
  deleteActionList,
  addItem,
  copyLinkButton,
  exportPdf,
  addCompositionItems,
  addComposition,
  useSpecificationTemplate,
  undoReconciliation,
  deleteAction,
  deleteActionV2
} from './_dropdown';
import { generalTask, task } from './stepAndTaskDropdown';
import { instalmentChildren, instalmentItem, instalmentParent, instalmentParentGrouped } from './instalmentDropdown';
import {
  projectList,
  projectItemList,
  opportunityItemList,
  projectListEnd,
  projectKanban,
  opportunityKanban,
  listOpportunityItemArchived,
  listOpportunityItem
} from './projectDropdown';
import { markAsPaid, payment, paymentChildren } from './paymentDropdown';

const styleForDropdown = {
  display: 'flex',
  justifyContent: 'left',
  gap: spaces.space1,
  padding: `${spaces.space1} ${spaces.space1} ${spaces.space1} 0`,
  fontSize: fonts.sizeSm
};

const allDropdown = {
  default: [deleteActionList()],
  sortCustom: [nameAsc, nameDesc, createdAtAsc, createdAtDesc],
  shareLink: [copyLinkButton],
  shareLinkWithWhatsapp: [
    copyLinkButton,
    {
      button: 'shareWhatsappLink',
      buttonParams: {
        text: true,
        children: 'WhatsApp'
      }
    }
  ],
  quickCreateActions: [
    {
      label: 'Projeto',
      id: 'quick-create-project',
      modal: 'templateModal',
      roles: ['project'],
      iconProps: {
        color: colors.neutral400,
        icon: faFolderOpen
      }
    },
    {
      label: 'Financeiro',
      id: 'quick-create-financial',
      roles: ['financial', 'vobipayCharge'],
      key: 'financial',
      iconProps: {
        color: colors.neutral400,
        icon: faCoins
      },
      children: [
        {
          label: 'Pagamento do cliente',
          roles: [
            'financialSupplier',
            'financialOwnBusiness',
            'projectSupplier',
            'projectOwnBusiness',
            'vobipayCharge'
          ],
          modal: 'newPaymentDrawer',
          id: 'payment'
        },
        {
          label: 'Despesa',
          roles: ['financialExpense', 'projectExpense'],
          modal: 'newBillDrawer',
          modalParams: {
            billType: 'expense'
          },
          id: 'expense'
        },
        {
          label: 'Receita',
          roles: ['financialIncome', 'projectIncome'],
          modal: 'newBillDrawer',
          modalParams: {
            billType: 'income'
          },
          id: 'income'
        },
        {
          label: 'Transferência',
          roles: ['financialTransfer'],
          modal: 'newTransferDrawer',
          id: 'transfer'
        },
        {
          label: 'Importar XML',
          roles: ['financialSupplier', 'projectSupplier', 'vobipayCharge', 'financialExpense', 'projectExpense'],
          id: 'import-xml',
          children: [
            {
              label: 'Despesa',
              roles: ['financialExpense', 'projectExpense'],
              modal: 'importXml',
              id: 'expense',
              modalParams: {
                type: 'expense'
              }
            },
            {
              label: 'Pagamento manual',
              roles: ['financialSupplier', 'projectSupplier'],
              modal: 'importXml',
              id: 'payment',
              modalParams: {
                type: 'charge'
              }
            },
            {
              label: 'Vobi Pay',
              roles: ['vobipayCharge'],
              modal: 'importXml',
              id: 'vobipay',
              modalParams: {
                type: 'vobipay'
              }
            }
          ]
        }
      ]
    },
    {
      label: 'Compras',
      id: 'quick-create-purchase',
      roles: ['purchase'],
      key: 'purchase',
      iconProps: {
        color: colors.neutral400,
        icon: faCartShopping
      },
      children: [
        {
          label: 'Solicitação',
          roles: ['purchase'],
          redirectTo: '/profissional/gestao-de-compras/solicitacoes/novo',
          id: 'solicitation'
        },
        {
          label: 'Ordem de compra',
          roles: ['purchase'],
          redirectTo: '/profissional/gestao-de-compras/ordem-de-compras/novo',
          id: 'order'
        },
        {
          label: 'Cotação',
          roles: ['purchase'],
          redirectTo: '/profissional/gestao-de-compras/cotacoes/novo',
          id: 'quote'
        }
      ]
    },
    {
      label: 'Oportunidade',
      id: 'quick-create-opportunity',
      redirectTo: '/profissional/oportunidades/novo',
      roles: ['opportunity'],
      iconProps: {
        color: colors.neutral400,
        icon: faHandshake
      }
    },
    {
      label: 'Tarefa',
      id: 'quick-create-task',
      modal: 'editTaskModal',
      roles: ['tasks'],
      iconProps: {
        color: colors.neutral400,
        icon: faListCheck
      }
    },
    {
      label: 'Cliente',
      id: 'quick-create-customer',
      modal: 'createCustomerDrawer',
      roles: ['customer'],
      iconProps: {
        color: colors.neutral400,
        icon: faUserGroup
      }
    },
    {
      label: 'Fornecedor',
      id: 'quick-create-supplier',
      modal: 'createSupplierDrawer',
      roles: ['supplier'],
      iconProps: {
        color: colors.neutral400,
        icon: faPersonDolly
      }
    }
  ],
  task,
  generalTask,
  sortCatalog: [
    topSale,
    priceAsc,
    priceDesc,
    { ...nameAsc, order: ['name', 'ASC'] },
    { ...nameDesc, order: ['name', 'DESC'] }
  ],
  sortFiles: [
    { ...createdAtDesc, label: 'Mais recente' },
    { ...createdAtAsc, label: 'Mais antigo' },
    { ...nameAsc, order: [['filename', 'ASC']] },
    { ...nameDesc, order: [['filename', 'DESC']] }
  ],
  sortOrderSummary: [createdAtAsc, createdAtDesc],
  instalmentItem,
  instalmentParent,
  instalmentParentGrouped,
  instalmentChildren,
  templateList: [
    {
      id: 'duplicate-templates',
      label: 'Duplicar template',
      modal: 'useAsTemplateModal',
      modalParams: {
        title: 'Novo template',
        model: 'template',
        pathOptions: '/duplicate',
        successText: 'Template duplicado com sucesso',
        modalType: 'Template',
        text: `Escolha o que deseja duplicar deste template.`
      }
    },
    deleteActionList({ item: 'template' })
  ],
  shoppingItem: isPaid => [
    {
      label: isPaid ? 'Marcar como aguardando compra' : 'Marcar como comprado',
      verb: 'put',
      values: { purchaseStatus: isPaid ? 1 : 6, event: 'project-shopping-edited' }
    }
  ],
  projectList,
  projectItemList,
  opportunityItemList,
  projectListEnd,
  projectKanban,
  opportunityKanban,
  listOpportunityItemArchived,
  listOpportunityItem,
  myProducts: ({ isComposition, isMobile }) => [
    {
      label: 'Ver detalhes',
      id: 'edit-item',
      modal: 'editModal',
      iconProps: {
        icon: faEye,
        color: colors.neutral400
      },
      buttonParams: {
        style: styleForDropdown
      }
    },
    ...(!isComposition
      ? [
          {
            label: 'Duplicar',
            id: 'duplicate-item',
            modal: 'confirmModal',
            verb: 'post',
            pathOptions: '/duplicate',
            displayToast: 'Operação realizada com sucesso.',
            modalParams: {
              text: 'Deseja realmente clonar este item?'
            },
            buttonParams: {
              style: styleForDropdown
            },
            iconProps: {
              icon: faCopy,
              color: colors.neutral400
            }
          }
        ]
      : []),
    copyLinkButton,
    {
      label: 'Adicionar em um projeto',
      id: 'add-item-to-project',
      modal: 'addProductToProject',
      iconProps: {
        icon: faPlus,
        color: colors.neutral400
      },
      modalParams: {
        style: styleForDropdown
      },
      buttonParams: {
        style: styleForDropdown
      }
    },
    {
      ...deleteActionList({ item: 'produto' }),
      buttonParams: {
        style: {
          ...styleForDropdown,
          color: colors.red500,
          ...(isMobile && { padding: '0px' })
        }
      },
      iconProps: {
        icon: faTrashCan,
        color: colors.red500
      }
    }
  ],
  addItems: [...addItem, useSpecificationTemplate],
  addRefurbishItems: (isMobile, groupedReference) => {
    const [searchCatalog, addProduct, addLabor] = addItem;
    return [
      ...(!isMobile
        ? [
            {
              label: 'Composição',
              modal: 'createComposition',
              values: { type: 4, groupedReference },
              verb: 'post',
              id: 'Create-new-composition',
              roles: ['composition'],
              iconProps: {
                icon: faLayerGroup,
                color: colors.neutral400
              }
            }
          ]
        : []),
      {
        ...addProduct,
        label: 'Produto',
        iconProps: {
          icon: faBoxOpen,
          color: colors.neutral400
        }
      },
      {
        ...addLabor,
        label: 'Serviço',
        iconProps: {
          icon: faScrewdriverWrench,
          color: colors.neutral400
        }
      },
      {
        ...searchCatalog,
        label: 'Busca Avançada',
        ...(!isMobile
          ? {
              buttonParams: {
                style: {
                  color: colors.primary600,
                  padding: 0,
                  margin: `${spaces.space0} 0`
                }
              }
            }
          : null),
        iconProps: {
          icon: faSearch,
          color: isMobile ? colors.neutral400 : colors.primary600
        }
      }
    ];
  },

  addTemplateItems: [...addItem, addComposition],
  addPaymentItem: [
    {
      label: 'Buscar de orçamento',
      externalAction: true,
      modal: 'createSpecificationItem',
      id: 'create-specitication-item'
    },
    {
      label: 'Criar item não orçado',
      externalAction: true,
      modal: 'newItem',
      id: 'create-new-item'
    }
  ],
  addCompositionItems,
  proposal: isProject => [
    {
      label: 'Arquivar',
      verb: 'put',
      id: 'proposal-archive',
      modal: 'confirmModal',
      modalParams: {
        text: 'Deseja realmente arquivar esta proposta?'
      },
      values: { idStatus: 4, winnerOrArchived: new Date(), archived: true, isProject }
    }
  ],
  addCompositionDropdown: [
    { label: 'Criar novo', id: 'new-composition', modal: 'new', roles: ['composition'] },
    { label: 'Copiar da SINAPI', id: 'copy-from-sinapi', modal: 'sinapi', roles: ['composition'] }
  ],
  notificationDropdown: userType => [
    {
      label: 'Marcar todas como lidas',
      verb: 'markAllRead'
    },
    {
      label: 'Ver todas as notificações',
      redirectTo: `/${userType}/notificacoes`
    },
    {
      label: 'Configurar notificações',
      redirectTo: `/${userType}/notificacoes/configuracoes`
    }
  ],
  notificationListItem: title => [
    {
      label: title,
      verb: 'markRead'
    }
  ],
  userProvider: [
    {
      label: 'Acessar como usuário',
      modal: 'accessAsUser'
    },
    deleteActionList({ item: 'usuário' })
  ],
  usersProvider: [
    {
      label: 'Reenviar e-mail',
      verb: 'post',
      modal: 'confirmModal',
      pathOptions: '/resend-welcome',
      displayToast: 'E-mail reenviado com sucesso!',
      model: 'user',
      modalParams: {
        text: 'Deseja reenviar o email?'
      }
    },
    deleteActionList({ item: 'usuário' })
  ],
  noteActions: [deleteActionList({ item: 'nota', gender: 'a' })],
  payment,
  paymentChildren,
  markAsPaid,
  performedHours: [
    {
      label: 'Adicionar em um projeto',
      id: 'add-item-to-project',
      modal: 'addProductToProject'
    },
    {
      label: 'Fazer uma cópia',
      id: 'duplicate-item',
      modal: 'confirmModal',
      verb: 'post',
      pathOptions: '/duplicate',
      displayToast: 'Operação realizada com sucesso.',
      modalParams: {
        text: 'Deseja realmente clonar este item?'
      }
    },
    {
      ...deleteAction,
      id: 'remove-item'
    }
  ],
  bankAccount: [
    {
      label: 'Editar',
      id: 'finance-edit',
      modal: 'financeEdit'
    },
    deleteActionList({ item: 'conta', gender: 'a' })
  ],
  transfer: ({ installment, transferId, isMobile }) => {
    const { idReconciliation } = installment || {};

    return [
      {
        label: 'Editar',
        id: 'edit-transfer',
        modal: 'editTransference',
        modalParams: {
          id: transferId || installment?.transferId
        }
      },
      ...(idReconciliation ? [undoReconciliation(installment)] : []),
      {
        ...deleteAction,
        label: 'Excluir',
        id: 'remove-transfer',
        pathOptions: `/transfer/${transferId || installment?.transferId}`,
        buttonParams: {
          style: { color: colors.red500, ...(isMobile && { padding: '0px' }) }
        },
        modalParams: {
          text: 'Ao excluir, a transferência será removida permanente da sua conta e ninguém mais poderá acessá-la.',
          alertInfo: 'Essa operação não poderá ser desfeita'
        }
      }
    ];
  },
  purchaseAction: ({ data, purchaseSolicitationStatus }) => {
    const actions = [];

    const createPurchaseOrder = {
      label: 'Criar Ordem de Compra/Contratação',
      id: 'create-purchase-order',
      redirectTo: `/profissional/gestao-de-compras/ordem-de-compras/novo?solicitacao=${data?.id}${
        data.idRefurbish ? `&projeto=${data?.idRefurbish}` : ''
      }`
    };

    const createQuote = {
      label: 'Criar cotação',
      id: 'create-quote',
      redirectTo: `/profissional/gestao-de-compras/cotacoes/novo?solicitacao=${data?.id}${
        data.idRefurbish ? `&projeto=${data?.idRefurbish}` : ''
      }`
    };

    const hasAlreadyQuote = data?.quote?.id;

    if (data?.idPurchaseStatus === purchaseSolicitationStatus.open) {
      actions.push({
        label: 'Mover para cotação',
        id: 'move-quote',
        verb: 'put',
        params: { values: { idPurchaseStatus: purchaseSolicitationStatus.quote } },
        pathOptions: `/action/${data?.id}`,
        displayToast: 'Operação realizada com sucesso.'
      });
      actions.push({
        label: 'Mover para compra e contratação',
        id: 'move-buy',
        verb: 'put',
        params: { values: { idPurchaseStatus: purchaseSolicitationStatus.buy } },
        pathOptions: `/action/${data?.id}`,
        displayToast: 'Operação realizada com sucesso.'
      });
      if (!hasAlreadyQuote) actions.push(createQuote);
      actions.push({
        label: 'Voltar para rascunho',
        id: 'back-draft',
        verb: 'put',
        params: { values: { idPurchaseStatus: purchaseSolicitationStatus.draft } },
        pathOptions: `/action/${data?.id}`,
        displayToast: 'Operação realizada com sucesso.'
      });
    }
    if (data?.idPurchaseStatus === purchaseSolicitationStatus.quote) {
      actions.push({
        label: 'Mover para compra e contratação',
        id: 'move-buy',
        verb: 'put',
        params: { values: { idPurchaseStatus: purchaseSolicitationStatus.buy } },
        pathOptions: `/action/${data?.id}`,
        displayToast: 'Operação realizada com sucesso.'
      });
      if (!hasAlreadyQuote) actions.push(createQuote);
      actions.push(createPurchaseOrder);
      actions.push({
        label: 'Voltar para aberta',
        id: 'back-open',
        verb: 'put',
        params: { values: { idPurchaseStatus: purchaseSolicitationStatus.open } },
        pathOptions: `/action/${data?.id}`,
        displayToast: 'Operação realizada com sucesso.'
      });
    }
    if (data?.idPurchaseStatus === purchaseSolicitationStatus.buy) {
      actions.push({
        label: 'Marcar como finalizada',
        id: 'move-closed',
        verb: 'put',
        params: { values: { idPurchaseStatus: purchaseSolicitationStatus.closed } },
        pathOptions: `/action/${data?.id}`,
        displayToast: 'Operação realizada com sucesso.'
      });
      actions.push({
        label: 'Voltar para cotação',
        id: 'back-quote',
        verb: 'put',
        params: { values: { idPurchaseStatus: purchaseSolicitationStatus.quote } },
        pathOptions: `/action/${data?.id}`,
        displayToast: 'Operação realizada com sucesso.'
      });
      actions.push(createPurchaseOrder);
    }
    if (data?.idPurchaseStatus === purchaseSolicitationStatus.closed) {
      actions.push({
        label: 'Voltar para compra e contratação',
        id: 'back-buy',
        verb: 'put',
        params: { values: { idPurchaseStatus: purchaseSolicitationStatus.buy } },
        pathOptions: `/action/${data?.id}`,
        displayToast: 'Operação realizada com sucesso.'
      });
    }

    return [...actions, copyLinkButton, deleteActionV2];
  },
  orderAction: ({ data, purchaseOrderStatus, hasUserPaymentPermission, isMobile }) => {
    const actions = [];

    const displayToast = 'Operação realizada com sucesso.';

    const isAllowedRequestRefund =
      !data?.splitId &&
      data?.idPayment &&
      !data.idPaymentRefund &&
      hasUserPaymentPermission &&
      purchaseOrderStatus.requestableRefund.includes(data?.idOrderStatus);

    const isAllowedChargeFeeAdministration =
      !data?.splitId &&
      data?.idPayment &&
      !data.idPaymentFee &&
      hasUserPaymentPermission &&
      purchaseOrderStatus.chargeableAdministrationFee.includes(data?.idOrderStatus);

    const seeSupplierPayment = {
      button: 'seeSupplierPayment'
    };

    const markAsAccepted = {
      button: 'markAsAccepted'
    };

    const markAsRefused = {
      label: 'Marcar como recusada',
      id: 'mark-as-refused',
      verb: 'put',
      params: { values: { idOrderStatus: purchaseOrderStatus.refused } },
      pathOptions: `/action/${data?.splitId || data?.id}`,
      displayToast
    };

    const markAsSent = {
      label: 'Marcar como enviado',
      id: 'mark-as-sent',
      verb: 'put',
      params: { values: { idOrderStatus: purchaseOrderStatus.sent } },
      pathOptions: `/action/${data?.splitId || data?.id}`,
      displayToast
    };

    const sendToSupplier = {
      label: 'Enviar para fornecedor',
      button: 'sendToSupplier',
      id: 'send-to-supplier'
    };

    const finish = {
      label: 'Finalizar',
      id: 'finish',
      verb: 'put',
      params: { values: { idOrderStatus: purchaseOrderStatus.closed } },
      pathOptions: `/action/${data?.splitId || data?.id}`,
      displayToast
    };

    const backToSent = {
      label: 'Voltar para enviada',
      id: 'back-to-sent',
      verb: 'put',
      params: { values: { idOrderStatus: purchaseOrderStatus.sent } },
      pathOptions: `/action/${data?.splitId || data?.id}`,
      displayToast: 'Operação realizada com sucesso.'
    };

    const requestRefund = isAllowedRequestRefund && {
      label: 'Solicitar reembolso',
      button: 'requestRefund',
      id: 'request-refund'
    };

    const chargeAdministrationFee = isAllowedChargeFeeAdministration && {
      id: 'charge-administration-fee',
      label: 'Cobrar taxa de administração',
      button: 'chargeAdministrationFee'
    };

    const backToAccepted = {
      label: 'Voltar para aceita',
      id: 'back-to-accepted',
      verb: 'put',
      params: { values: { idOrderStatus: purchaseOrderStatus.accepted } },
      pathOptions: `/action/${data?.splitId || data?.id}`,
      displayToast: 'Operação realizada com sucesso.'
    };

    const backToRefused = {
      label: 'Voltar para recusada',
      id: 'back-to-refused',
      verb: 'put',
      params: { values: { idOrderStatus: purchaseOrderStatus.refused } },
      pathOptions: `/action/${data?.splitId || data?.id}`,
      displayToast: 'Operação realizada com sucesso.'
    };

    if (data?.idOrderStatus === purchaseOrderStatus.open) {
      actions.push(markAsSent, sendToSupplier);
    }
    if (data?.idOrderStatus === purchaseOrderStatus.accepted) {
      actions.push(finish, seeSupplierPayment);
    }
    if (data?.idOrderStatus === purchaseOrderStatus.sent) {
      actions.push(markAsAccepted, markAsRefused, sendToSupplier);
    }
    if (data?.idOrderStatus === purchaseOrderStatus.refused) {
      actions.push(backToSent);
    }

    if (data?.idOrderStatus === purchaseOrderStatus.closed) {
      actions.push(seeSupplierPayment);
      actions.push(backToAccepted);
      !data?.idPayment && actions.push(backToRefused);
    }

    if (isAllowedRequestRefund) actions.push(requestRefund);

    if (isAllowedChargeFeeAdministration) actions.push(chargeAdministrationFee);

    if (!isMobile) actions.push(exportPdf);

    actions.push(copyLinkButton);

    if (!data?.payment?.paidValue)
      actions.push({ ...deleteActionV2, ...(data?.splitId && { deletePathOptions: `/split/${data?.splitId}` }) });

    return actions;
  },
  quoteAction: ({ idQuote, idStatus, purchaseQuoteStatus, isView }) => {
    const actions = [];

    const finishedId = purchaseQuoteStatus.finished;
    const buyId = purchaseQuoteStatus.buy;
    const draftId = purchaseQuoteStatus.draft;

    if (!isView)
      actions.push({
        id: 'view-quote',
        button: 'view'
      });

    if (purchaseQuoteStatus.editable.includes(idStatus)) {
      actions.push({
        id: 'edit-quote-dropdown',
        button: 'edit'
      });
    }

    if (idStatus !== draftId && idStatus !== finishedId) {
      actions.push({
        label: 'Enviar pedidos de cotações',
        id: 'resend-request',
        modal: 'sendQuoteRequest'
      });
    }

    actions.push({
      id: 'export-pdf',
      button: 'exportPdf'
    });

    if (idStatus === buyId) {
      actions.push({
        label: 'Ordens de compra vinculadas',
        id: 'show-orders',
        modal: 'orderDetailModal'
      });
      actions.push({
        label: 'Mover para finalizada',
        id: 'move-to-finished',
        verb: 'put',
        params: { values: { idQuoteStatus: finishedId }, updatePathOptions: `/action/${idQuote}`, isAction: true },
        pathOptions: `/action/${idQuote}`,
        displayToast: 'Operação realizada com sucesso.'
      });
    }

    if (idStatus === finishedId) {
      actions.push({
        label: 'Mover para compra e contratação',
        id: 'move-to-buy',
        verb: 'put',
        params: { values: { idQuoteStatus: buyId }, updatePathOptions: `/action/${idQuote}`, isAction: true },
        pathOptions: `/action/${idQuote}`,
        displayToast: 'Operação realizada com sucesso.'
      });
    }

    return [...actions, deleteActionV2];
  }
};

export { allDropdown };
