import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/pro-light-svg-icons';
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons';
import { CurrencyInput } from 'react-currency-mask';
import TextArea from 'antd/lib/input/TextArea';
import { colors, fonts, spaces } from '../../styles/style';
import TooltipIcon from '../Tooltip/TooltipIcon';
import { EditContainer, StyledDiv } from './EditableInput.style';
import formatNumber from '../../lib/helpers/formatNumber';

const EditableInput = ({
  icon = false,
  value,
  onChange = () => {},
  noWrap = false,
  disabled,
  initEdit,
  map,
  withEditButton,
  onClick,
  onBlur = f => f,
  rowId,
  element,
  unit,
  placeholder,
  id,
  type,
  size,
  textStyleProps,
  InputStyle = { fontSize: fonts.fontSm },
  showUnitWhenNotEmpty,
  propsParagraph,
  formReadOnly = false,
  prefix,
  suffix,
  hideCurrencySymbol = false,
  allowClear,
  textArea,
  ...props
}) => {
  const [_value, setValue] = useState(value);
  const [edit, setEdit] = useState(initEdit);
  const ref = useRef();
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setValue(value);
  }, [value]);

  // Sometimes the input is not focused when the component is mounted, even with initEdit true, so we need to force it
  useEffect(() => {
    if (initEdit && !focused) {
      setTimeout(() => ref.current?.focus(), 0);
      setFocused(true);
    }
  }, [initEdit, focused]);

  useEffect(() => {
    if (rowId || element) {
      const expandedRows = document.getElementsByClassName('expanded-row') || [];
      const _element = element || document.querySelector(`[data-row-key="${rowId}"]`);
      if (edit && _element) {
        _element.setAttribute('draggable', false);
        Array.from(expandedRows).forEach(item => item.setAttribute('draggable', false));
      } else if (_element) {
        _element.setAttribute('draggable', true);
        Array.from(expandedRows).forEach(item => item.setAttribute('draggable', true));
      }
    }
  }, [edit]);

  const isPromise = returnOnchange => {
    return !!(typeof returnOnchange === 'object' && typeof returnOnchange?.then === 'function');
  };

  const handleBlur = ({ target: { value: newValue } = {} }) => {
    if ((value?.replace?.(/\s+/g, '') || '').trim() !== newValue?.replace?.(/\s+/g, '').trim()) {
      let _newValue = newValue;
      if (map) {
        _newValue = map(newValue);
        setValue(_newValue);
      }

      const responseOnChange = onChange(_newValue);
      if (isPromise(responseOnChange)) {
        responseOnChange.then(resp => {
          if (resp?.error) setValue(value);
        });
      }
    }

    setEdit(false);
    onBlur();
  };

  const handleKey = e => {
    if (e.key === 'Escape') {
      setEdit(false);
      setValue(value);
    }
    if (e.key === 'Enter') {
      e?.target?.blur();
    }
  };

  const RendInput = textArea ? TextArea : Input;

  if (withEditButton && !edit) {
    return (
      <EditContainer onClick={onClick}>
        <StyledDiv role="presentation" cursor="pointer" disabled noWrap={noWrap} value={_value} {...props}>
          {_value || '-'}
        </StyledDiv>
        {!disabled && (
          <TooltipIcon
            style={{ height: spaces.space2, width: spaces.space2 }}
            className="renameItem showHover"
            text="Renomear"
            icon={faPenToSquare}
            iconColor={colors.neutral600}
            onClick={e => {
              setEdit(true);
              e.stopPropagation();
            }}
          />
        )}
      </EditContainer>
    );
  }

  return (
    <StyledDiv id={id} noWrap={noWrap} marginRight={unit || icon} value={_value} {...props}>
      {type === 'currency' ? (
        <CurrencyInput
          ref={ref}
          value={formatNumber(_value)}
          onChangeValue={(_, __, maskedValue) => setValue(maskedValue)}
          onBlur={handleBlur}
          placeholder={placeholder}
          hideSymbol={hideCurrencySymbol}
          onFocus={() => setEdit(true)}
          InputElement={
            <Input
              id={id || `task-name-${rowId}`}
              style={InputStyle}
              status={props.withError && 'error'}
              placeholder={placeholder}
              disabled={disabled || formReadOnly}
              onKeyDown={handleKey}
              onClick={e => e.stopPropagation()}
              size={size}
              readOnly={formReadOnly}
              prefix={prefix}
              suffix={suffix}
              allowClear={allowClear}
            />
          }
        />
      ) : (
        <RendInput
          ref={ref}
          id={id || `task-name-${rowId}`}
          style={InputStyle}
          status={props.withError && 'error'}
          placeholder={placeholder}
          value={_value}
          onFocus={() => setEdit(true)}
          onKeyDown={handleKey}
          onChange={e => setValue(e.target.value)}
          onBlur={handleBlur}
          onClick={e => e.stopPropagation()}
          disabled={disabled}
          size={size}
          autoFocus={withEditButton || initEdit}
          readOnly={formReadOnly}
          prefix={prefix}
          suffix={suffix}
          allowClear={allowClear}
          autoSize
        />
      )}
      {unit && (!showUnitWhenNotEmpty || _value) ? ` ${unit}` : ''}
      {icon && <FontAwesomeIcon icon={faPencilAlt} size="xs" />}
    </StyledDiv>
  );
};

EditableInput.propTypes = {
  icon: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  noWrap: PropTypes.bool,
  disabled: PropTypes.bool,
  initEdit: PropTypes.bool,
  map: PropTypes.func,
  withEditButton: PropTypes.bool,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  rowId: PropTypes.number,
  element: PropTypes.instanceOf(Object),
  unit: PropTypes.string,
  placeholder: PropTypes.string,
  inputStyle: PropTypes.instanceOf(Object),
  id: PropTypes.string,
  type: PropTypes.string,
  withError: PropTypes.bool,
  size: PropTypes.string,
  textStyleProps: PropTypes.instanceOf(Object),
  showUnitWhenNotEmpty: PropTypes.bool,
  InputStyle: PropTypes.instanceOf(Object),
  propsParagraph: PropTypes.instanceOf(Object),
  formReadOnly: PropTypes.bool,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  allowClear: PropTypes.bool,
  textArea: PropTypes.bool,
  hideCurrencySymbol: PropTypes.bool
};

export default EditableInput;
