import styled, { css } from 'styled-components';
import { Table as TableAnt } from 'antd';
import { colors, spaces, fonts, radius } from '../../styles/style';

const FlexTable = styled(TableAnt)`
  border-radius: ${props => (props.$customBorder ? props.$customBorder : radius.radius1)};
  padding-bottom: 8px;
  border: ${props => (props.border ? `1px solid ${colors.neutral100}` : null)};

  .ant-table-thead {
    position: ${props => (props.$stickyTop === null ? 'relative' : 'sticky')};
    top: ${props => props.$stickyTop}px;
    z-index: 2;
  }
  .ant-table.ant-table-small {
    font-size: ${fonts.sizeSm};
    border-radius: ${radius.radius1};

    .ant-table-thead > tr > th {
      background: ${colors.neutral50};
      padding: ${spaces.space1} ${spaces.space0};
      border-top: 1px solid #f0f0f0;

      &:first-of-type {
        padding: ${spaces.space1} ${props => props.$paddingFirstColumn}px;
      }

      .ant-table-column-sorters {
        padding: 0 ${spaces.space0};
      }

      &.image {
        min-width: 45px;
        page-break-inside: avoid;
      }

      &:first-child {
        border-top-left-radius: ${props => props.$borderRadius}px;
      }

      &:last-child {
        border-top-right-radius: ${props => props.$borderRadius}px;
      }
    }

    .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
      margin: 0;
    }
  }

  .ant-table-column-sorter {
    margin-left: 2px;
    padding-top: 0;
    display: none;
  }

  .ant-table-thead tr {
    display: flex;
  }
  .ant-table-thead th {
    flex: 0;
    min-width: ${spaces.space5};
    z-index: 1;

    &.actions {
      z-index: 0;
      min-width: 45px;
      display: flex;
      justify-content: flex-end;
    }

    &.center {
      display: flex;
      justify-content: center;
    }

    &.width-10 {
      min-width: 10px;
    }
    &.width-50 {
      min-width: 50px;
    }
    &.width-60 {
      min-width: 60px;
    }
    &.width-70 {
      min-width: 70px;
    }
    &.width-90 {
      min-width: 90px;
    }
    &.width-110 {
      min-width: 110px;
    }
    &.width-120 {
      min-width: 120px;
    }
    &.width-180 {
      min-width: 180px;
    }

    &.font-weight-600 {
      font-weight: 600;
    }

    &.col-grow-1 {
      flex: 1;
    }
    &.col-grow-2 {
      flex: 2;
    }
    &.col-grow-3 {
      flex: 3;
    }
    &.col-grow-4 {
      flex: 4;
    }
    &.col-grow-5 {
      flex: 5;
    }
  }
  .ant-table-tbody {
    display: block;
  }

  .ant-table.ant-table-empty {
    .ant-table-tbody {
      display: contents;
    }
  }

  .ant-table-row-expand-icon {
    background-color: transparent;
    border: none;
    border-radius: 50%;
    padding: ${spaces.space2};
    margin: 0;
    &:hover {
      background-color: ${colors.neutral200};
    }

    &.ant-table-row-expand-icon-expanded,
    &.ant-table-row-expand-icon-collapsed {
      :after {
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 7px solid ${colors.neutral600};
        background: transparent;
        top: 10px;
        left: 12px;
      }

      :before {
        content: none;
      }
    }
  }

  .ant-table-tbody > tr.ant-table-expanded-row {
    display: grid;
  }

  .ant-table-tbody > tr.ant-table-row,
  .ant-table-tbody > tr.ant-table-row-selected {
    > td {
      border: 0;
      background: white;
      padding: 0 ${spaces.space0};
      transition: 0s;
      padding-bottom: 3px !important;

      &.image {
        display: flex;
        justify-content: center;
        min-width: 45px;
        height: 50px;
        align-items: center;
      }

      &:first-of-type {
        padding: 0 ${props => props.$paddingFirstColumn}px;
      }
    }
    &:hover > td {
      background: ${colors.primary50};
    }
  }

  .ant-table-tbody > tr.ant-table-row-selected {
    background: ${colors.primary50};
    td {
      background: ${colors.primary50};
    }
  }

  .ant-table-row {
    display: flex;
    overflow: auto;
    border: 0;
    border-bottom: ${props => (props.$borderRow ? `1px solid ${colors.neutral100}` : null)};
    position: relative;
    &:hover {
      background: ${colors.primary50};
      cursor: ${props => props.cursor && props.cursor};
      .action {
        height: inherit;
      }
      .showHover {
        display: inherit;
      }
    }
    &:last-child {
      ${props =>
        props.$borderRow &&
        css`
          border-bottom: none;
        `}
    }
  }
  .ant-table-row td {
    flex: 0;
    overflow: auto;
    align-self: center;
    min-width: ${props => (props.$noCheckBox ? spaces.space2 : spaces.space5)};

    &.actions {
      min-width: 45px;
      display: flex;
      justify-content: flex-end;
    }

    &.center {
      display: flex;
      justify-content: center;
    }

    &.width-50 {
      min-width: 50px;
    }
    &.width-60 {
      min-width: 60px;
    }
    &.width-70 {
      min-width: 70px;
    }
    &.width-90 {
      min-width: 90px;
    }
    &.width-110 {
      min-width: 110px;
    }
    &.width-120 {
      min-width: 120px;
    }
    &.width-180 {
      min-width: 180px;
    }

    &.font-weight-600 {
      font-weight: 600;
    }

    &.col-grow-1 {
      flex: 1;
    }
    &.col-grow-2 {
      flex: 2;
    }
    &.col-grow-3 {
      flex: 3;
    }
    &.col-grow-4 {
      flex: 4;
    }
    &.col-grow-5 {
      flex: 5;
    }
    &.padding-left-12 {
      padding-left: 12px !important;
    }
  }

  .ant-table-row ::-webkit-scrollbar {
    display: none;
  }

  .ant-table-row-level-1 {
    display: none;
  }

  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    font-size: 10px;
  }

  .ant-table-selection-column {
    position: initial;
  }

  .ant-table-footer {
    background: white;
  }

  .expanded-row {
    > td {
      padding: 0 !important;
    }

    .ant-table-wrapper {
      border-left: 2px solid ${colors.primary600};
      background: white;
      padding-left: 10px;
    }
  }

  .expanded-parent {
    border-left: 2px solid ${colors.primary600};
    background: white;
  }

  .ant-empty-image {
    display: none;
  }

  .display {
    padding-left: 6px;
  }

  ${props =>
    props.$noFooter &&
    css`
      .ant-table-placeholder {
        td {
          border-bottom: none;
        }
      }
    `}
`;

const SimpleTable = styled(TableAnt)`
  border-radius: ${radius.radius1};

  .ant-table.ant-table-small {
    font-size: ${fonts.sizeSm};
    border-radius: ${radius.radius1};

    border: ${props => (props.border ? props.border : null)};
    .ant-table-thead > tr > th {
      background-color: ${colors.neutral50};
      padding: ${spaces.space1};

      .ant-table-column-sorters {
        padding: 0 ${spaces.spac0};
      }

      &:first-child {
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
      }
    }
  }
  .ant-table-column-sorter {
    margin-left: 2px;
    padding-top: 0;
  }

  .ant-table-row td {
    border: 0;
    vertical-align: top;
  }

  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    font-size: 8px;
  }

  .ant-table-footer {
    background: white;
  }

  .ant-table-row {
    &:hover {
      .showHover {
        display: inherit;
      }
    }
  }
  .ant-table-cell {
    border-bottom: ${props => (props.$borderBottom ? `1px solid ${colors.neutral100} !important` : null)};
  }
`;

export { FlexTable, SimpleTable };
