import React from 'react';
import { useHistory } from 'react-router-dom';

import { Div, spaces } from '../../styles/style';
import Button from '../Button/Button';
import QuoteDropdown from '../Dropdown/QuoteDropdown';
import { useContextHook } from '../../contexts/GeneralContext';
import Footer from './Footer';
import useBackToContext from '../../_Hooks/useBackToContext';
import Action from '../Button/Action';

const QuoteFooter = () => {
  const {
    isView,
    isDraft,
    data,
    handleSave,
    validateSubmit,
    handleSaveAndAddSuppliers,
    purchaseQuoteStatus,
    handleExportPdf,
    isMobile,
    reload,
    queryString
  } = useContextHook() || {};
  const history = useHistory();
  const backToContext = useBackToContext();

  const SaveDraftButton = () => (
    <Button id="quote-save-draft" text onClick={() => handleSave()}>
      Salvar rascunho
    </Button>
  );

  const EditButton = () => (
    <Button
      id="quote-edit"
      text
      onClick={() =>
        history.push(`/profissional/gestao-de-compras/cotacoes/editar/${data?.splitId || data?.id}${queryString}`)
      }
    >
      Editar
    </Button>
  );

  const SaveAndAddSuppliersButton = () => (
    <Button
      id="quote-save"
      type="primary"
      width={isMobile ? '152px' : '232px'}
      onClick={() =>
        validateSubmit({
          callback: () => {
            handleSaveAndAddSuppliers();
          }
        })
      }
    >
      {isMobile ? 'Adicionar fornecedores' : 'Salvar e adicionar fornecedores'}
    </Button>
  );

  const SaveButton = () => (
    <Button
      id="quote-save"
      type="primary"
      width="120px"
      onClick={() =>
        validateSubmit({
          callback: () => {
            handleSave();
          }
        })
      }
    >
      Salvar
    </Button>
  );

  const ActionsButton = () => (
    <QuoteDropdown
      idQuote={data?.splitId || data?.id}
      originalId={data?.id}
      splitId={data?.splitId}
      idStatus={data?.idQuoteStatus}
      purchaseQuoteStatus={purchaseQuoteStatus}
      afterSubmit={() => history.push('/profissional/gestao-de-compras/cotacoes')}
      handleExportPdf={handleExportPdf}
      isView={isView}
      reload={reload}
      queryString={queryString}
    >
      <Action id="quote-actions" text />
    </QuoteDropdown>
  );

  return (
    <Footer>
      <Button id="purchase-go-back" text onClick={backToContext}>
        Voltar
      </Button>
      <Div gap={isMobile ? spaces.space2 : spaces.space3}>
        {(!data?.id || (!isView && isDraft)) && (
          <>
            <SaveDraftButton />
            <SaveAndAddSuppliersButton />
          </>
        )}
        {isView && isDraft && (
          <>
            <EditButton />
            <SaveAndAddSuppliersButton />
          </>
        )}
        {isView && !isDraft && <ActionsButton />}
        {data?.id && !isView && !isDraft && <SaveButton />}
      </Div>
    </Footer>
  );
};

export default QuoteFooter;
