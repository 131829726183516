import { Badge } from 'antd';
import styled, { css } from 'styled-components';
import { colors, fonts, spaces } from '../../styles/style';

export const Paragraph = styled.p`
  color: ${props => props.color || colors.neutral600};
  padding: ${props => props.padding || 'auto'};
  font-weight: ${props => props.weight || 'auto'};
  text-align: ${props => props.align || 'auto'};
  line-height: 1.5;
  display: ${props => props.display || 'auto'};

  ${props =>
    props.ellipsis &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${props =>
    props.type === 'small' &&
    css`
      font-size: ${fonts.sizeSm};
    `}

  ${props =>
    props.type === 'large' &&
    css`
      font-size: ${fonts.sizeLg};
    `}

    ${props =>
      props.$ellipsisClamp &&
      css`
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: ${props.$ellipsisClamp};
        -webkit-box-orient: vertical;
      `}
`;

export const Title = styled.span`
  font-weight: ${props => (props.weight ? props.weight : fonts.weight600)};
  font-size: ${props => (props.size ? props.size : fonts.sizeLg)};
  line-height: 1.5;
  color: ${props => (props.color ? props.color : colors.neutral700)};
`;

export const Subtitle = styled.span`
  font-weight: ${props => (props.weight ? props.weight : fonts.weight600)};
  font-size: ${props => (props.size ? props.size : fonts.sizeMd)};
  line-height: 1.5;
  color: ${props => (props.color ? props.color : colors.neutral700)};
  padding: ${props => (props.padding ? props.padding : '0px')};
`;

export const Error = styled.span`
  font-weight: ${fonts.weight500};
  font-size: ${fonts.sizeSm};
  line-height: 1.5;
  color: ${props => (props.color ? props.color : colors.red500)};
  strong {
    font-weight: ${fonts.weight600};
  }
`;

export const Description = styled.span`
  font-family: ${fonts.family};
  font-weight: ${fonts.weight400};
  font-size: ${fonts.sizeXs};
  line-height: 1.4;
  color: ${props => (props.color ? props.color : colors.neutral600)};

  ${props =>
    props.ellipsis &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  strong {
    font-weight: ${fonts.weight600};
  }
`;

export const HelperLink = styled.a`
  cursor: pointer;
  color: ${props => (props.color ? props.color : colors.primary500)};
`;

export const BadgeText = styled(Badge)`
  display: flex;
  justify-content: ${props => props.$justify || 'center'};
  position: ${props => props.position || 'relative'};
  z-index: ${props => props.$zIndex || 'auto'};
  top: ${props => props.top || 'auto'};
  right: ${props => props.right || 'auto'};
  color: ${props => props.$textColor || colors.green500};

  .ant-badge-count {
    box-shadow: ${props => props.$boxShadow && props.$boxShadow};
    background-color: ${props => props.background || colors.green100};
    color: ${props => props.$textColor || colors.green500};
    padding: ${props => props.$padding || `0 ${spaces.space1}`};
    border-radius: ${props => props.$borderRadius || spaces.space0};
    font-size: ${props => props.$fontSize || fonts.sizeSm};
    font-weight: ${props => props.weight || fonts.weight500};
  }
`;
