import styled from 'styled-components';
import { Checkbox } from 'antd';

import { spaces, colors, fonts, radius } from '../../styles/style';

export const Container = styled.div`
  position: relative;
  display: inline-block;
`;

export const WatcherCheckbox = styled(Checkbox)``;

export const EyeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  div {
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${colors.neutral600};
    color: ${colors.white};
    border-radius: ${radius.radius1};
    height: 6px;
    width: 6px;
    border: 1px solid ${colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const UserList = styled.ul`
  position: absolute;
  top: 100%;
  right: 0;
  height: auto;
  max-height: 300px;
  min-width: 200px;
  overflow: auto;
  font-size: ${fonts.sizeSm};
  color: ${colors.neutral600};
  background-color: ${colors.white};
  padding: ${spaces.space2};
  border-radius: ${radius.radius1};
  box-shadow: 0 ${spaces.space1} ${spaces.space2} 0 rgb(0 0 0 / 8%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: ${spaces.space1};

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    gap: ${spaces.space1};

    label {
      margin: 0;
    }
  }
`;
