import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { Table } from './VirtualizedTable.styled';
import SimpleAccordion from '../Accordion/SimpleAccordion';
import { Paragraph } from '../Text/Text';
import { addressConcat, formatDecimalsDinamically, hasAddress } from '../../lib/helpers/helper';
import { Div, spaces } from '../../styles/style';
import { store } from '../../lib/config/redux-store';
import ImageInput from '../Input/ImageInput';

const allColumns = (refurbishItemType, refurbishItemTypes) => [
  {
    title: 'Item',
    dataIndex: 'name',
    key: 'name',
    sorter: false,
    width: 300,
    render: (val, row) => {
      const { id, item } = row || {};
      const type = row?.type || item?.type;
      const name = row?.name || item?.name;
      const typeName = refurbishItemTypes?.find(r => r.value === row.type)?.label;
      const itemImage = refurbishItemType?.product === type ? 'boxOpen' : 'screwdriverWrench';
      return (
        <Div gap={spaces.space0}>
          <Tooltip title={typeName}>
            <ImageInput size="12px" key={`image${id}`} id={id} value={itemImage || 'boxOpen'} disabled />
          </Tooltip>
          <Paragraph type="small" id="quote-item-supplier-item">
            {name}
          </Paragraph>
        </Div>
      );
    },
    shouldCellUpdate: () => false
  },
  {
    title: 'Qtd. Solicitada',
    dataIndex: 'quantity',
    key: 'quantity',
    sorter: false,
    align: 'right',
    render: val => {
      return (
        <Paragraph type="small" id="quote-item-quantity">
          {formatDecimalsDinamically(val)}
        </Paragraph>
      );
    },
    shouldCellUpdate: () => false
  },
  {
    title: 'Un.',
    dataIndex: ['units', 'name'],
    key: ['units', 'name'],
    sorter: false,
    shouldCellUpdate: () => false
  }
];

const SplitQuoteTable = ({ data }) => {
  const { setup } = store.getState();
  const { refurbishItemType = {} } = setup.enums;
  const { refurbishItemTypes = [] } = setup.systemData;

  return (
    <SimpleAccordion
      title={`${data?.refurbish?.name}`}
      initOpen
      id={`${data?.refurbish?.id}`}
      size="sm"
      iconPadding={`${spaces.space1} 0`}
    >
      <Div direction="column" gap={spaces.space2} align="start" $fullWidth margin={`0 0 ${spaces.space2} 0`}>
        <Paragraph>{`Endereço de entrega: ${hasAddress(data) ? addressConcat(data) : 'Não informado.'}`}</Paragraph>
        <Table
          style={{ marginRight: 'auto', maxWidth: '600px' }}
          dataSource={data?.quoteItems}
          columns={allColumns(refurbishItemType, refurbishItemTypes)}
          pagination={false}
          border
          $customHover="inherit"
          $withCommonRow
          borderRow
          rowSelection={false}
        />
      </Div>
    </SimpleAccordion>
  );
};

SplitQuoteTable.propTypes = {
  data: PropTypes.instanceOf(Object)
};

export default SplitQuoteTable;
