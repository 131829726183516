import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import AdministrationFeeModal from '../../../components/Modal/AdministrationFeeModal';
import OrderPaymentModal from '../../../components/Modal/OrderPaymentModal';
import OrderDetailModal from '../../../components/Modal/OrderDetailModal';
import Bill from '../../Payments/Bill';
import Payment from '../../Payments/Payment';
import { buildQueryString } from '../../../lib/helpers/helper';

const OrderModals = ({
  openPurchaseModal = {},
  setOpenPurchaseModal = f => f,
  openFinanceDrawer = {},
  setOpenFinanceDrawer = f => f,
  afterSubmit = f => f
}) => {
  const history = useHistory();
  const { paymentOrderType } = useSelector(state => state.setup.enums);
  return (
    <>
      {openPurchaseModal.open && openPurchaseModal.type === 'orderPayment' && (
        <OrderPaymentModal
          idOrder={openPurchaseModal.idOrders}
          isSplit={openPurchaseModal.isSplit}
          onClose={_data => {
            setOpenPurchaseModal({ open: false, idOrders: null, code: openPurchaseModal?.code });
            if (!_data) return;
            const { idCompanyCustomer, type, idOrder, orderDate, isSplit } = _data;
            setOpenFinanceDrawer({
              open: true,
              type,
              idOrders: idOrder,
              idCompanyCustomer,
              orderDate,
              orderType: paymentOrderType.order,
              isSplit
            });
          }}
        />
      )}
      {openPurchaseModal.open && openPurchaseModal.type === 'administrationFee' && (
        <AdministrationFeeModal
          idOrders={openPurchaseModal.idOrders}
          onClose={_data => {
            setOpenPurchaseModal({ open: false, idOrders: null });
            if (!_data) return;
            const { idOrders, feePercentage } = _data;
            setOpenFinanceDrawer({
              open: true,
              type: 'payment',
              idOrders,
              feePercentage,
              orderType: paymentOrderType.fee
            });
          }}
        />
      )}
      {openPurchaseModal.open && openPurchaseModal.type === 'detail' && (
        <OrderDetailModal
          detailType={openPurchaseModal.detailType}
          idPayment={openPurchaseModal.idPayment}
          onClose={_data => {
            setOpenPurchaseModal({ open: false, idPayment: null });
            if (!_data) return;
            const { idOrder, idPayment, splitId } = _data;

            const queryParts = [splitId && { key: 'isSplit', value: true }];
            const query = buildQueryString(queryParts);

            if (splitId || idOrder)
              history.push(`/profissional/gestao-de-compras/ordem-de-compras/visualizar/${splitId || idOrder}${query}`);

            setOpenFinanceDrawer({
              open: true,
              type: 'payment',
              idPayment
            });
          }}
        />
      )}
      {openFinanceDrawer.open && openFinanceDrawer.type === 'payment' && (
        <Payment
          id={openFinanceDrawer.idPayment}
          isView={!!openFinanceDrawer.idPayment}
          action={openFinanceDrawer.idPayment ? 'visualizar' : 'novo'}
          idCompanyCustomer={openFinanceDrawer.idCompanyCustomer}
          idOrder={openFinanceDrawer.idOrders}
          feePercentage={openFinanceDrawer.feePercentage}
          orderDate={openFinanceDrawer.orderDate}
          initialData={
            Array.isArray(openFinanceDrawer.idOrders)
              ? {
                  name: openFinanceDrawer.feePercentage ? 'Taxa de administração' : 'Reembolso',
                  ownBusiness: true
                }
              : {
                  name: `Pagamento ${openPurchaseModal?.code ? openPurchaseModal?.code : ''}`,
                  ownBusiness: false,
                  isVobiPay: false
                }
          }
          onClose={() => {
            setOpenFinanceDrawer({});
            afterSubmit({ data: { id: openFinanceDrawer.idOrders }, isDelete: false });
          }}
          orderType={openFinanceDrawer.orderType}
        />
      )}
      {openFinanceDrawer.open && openFinanceDrawer.type === 'expense' && (
        <Bill
          billType="expense"
          action={openFinanceDrawer.idPayment ? 'editar' : 'novo'}
          idOrder={openFinanceDrawer.idOrders}
          isSplit={openFinanceDrawer.isSplit}
          orderDate={openFinanceDrawer.orderDate}
          onClose={() => {
            setOpenFinanceDrawer({});
            afterSubmit({
              data: { [openFinanceDrawer.isSplit ? 'idSplit' : 'id']: openFinanceDrawer.idOrders },
              isDelete: false
            });
          }}
          initialData={{ name: `Pagamento ${openPurchaseModal?.code ? openPurchaseModal?.code : ''}` }}
          id={openFinanceDrawer.idPayment}
          orderType={openFinanceDrawer.orderType}
        />
      )}
    </>
  );
};

OrderModals.propTypes = {
  openPurchaseModal: PropTypes.instanceOf(Object),
  setOpenPurchaseModal: PropTypes.func,
  openFinanceDrawer: PropTypes.instanceOf(Object),
  setOpenFinanceDrawer: PropTypes.func,
  afterSubmit: PropTypes.func
};

export default OrderModals;
