import styled, { css } from 'styled-components';
import { colors, spaces } from '../../styles/style';

export const Container = styled.div`
  width: 100%;
  ${props =>
    props.$withPaddingContainer &&
    css`
      padding-left: ${spaces.space2};
      padding-right: ${spaces.space2};
    `}

  ${props =>
    props.type === 'chart' &&
    css`
      padding: ${spaces.space2};
      min-height: ${spaces.space8};
      border: 1px solid ${colors.neutral100};
      border-radius: ${spaces.space0};
      margin: ${spaces.space3} 0px;
    `}
`;

export const Body = styled.div`
  padding-top: ${props => props.$paddingTop || spaces.space2};
  ${props =>
    !props.$show &&
    css`
      display: none;
    `}
`;
