import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import React, { useEffect, useState } from 'react';
import PropTypes, { instanceOf } from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Body, Container } from './SimpleAccordion.style';
import { Div, spaces } from '../../styles/style';
import { Paragraph, Subtitle, Title } from '../Text/Text';
import Button from '../Button/Button';

const sizeText = {
  lg: Subtitle,
  md: Paragraph
};

const SimpleAccordion = ({
  isPrinting,
  forceOpen = false,
  setForceOpen = f => f,
  children,
  initOpen,
  title,
  id,
  withPaddingContainer,
  extraHeaderComponent,
  size = 'lg',
  type = 'common',
  iconPadding,
  setShowChart = f => f,
  paddingTop
}) => {
  const [showBody, setShowBody] = useState(initOpen);
  useEffect(() => {
    setShowBody(forceOpen || initOpen);
    setForceOpen(false);
  }, [initOpen, forceOpen]);

  useEffect(() => {
    setShowChart && setShowChart(showBody);
  }, [showBody]);

  const Text = sizeText[size] || Title;

  const icon = (
    <Button text padding={iconPadding}>
      <FontAwesomeIcon icon={!showBody ? faChevronDown : faChevronUp} size={size} />
    </Button>
  );

  return (
    <Container $withPaddingContainer={withPaddingContainer} type={type}>
      <Div onClick={() => setShowBody(prev => !prev)} maxHeight justify="space-between" className="pointer">
        <Div id={id || 'share-chevron'} gap={spaces.space1}>
          {!isPrinting && type === 'common' && icon}
          <Text>{title}</Text>
        </Div>
        {extraHeaderComponent}
        {type === 'chart' && <Div>{icon}</Div>}
      </Div>
      <Body $show={showBody} $paddingTop={paddingTop}>
        {children}
      </Body>
    </Container>
  );
};

SimpleAccordion.propTypes = {
  isPrinting: PropTypes.bool,
  forceOpen: PropTypes.bool,
  setForceOpen: PropTypes.func,
  title: PropTypes.string,
  initOpen: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, instanceOf(Object)]),
  id: PropTypes.string,
  withPaddingContainer: PropTypes.bool,
  extraHeaderComponent: PropTypes.oneOfType([PropTypes.string, instanceOf(Object)]),
  size: PropTypes.string,
  type: PropTypes.string,
  iconPadding: PropTypes.string,
  setShowChart: PropTypes.func,
  paddingTop: PropTypes.string
};

export default SimpleAccordion;
