import {
  faArrowsRotate,
  faBookmark,
  faBookmarkSlash,
  faCopy,
  faEye,
  faTrashCan
} from '@fortawesome/pro-regular-svg-icons';
import { copyLinkButton, attachmentButton, deleteActionV2 } from './_dropdown';
import { colors } from '../../../styles/style';

const editItem = {
  label: 'Ver detalhes',
  id: 'edit',
  modal: 'editItem',
  params: { tab: '0' },
  iconProps: {
    icon: faEye,
    color: colors.neutral400
  }
};

const comment = {
  label: 'Comentar',
  modal: 'editItem',
  id: 'comment-items',
  params: { tab: '3' }
};

const addToMyProducts = {
  label: 'Adicionar à meus produtos',
  button: 'myProducts',
  id: 'add-products',
  params: {
    postPathOptions: '/copy',
    displayToast: 'Produto adicionado em Meus Produtos com sucesso!'
  }
};

const duplicateItem = {
  label: 'Duplicar',
  verb: 'post',
  id: 'duplicate-item',
  params: {
    postPathOptions: '/duplicate'
  },
  iconProps: {
    icon: faCopy,
    color: colors.neutral400
  }
};

const addToLibrary = {
  label: 'Adicionar na biblioteca',
  verb: 'post',
  id: 'copy',
  params: {
    postPathOptions: '/copy',
    displayToast: 'Produto adicionado na biblioteca com sucesso!'
  },
  iconProps: {
    icon: faBookmark,
    color: colors.neutral400
  }
};

const removeItemFromGroup = {
  label: 'Retirar item do grupo',
  verb: 'put',
  id: 'remove-item-group',
  params: {
    updatePathOptions: '/remove-group',
    displayToast: 'Produto adicionado em Meus Produtos com sucesso!',
    refresh: false
  }
};

const linkItem = {
  id: 'link-item',
  label: 'Vincular com item da biblioteca',
  modal: 'linkItem',
  params: { subtitle: 'Busque o item da biblioteca para vincular com o item selecionado' },
  iconProps: {
    icon: faBookmark,
    color: colors.neutral400
  }
};

const unlinkItem = isExternalBase => ({
  id: 'unlink-item',
  label: isExternalBase ? 'Desvincular' : 'Desvincular com a biblioteca',
  modal: 'unlinkItem',
  iconProps: {
    icon: faBookmarkSlash,
    color: colors.neutral400
  }
});

const updateLinkedItem = isExternalBase => ({
  id: 'update-linked-item',
  label: isExternalBase ? 'Restaurar valores SINAPI' : 'Atualizar item com a biblioteca',
  modal: 'updateItem',
  iconProps: {
    icon: faArrowsRotate,
    color: colors.neutral400
  }
});

const _deleteActionV2 = {
  ...deleteActionV2,
  iconProps: {
    icon: faTrashCan,
    color: colors.red500
  }
};

const specificationDropDown = {
  specificationItem: ({ isComposition, isLinked, isExternalBase }) => {
    const items = [];

    items.push(editItem, attachmentButton);

    if (!isComposition) items.push(duplicateItem);

    items.push(copyLinkButton);

    if (!isComposition && !isLinked && !isExternalBase) items.push(addToLibrary);

    if (isExternalBase || isLinked) items.push(updateLinkedItem(isExternalBase));
    else items.push(linkItem);

    items.push(_deleteActionV2);

    return items;
  },
  specificationParent: [
    {
      ..._deleteActionV2,
      params: {
        text: `Ao apagar um nível os itens que estão dentro dele também serão apagados.
                Deseja realmente apagar?`
      }
    }
  ],
  specificationChildren: [
    removeItemFromGroup,
    editItem,
    attachmentButton,
    copyLinkButton,
    addToMyProducts,
    comment,
    _deleteActionV2
  ],
  templateItem: ({ isComposition, isLinked, isExternalBase }) => {
    const items = [];

    items.push({ ...editItem, params: { tab: '0', isTemplate: true } });

    if (!isComposition) items.push(duplicateItem);

    if (!isComposition && isLinked) items.push(unlinkItem(isExternalBase));

    if (isExternalBase || isLinked) items.push(updateLinkedItem(isExternalBase));
    else items.push(linkItem);

    items.push(_deleteActionV2);

    return items;
  }
};

const viewAttachments = {
  ...editItem,
  params: { ...editItem.params, tab: '1' }
};

export { specificationDropDown, editItem, viewAttachments };
