import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Paragraph, Subtitle } from '../Text/Text';
import RichTextEditor from '../RichTextEditor/RichTextEditor';

// components
import AttachmentList from '../List/AttachmentList';
import FileUpload from '../File/FileUpload';

import { Div, colors, spaces } from '../../styles/style';
import useViewport from '../../_Hooks/useViewport';

const ItemAttachmentContent = ({
  data,
  setObservation,
  setFiles,
  readOnly,
  textEditor = true,
  hasDescription = true,
  maxSize = '50mb',
  onAddFile,
  onRemoveFile,
  ...props
}) => {
  const { files = [], observation } = data;
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();

  const handleAddFile = newFiles => {
    setFiles(prev => [...prev, ...newFiles]);
    onAddFile && onAddFile(true);
  };

  const handleRemoveFile = file => {
    onRemoveFile && onRemoveFile(file);
  };

  return (
    <>
      {!readOnly ? (
        <Div $fullWidth direction="column" align="none" gap={_isMobile ? spaces.space1 : spaces.space2}>
          <Subtitle>Enviar arquivos</Subtitle>
          {hasDescription && <p>Envie arquivos como orçamentos, notas fiscais e o que precisar.</p>}
          <Div direction={_isMobile ? 'column' : 'row'} gap={spaces.space1} align={_isMobile ? 'flex-start' : 'center'}>
            <FileUpload
              id="file-input"
              style={{ marginLeft: `-${spaces.space1}` }}
              onChange={handleAddFile}
              multiple
              showGallery={false}
              text="Adicionar documento"
              listType={null}
              buttonProps={{ type: 'primary' }}
              fullWidth
            />
            <Paragraph type="small" color={colors.neutral600}>
              (Tamanho máximo do arquivo: {maxSize})
            </Paragraph>
          </Div>

          <AttachmentList preventUpload files={files} setFiles={setFiles} onRemoveFile={handleRemoveFile} {...props} />

          {textEditor && (
            <>
              <Subtitle>Observações:</Subtitle>
              <RichTextEditor
                onChange={setObservation}
                value={observation}
                height={100}
                toolbar={false}
                statusbar={false}
                outputFormat="html"
              />
            </>
          )}
        </Div>
      ) : (
        <Div $fullWidth>
          <AttachmentList
            preventUpload
            files={files}
            setFiles={setFiles}
            readOnly={readOnly}
            onRemoveFile={handleRemoveFile}
            enumsName="systemData"
            style={{ width: '100%' }}
            {...props}
          />
          {observation && <Subtitle>Observação</Subtitle>}
          <div>{observation ? <Paragraph>{parse(observation)}</Paragraph> : ''}</div>
        </Div>
      )}
    </>
  );
};

ItemAttachmentContent.propTypes = {
  data: PropTypes.instanceOf(Object),
  setFiles: PropTypes.func,
  readOnly: PropTypes.bool,
  setObservation: PropTypes.func,
  textEditor: PropTypes.bool,
  hasDescription: PropTypes.bool,
  maxSize: PropTypes.string,
  onAddFile: PropTypes.func,
  onRemoveFile: PropTypes.func
};

export default ItemAttachmentContent;
