import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { Divider, Menu } from 'antd';
import DropdownV2 from '../Dropdown/DropdownV2';
import Button from './Button';
import useViewport from '../../_Hooks/useViewport';
import { colors, spaces } from '../../styles/style';
import { allDropdown } from '../../lib/mapping/Dropdown/allDropdown';
import { StyledMenu } from '../Dropdown/DropdownV2.styled';
import DropDownButton from './DropDownButton';

const AddTableButtonV2 = ({ onSubmit = f => f, customButton, ...props }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const menu = (
    <StyledMenu $alignLeftOptions={false}>
      {allDropdown.addRefurbishItems(_isMobile)?.map(option => {
        return (
          <React.Fragment key={`menu${option.label}`}>
            {option?.id === 'Search-item-catalog' && !_isMobile && (
              <Divider style={{ margin: `${spaces.space1} 0 0 0` }} />
            )}
            <Menu.Item key={`menuItem${option.label}`}>
              <DropDownButton
                optionData={option}
                id={option.id}
                text
                onClick={() => onSubmit(option)}
                fullWidth
                style={{
                  display: 'flex',
                  justifyContent: 'left',
                  gap: spaces.space1,
                  padding: `${spaces.space1} ${spaces.space1} ${spaces.space1} 0`,
                  ...option?.buttonParams?.style
                }}
              />
            </Menu.Item>
          </React.Fragment>
        );
      })}
    </StyledMenu>
  );

  return (
    <DropdownV2 slim trigger={['click']} menu={menu} {...props}>
      {customButton || (
        <Button
          id="id-add-item"
          size="small"
          type="primary"
          shape="round"
          roundPadding={`0 ${spaces.space2} 0 ${spaces.space0}`}
        >
          <FontAwesomeIcon
            icon={faPlusCircle}
            style={{
              margin: `0 ${spaces.space1} 0 2px`,
              color: colors.white
            }}
            size="lg"
          />
          ITEM
        </Button>
      )}
    </DropdownV2>
  );
};

AddTableButtonV2.propTypes = {
  onSubmit: PropTypes.func,
  customButton: PropTypes.instanceOf(Button),
  isTemplate: PropTypes.bool
};

export default AddTableButtonV2;
