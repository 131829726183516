import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Upload } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from '@fortawesome/pro-light-svg-icons';
import { useSelector } from 'react-redux';
import ImgCrop from 'antd-img-crop';
import { toast } from 'react-toastify';
import { CoverImageDragger, StyledDragger, StyledUpload, CoverImageUploadContainer } from './FileUpload.style';

import Gallery from '../Gallery/Gallery';
import { colors, spaces } from '../../styles/style';
import Button from '../Button/Button';
import ProductGallery from '../Gallery/ProductGallery';
import ImageModal from '../Modal/ImageModal';
import useFileUpload from './useFileUpload';
import Modal from '../Modal/Modal';

const FileUpload = ({
  id,
  multiple = true,
  onChange = f => f,
  onRemove = f => f,
  showGallery = true,
  text = 'Clique para enviar o arquivo',
  startLoading = f => f,
  initialFiles = [],
  children,
  buttonProps,
  galleryProps,
  type,
  textArray,
  limitSize,
  onSuccessUpload,
  onErrorUpload,
  icon,
  tooltipText,
  iconSize,
  subType,
  onUploadButtonClick,
  secondaryButtonText,
  onSecondaryButtonClick,
  fullWidth,
  galleryType = 'common',
  buttonObrigatory,
  noMobileMargin,
  limitNumFiles = 20,
  onlyIcon,
  iconColor,
  onlyButtonOnEmpty,
  ...props
}) => {
  const _initialFiles = [...(Array.isArray(initialFiles) ? initialFiles : [initialFiles])].map(item => ({
    fullpath: item?.fullpath || item,
    status: 'done'
  }));

  const [files, setFiles] = useState(_initialFiles);
  const [filesUploaded, setFilesUploaded] = useState({});
  const [, setUploadFileCount] = useState(0);
  const [selectedFileIndex, setSelectedFileIndex] = useState(0);
  const [showImageModal, setShowImageModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState(null);
  const { user } = useSelector(state => state.authReducer);
  const { handleUpload, createThumbnail, compressImage } = useFileUpload({ limitSize });

  useEffect(() => {
    const fileList = Object.values(filesUploaded);

    if (!fileList.length || fileList.length + _initialFiles?.length !== files.length) {
      return;
    }

    if (multiple) {
      const filesToReturn = [..._initialFiles, ...fileList];
      onChange(
        textArray
          ? filesToReturn.map(f => f.response?.location || f.fullpath)
          : filesToReturn.map(f => ({
              fullpath: f.response?.location || f.fullpath,
              thumbnail: f.response?.thumbnail || f.thumbnail,
              filename: f.name || f.filename,
              idCompany: f.idCompany || user?.idCompany,
              createdAt: f.createdAt || new Date()
            }))
      );
    } else {
      const file = fileList[0];

      onChange(
        textArray
          ? file.response?.location
          : {
              fullpath: file.response?.location || file.fullpath,
              thumbnail: file.response?.thumbnail,
              filename: file.name,
              idCompany: file.idCompany || user?.idCompany,
              createdAt: file.createdAt || new Date()
            }
      );
    }

    if (!showGallery) {
      setFiles([]);
    }

    setFilesUploaded({});
    setIsLoading(false);
  }, [filesUploaded, files]);

  useEffect(() => {
    if (uploadErrorMessage) {
      toast.error(uploadErrorMessage);
      setIsLoading(false);
      setUploadErrorMessage(null);
    }
  }, [uploadErrorMessage]);

  const upload = async (fileObject, onSuccess, onError) => {
    const thumbnail = await createThumbnail(fileObject);
    const compressed = await compressImage(fileObject);

    let uploadThumbnail = Promise.resolve(null);

    if (thumbnail) {
      uploadThumbnail = handleUpload({ fileObject: thumbnail, metadata: { name: `thumbnail_${thumbnail.name}` } });
    }

    return Promise.all([handleUpload({ fileObject: compressed }), uploadThumbnail])
      .then(([f, thumb] = []) => {
        onSuccess && onSuccess({ ...f, thumbnail: thumb?.location });
        return onSuccessUpload && onSuccessUpload({ ...f, thumbnail: thumb?.location });
      })
      .catch(err => {
        setIsLoading(false);
        startLoading();
        onError && onError(err);
        return onErrorUpload && onErrorUpload(err);
      });
  };

  const handleModalOk = file => {
    startLoading();
    setIsLoading(true);
    upload(file).then(() => setIsLoading(false));
  };

  const customRequest = option => {
    const { onSuccess, onError, file } = option;
    upload(file, onSuccess, onError);
  };

  const handleBeforeUpload = (file, fileList) => {
    setUploadFileCount(0);
    if (limitSize && file.size > limitSize) {
      setUploadErrorMessage('O tamanho do arquivo excedeu o limite!');
      return Upload.LIST_IGNORE;
    }

    if (limitNumFiles && fileList.length >= limitNumFiles) {
      setUploadErrorMessage(`O upload não pode possuir mais que ${limitNumFiles} arquivos por vez`);
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  const handleRemove = index => {
    const file = files[index];
    const newArr = [...files];
    newArr.splice(index, 1);

    setFiles(newArr);
    onRemove(file?.id, index);
  };

  const openImageModalInIndex = index => {
    setShowImageModal(true);
    setSelectedFileIndex(index);
  };

  const onChangeUpload = info => {
    startLoading(true);
    setIsLoading(true);

    const { file, fileList } = info;

    if (file.status !== 'uploading') {
      setUploadFileCount(prev => {
        const count = prev + 1;
        if (count >= fileList?.length) {
          setIsLoading(false);
          startLoading();
        }

        return count;
      });
    }

    if (file.status === 'done') {
      setFilesUploaded(prev => ({ ...prev, [file.uid]: file }));
    }

    setFiles(
      fileList
        ?.filter(_file => _file.status !== 'error')
        ?.map(_file => ({
          uid: _file.uid,
          filename: _file.name || _file.filename,
          fullpath: _file.response?.location || _file.fullpath,
          thumbnail: _file.response?.thumbnail,
          status: _file.status,
          idCompany: _file.idCompany || user?.idCompany,
          createdAt: _file.createdAt || new Date()
        }))
    );
  };

  const _props = {
    name: 'file',
    headers: {
      authorization: 'authorization-text'
    },
    customRequest,
    fileList: files,
    onChange: onChangeUpload,
    showUploadList: false,
    listType: 'picture-card',
    multiple,
    type,
    ...props
  };

  const uploadComponent = useMemo(() => {
    if (type === 'dragger') {
      return (
        <StyledDragger {..._props}>
          <FontAwesomeIcon icon={faCloudUpload} />
          <p className="ant-upload-text">{text}</p>
        </StyledDragger>
      );
    }

    if (type === 'avatar') {
      return (
        <ImgCrop shape="round" modalOk="Enviar" onModalOk={handleModalOk} modalTitle="Posicione sua foto">
          <Upload
            showUploadList={false}
            accept="image/png, image/jpeg, image/jpg"
            beforeUpload={handleBeforeUpload}
            id="avatar-input"
          >
            {subType === 'button' ? (
              <Button text onClick={onUploadButtonClick}>
                <FontAwesomeIcon icon={icon} size={iconSize} color={colors.primary600} style={{ cursor: 'pointer' }} />
                {text || 'Enviar'}
              </Button>
            ) : (
              <Tooltip title={tooltipText}>
                <FontAwesomeIcon icon={icon} size={iconSize} color="white" style={{ cursor: 'pointer' }} />
              </Tooltip>
            )}
          </Upload>
        </ImgCrop>
      );
    }

    if (type === 'coverImage') {
      return (
        <CoverImageUploadContainer>
          <ImgCrop
            shape="rect"
            aspect={5.55}
            modalOk="Aplicar"
            onModalOk={handleModalOk}
            modalTitle="Posicione sua imagem"
          >
            <CoverImageDragger
              id="drag-image-input"
              showUploadList={false}
              accept="image/png, image/jpeg, image/jpg"
              beforeUpload={handleBeforeUpload}
            >
              <p id="drag-image-text" style={{ marginBottom: spaces.space1 }}>
                Arraste uma imagem para cá
              </p>
              <p style={{ marginBottom: spaces.space1, fontSize: '12px' }}>ou se preferir</p>
              {subType === 'button' && (
                <Button
                  id="select-file-button"
                  type="primary"
                  onClick={onUploadButtonClick}
                  style={{ width: '148px', marginBottom: spaces.space1 }}
                >
                  {text || 'Enviar'}
                </Button>
              )}

              {secondaryButtonText && (
                <Button type="ghost" style={{ width: '148px' }} onClick={onSecondaryButtonClick}>
                  {secondaryButtonText}
                </Button>
              )}
            </CoverImageDragger>
          </ImgCrop>
        </CoverImageUploadContainer>
      );
    }

    if (type === 'pickCoverImage') {
      return (
        <ImgCrop
          shape="rect"
          modalOk="Aplicar"
          onModalOk={e =>
            handleModalOk(e, f => {
              setFiles([{ fullpath: f?.location || f?.fullpath || f, status: 'done' }]);
              onChange(f?.location);
            })
          }
          modalTitle="Posicione sua imagem"
        >
          <StyledUpload fullWidth={fullWidth} {..._props}>
            <FontAwesomeIcon icon={faCloudUpload} />
            <p className="ant-upload-text">{text}</p>
          </StyledUpload>
        </ImgCrop>
      );
    }

    return (
      <StyledUpload fullWidth noMobileMargin={noMobileMargin} beforeUpload={handleBeforeUpload} {..._props}>
        {showGallery && !buttonObrigatory ? (
          <>
            <FontAwesomeIcon icon={faCloudUpload} />
            <p className="ant-upload-text">{text}</p>
          </>
        ) : (
          <Button
            id={id}
            icon={
              (!buttonProps?.$noIcon || onlyIcon) && <FontAwesomeIcon icon={icon || faCloudUpload} color={iconColor} />
            }
            disabled={user?.isCustomerView}
            {...buttonProps}
          >
            {!onlyIcon && (text || 'Enviar')}
          </Button>
        )}
      </StyledUpload>
    );
  }, [user, showGallery, _props, type, buttonProps, tooltipText]);

  const buttonOnEmpty = onlyButtonOnEmpty ? (
    <StyledUpload fullWidth={fullWidth} noMobileMargin={noMobileMargin} beforeUpload={handleBeforeUpload} {..._props}>
      <Button id="add-image-only-on-empty" type="primary">
        Adicionar imagem
      </Button>
    </StyledUpload>
  ) : null;

  if (showGallery) {
    return galleryType === 'new' ? (
      <>
        <ProductGallery
          images={files}
          uploadComponent={uploadComponent}
          onDelete={handleRemove}
          openImageModalInIndex={openImageModalInIndex}
          buttonOnEmpty={buttonOnEmpty}
          {...galleryProps}
        />
        {showImageModal && (
          <ImageModal
            fileList={files}
            startIndex={selectedFileIndex}
            length={files?.length}
            onClose={() => setShowImageModal(false)}
          />
        )}
      </>
    ) : (
      <div>
        <Gallery onRemove={handleRemove} images={files} noPreview unique={!multiple} {...galleryProps}>
          {uploadComponent}
        </Gallery>
      </div>
    );
  }

  return (
    <>
      <Modal
        hideFooter
        loading
        open={isLoading}
        closable={false}
        loaderStyle={{ marginBottom: spaces.space2 }}
        zIndex="1002"
      />
      <div style={props.style}>{uploadComponent}</div>
    </>
  );
};

FileUpload.propTypes = {
  id: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  showGallery: PropTypes.bool,
  text: PropTypes.string,
  startLoading: PropTypes.func,
  initialFiles: PropTypes.instanceOf(Array),
  children: PropTypes.instanceOf(Object),
  buttonProps: PropTypes.instanceOf(Object),
  galleryProps: PropTypes.instanceOf(Object),
  textArray: PropTypes.bool,
  type: PropTypes.string,
  subType: PropTypes.string,
  limitSize: PropTypes.number,
  onSuccessUpload: PropTypes.func,
  onErrorUpload: PropTypes.func,
  tooltipText: PropTypes.string,
  icon: PropTypes.instanceOf(Object),
  iconSize: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  onUploadButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
  secondaryButtonText: PropTypes.string,
  fullWidth: PropTypes.bool,
  galleryType: PropTypes.string,
  buttonObrigatory: PropTypes.bool,
  noMobileMargin: PropTypes.bool,
  limitNumFiles: PropTypes.number,
  onlyIcon: PropTypes.bool,
  iconColor: PropTypes.string,
  onlyButtonOnEmpty: PropTypes.bool
};

export default FileUpload;
