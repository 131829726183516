import styled, { css } from 'styled-components';

import { colors, spaces } from '../../styles/style';
import Button from '../Button/Button';

const Container = styled.div`
  height: 100%;
`;

const FormContainer = styled.div`
  width: 100%;
  padding: ${spaces.space2};

  ${props =>
    props.$itemInfo &&
    css`
      background-color: ${colors.neutral50};
      border: 1px solid ${colors.neutral100};
    `}
`;

const TabContent = styled.div`
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  padding: ${props => props.padding || spaces.space1};

  section {
    padding: 0 !important;
  }
`;

const CatalogButton = styled(Button)`
  width: 100%;
  justify-content: end;
  display: flex;
  align-items: flex-end;
  color: ${colors.neutral400};
`;

export { Container, TabContent, CatalogButton, FormContainer };
