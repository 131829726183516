import styled from 'styled-components';
import { Table as TableAnt } from 'antd';
import { colors, fonts, spaces, radius } from '../../styles/style';

const Table = styled(TableAnt)`
  border: 1px solid ${colors.neutral100};
  border-radius: ${radius.radius1};

  .virtuallist {
    table {
      padding-bottom: ${props => props.$paddingBottom};
    }
  }


  .row-parent {
    background-color: ${colors.neutral50};
    cursor: ${props => props.$customHover || 'pointer'};
  }

  .row-finance {
    background-color: ${colors.neutral50};
    font-weight: ${fonts.weight700};
    font-size: ${fonts.sizeSm};
    color: ${colors.neutral600};
  }

  p, span {
    font-size: ${fonts.sizeSm};
  }

  .ant-table-row.ant-table-row-level-0 {
    background-color: ${props => props.$parentRowColor || (props.$withCommonRow ? colors.white : colors.neutral50)};
    &.row-parent {
      background-color: ${props => props.$parentRowColor || colors.neutral50};
    }
  }

  ${props =>
    props.$tableRowSpan &&
    `
    .ant-table-row.ant-table-row-level-0 {
      background-color:  ${colors.neutral50};
    }

  `}

  .ant-table-placeholder > td {
    border-bottom: none;
  }

  .ant-table-tbody > tr > td > div {
    white-space: ${props => (props.tableLayout ? 'unset' : 'normal')};
    display: ${props => (props.$displayBlock ? 'inline-block' : 'inline-flex')};

    align-items: ${props => props.$alignItems || 'center'};
  }

  .ant-table-thead > tr > th {
    background: ${props => props.$customTheadColor || colors.neutral50};
    &:hover {
      background: ${props => props.$customTheadColor || colors.neutral50};
    }
  }

  .ant-table-tbody > tr.ant-table-row,
  .ant-table-tbody > tr.ant-table-row-selected {
    &:hover > td {
      cursor: ${props => props.$customHover || 'pointer'};
      background-color: ${colors.primary50};
    }
  }

  .ant-table-row {
    background-color: ${props => props.$childRowColor || colors.white};
    &:hover {
      + .inlineButtons {
        display: block;
      }

      .showHover {
        display: inherit;
      }
    }

    &.drop-over-downward {
      td {
        border-bottom: 2px dashed ${colors.primary600} !important;
        position: relative;
      }
    }

    &.drop-over-upward {
      td {
        border-top: 2px dashed ${colors.primary600};
        position: relative;
      }
    }
  }

  .ant-table-row > td:has(.prevent-cell-line-break) {
    display: flex;
    flex-wrap: nowrap;
  }

  .ant-table-tbody {
    .inlineButtons {
      z-index: 100;
      position: absolute;
      height: 2px;
      width: 100%;
      background-color: ${colors.primary600};
      display: none;

      .buttonContainer {
        display: flex;
        gap: ${spaces.space1};
        margin-left: 60px;
        width: 250px;
        margin-top: -10px;
      }

      &:hover {
        display: block;
      }
    }

    .forceShow {
      display: block;
    }
  }

  .ant-table-row-expand-icon {
    background-color: transparent;
    border: none;
    border-radius: 50%;
    padding: ${spaces.space2};
    margin: 0;
    &:hover {
      background-color: ${colors.neutral200};
    }

    &.ant-table-row-expand-icon-expanded,
    &.ant-table-row-expand-icon-collapsed {
      :after {
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 7px solid ${colors.neutral600};
        background: transparent;
        top: 10px;
        left: 12px;
      }

      :before {
        content: none;
      }
    }
  }

  ${props =>
    props.$forceLeft &&
    `
      .force-left {
        left: ${props.$forceLeft}px !important;
      }
  `}

  .ant-table-cell {
    padding: ${spaces.space1};

    font-size: ${fonts.sizeSm};
    vertical-align: middle;
    border-bottom: 1px solid ${colors.neutral100} !important;

    .secondary {
      background-color: white;
      width: 100%;
    }

    &-row-hover {
      .secondary {
        background-color: transparent;
      }
    }

    &.ant-table-cell-with-append {
      div {
        display: inline-flex;
      }
    }

  }


  .cell-before-80 {
    :before {
        height: 80% !important;
      }
  }

  .cell-before-full {
    :before {
        height: 100% !important;
      }
  }

  .cell-color-white {
    background-color: ${colors.white};
  }

  .cell-color-primary-99 {
    background-color: ${colors.neutral50};
  }

  .cell-color-primary-95 {
    background-color: ${colors.primary50};
    :hover {
      background-color: ${colors.primary100};
    }
  }

  .cell-color-neutral-95 {
    background-color: ${colors.neutral75};
    :hover {
      background-color: ${colors.neutral100};
    }
  }

  .cell-color-primary-40 {
    background-color: ${colors.primary600};
    :hover {
      background-color: ${colors.primary700};
    }
  }

  .cell-with-border-top-primary-40 {
    border-top: 1px solid ${colors.primary600};
  }

  .cell-before-none {
    :before {
        display: none !important;
      }
  }

  .ant-table-cell.noPadding {
      padding: 0px !important;
    }

  .ant-table-selection-column {
    text-align: left;
  }

  .ant-table-thead .ant-table-cell {
    padding: calc(${spaces.space0} + 1px) ${spaces.space1};
    .row-parent {
      font-weight: ${fonts.weight600};
    }
  }

  .ant-table-tbody tr {
    height: ${spaces.space6};
  }

  .ant-table-row-level-0.row-parent .ant-table-cell {
    font-weight: ${fonts.weight600};
    font-size: ${fonts.sizeSm};

    .table-name {
      font-size: ${fonts.sizeMd};
      div div {
        font-size: ${fonts.sizeMd};
      }
    }

    .table-name div div {
      font-size: ${fonts.sizeMd};
    }
  }

  .ant-table-row-level-1.row-parent .ant-table-cell {
    font-weight: ${fonts.weight600};
    color: ${colors.neutral600};
    font-size: ${fonts.sizeSm};
  }

  ${props =>
    props.isGrouped &&
    `
    .ant-table-row-level-0 {
      background-color: ${colors.neutral50};
      cursor: pointer;
    }
    .ant-table-row-level-0 .ant-table-cell {
      font-weight: ${fonts.weight600};
    }
  `}

  th.ant-table-cell.ant-table-column-sort {
    background-color: ${colors.neutral50};
  }

  .ant-table-column-sorter {
    display: none;
  }

  .row-parent .ant-table-cell.ant-table-column-sort {
    background-color: transparent;
  }

  .ant-table-cell.ant-table-column-sort {
    background-color: transparent;
  }

  .ant-table-body {
    min-height: ${props => props.$minHeight || `calc(100vh - 360px)`};
  }

  .ant-table-body::-webkit-scrollbar {
    display: block;
  }

  .ant-table-summary {
    background-color: ${colors.neutral50};
    .ant-table-cell {
      border-right: none !important;
    }
    .center {
      text-align: center;
    }
  }

  .ant-table-footer {
    border: none !important;
    border-top: 1px solid ${colors.neutral100} !important;
    ${props => props.$footerPadding && `padding: ${props.$footerPadding}`}
    background-color: ${colors.neutral50};
    font-weight: ${props => props.$footerWeight || fonts.weight700};
    font-size: ${fonts.sizeSm};
    color: ${colors.neutral600};
  }
`;

const TablePdf = styled(TableAnt)`
  border: 1px solid ${colors.neutral100};
  border-radius: ${radius.radius1};

  .row-parent {
    background-color: ${colors.neutral50};
    cursor: ${props => props.$customHover || 'pointer'};
  }

  .row-finance {
    background-color: ${colors.neutral50};
    font-weight: ${fonts.weight700};
    font-size: ${fonts.sizeSm};
    color: ${colors.neutral600};
  }

  .ant-table-row.ant-table-row-level-0 {
    background-color: ${props => props.$parentRowColor || (props.$withCommonRow ? colors.white : colors.neutral50)};
    &.row-parent {
      background-color: ${props => props.$parentRowColor || colors.neutral50};
    }
  }

  ${props =>
    props.$tableRowSpan &&
    `
    .ant-table-row.ant-table-row-level-0 {
      background-color:  ${colors.neutral50};
    }

  `}

  .ant-table-placeholder > td {
    border-bottom: none;
  }

  .ant-table-tbody > tr > td > div {
    white-space: ${props => (props.tableLayout ? 'unset' : 'normal')};
    display: ${props => (props.$displayBlock ? 'inline-block' : 'inline-flex')};

    align-items: ${props => props.$alignItems || 'center'};
  }

  .ant-table-thead > tr > th {
    background: ${colors.white};
    &:hover {
      background: ${props => props.$customTheadColor || colors.neutral50};
    }
  }

  .custom-title-pdf{
    font-weight: ${fonts.weight700};
    color: ${colors.neutral800};
  }

  .custom-content-pdf{
    color: ${colors.neutral800};
    font-size: ${fonts.sizeSm};
  }

  .ant-table-tbody > tr.ant-table-row,
  .ant-table-tbody > tr.ant-table-row-selected {
    &:hover > td {
      cursor: ${props => props.$customHover || 'pointer'};
      background-color: ${colors.primary50};
    }
  }

  .ant-table-row {
    background-color: ${props => props.$childRowColor || colors.white};
    &:hover {
      + .inlineButtons {
        display: block;
      }

      .showHover {
        display: inherit;
      }
    }

    &.drop-over-downward {
      td {
        border-bottom: 2px dashed ${colors.primary600} !important;
        position: relative;
      }
    }

    &.drop-over-upward {
      td {
        border-top: 2px dashed ${colors.primary600};
        position: relative;
      }
    }
  }

  .ant-table-tbody {
    .inlineButtons {
      z-index: 100;
      position: absolute;
      height: 2px;
      width: 100%;
      background-color: ${colors.primary600};
      display: none;

      .buttonContainer {
        display: flex;
        gap: ${spaces.space1};
        margin-left: 60px;
        width: 250px;
        margin-top: -10px;
      }

      &:hover {
        display: block;
      }
    }

    .forceShow {
      display: block;
    }
  }

  .ant-table-row-expand-icon {
    background-color: transparent;
    border: none;
    border-radius: 50%;
    padding: ${spaces.space2};
    margin: 0;
    &:hover {
      background-color: ${colors.neutral200};
    }

    &.ant-table-row-expand-icon-expanded,
    &.ant-table-row-expand-icon-collapsed {
      :after {
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 7px solid ${colors.neutral600};
        background: transparent;
        top: 10px;
        left: 12px;
      }

      :before {
        content: none;
      }
    }
  }

  .ant-table-cell {
    padding: ${spaces.space1};
    font-size: ${fonts.sizeSm};
    vertical-align: middle;
    background-color: ${colors.white};
    color: ${colors.neutral800} !important;
    border-bottom: 1px solid ${colors.neutral100} !important;
    border-right: none;
    border-left:none;
    .secondary {
      background-color: white;
      width: 100%;
    }

    &-row-hover {
      .secondary {
        background-color: transparent;
      }
    }

  }

  .cell-with-before {
    :before {
      position: absolute;
      top: 50%;
      right: 0;
      width: 1px;
      background-color: rgba(0, 0, 0, 0.06);
      transform: translateY(-50%);
      transition: background-color 0.3s;
      content: "";
      }
  }

  .cell-before-80 {
    :before {
        height: 80% !important;
      }
  }

  .cell-before-full {
    :before {
        height: 100% !important;
      }
  }

  .cell-color-white {
    background-color: ${colors.white};
  }

  .cell-color-primary-99 {
    background-color: ${colors.neutral50};
  }

  .cell-color-primary-95 {
    background-color: ${colors.primary50};
    :hover {
      background-color: ${colors.primary100};
    }
  }

  .cell-color-neutral-95 {
    background-color: ${colors.neutral75};
    :hover {
      background-color: ${colors.neutral100};
    }
  }

  .cell-color-primary-40 {
    background-color: ${colors.primary600};
    :hover {
      background-color: ${colors.primary700};
    }
  }

  .cell-with-border-top-primary-40 {
    border-top: 1px solid ${colors.primary600};
  }

  .cell-before-none {
    :before {
        display: none !important;
      }
  }

  .ant-table-cell.noPadding {
      padding: 0px !important;
    }

  .ant-table-selection-column {
    text-align: left;
  }

  .ant-table-thead .ant-table-cell {
    padding: calc(${spaces.space0} + 1px) ${spaces.space1};
    .row-parent {
      font-weight: ${fonts.weight700};
    }
  }

  .ant-table-tbody tr {
    height: ${spaces.space6};
  }

  .ant-table-row-level-0.row-parent .ant-table-cell {
    font-weight: ${fonts.weight600};
    font-size: ${fonts.sizeSm};
    color: ${colors.neutral800};

    .table-name {
      font-size: ${fonts.sizeSm};
      div div {
        font-size: ${fonts.sizeSm};
      }
    }

  }

  .ant-table-row-level-1.row-parent .ant-table-cell {
    font-weight: ${fonts.weight600};
    color: ${colors.neutral800};
    font-size: ${fonts.sizeSm};
  }

  ${props =>
    props.isGrouped &&
    `
    .ant-table-row-level-0 {
      background-color: ${colors.neutral50};
      cursor: pointer;
    }
    .ant-table-row-level-0 .ant-table-cell {
      font-weight: ${fonts.weight600};
    }
  `}

  th.ant-table-cell.ant-table-column-sort {
    background-color: ${colors.neutral50};
  }

  .ant-table-column-sorter {
    display: none;
  }

  .row-parent .ant-table-cell.ant-table-column-sort {
    background-color: transparent;
  }

  .ant-table-cell.ant-table-column-sort {
    background-color: transparent;
  }

  .ant-table-body {
    ${props => props.$padBottom && `padding-bottom: ${spaces.space2};`}
    min-height: ${props => props.$minHeight || `calc(100vh - 360px)`};
  }

  .ant-table-body::-webkit-scrollbar {
    display: block;
  }

  .ant-table-summary {
    background-color: ${colors.neutral50};
    .ant-table-cell {
      border-right: none !important;
    }
    .center {
      text-align: center;
    }
  }

  .ant-table-footer {
    border: none !important;
    border-top: 1px solid ${colors.neutral100} !important;
    ${props => props.$footerPadding && `padding: ${props.$footerPadding}`}
    background-color: ${colors.neutral75};
    font-weight: ${props => props.$footerWeight || fonts.weight700};
    font-size: ${fonts.sizeSm};
    color: ${colors.neutral600};
  }
`;

export { Table, TablePdf };
