import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import { TabPane } from './Tabs.style';
import Button from '../Button/Button';
import RefurbishApportionment from '../Table/RefurbishApportionment';
import { apportionmentTotalsColumns } from '../../lib/mapping/TableOrList/apportionmentColumns';
import SimpleTable from '../Table/SimpleTable';
import { TableContainer } from '../Table/CashFlowTable.style';
import { store } from '../../lib/config/redux-store';
import Tabs from './Tabs';
import { colors, Div, spaces } from '../../styles/style';
import { Paragraph, Subtitle, Title } from '../Text/Text';
import formatCurrency from '../../lib/helpers/formatCurrency';
import AddRefurbishApportionmentModal from '../Modal/AddRefurbishApportionmentModal';
import ConfirmModal from '../Modal/ConfirmModal';
import CatalogDrawer from '../Drawer/CatalogDrawer';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import SearchItemApportionment from '../Button/SearchItemApportionment';

const PurchaseApportionment = ({
  isPrinting,
  isView,
  model,
  itemObjectsMap,
  itemSettersMap,
  showTabHeader = true,
  isMobile,
  isSupplierView,
  ...appropriationProps
}) => {
  const { authReducer } = store.getState();
  const { user } = authReducer || {};
  const { refurbishItemType = {} } = useSelector(state => state.setup.enums) || {};
  const { plans, permissions } = useSelector(state => state.setup);

  const isQuote = model === 'quote';

  const [selectedTabApportionment, setSelectedTabApportionment] = useState('1');

  const hasSplitOrderPermission = hasPermission(user, ['splitOrder'], plans, permissions);

  const stringsMap = {
    order: {
      model: 'ordem de compra',
      paragraph: 'Escolha o(s) projeto(s) para adicionar à ordem de compra.',
      paragraphBeta: 'Escolha o projeto para adicionar à ordem de compra.',
      parentColumnName: 'orderItems',
      childrenColumnName: 'orderItemLevels',
      selectLabel: 'Projeto'
    },
    quote: {
      model: 'cotação',
      paragraph: 'Escolha o(s) projeto(s) para adicionar à cotação.',
      paragraphBeta: 'Escolha o projeto para adicionar à cotação.',
      parentColumnName: 'quoteItems',
      childrenColumnName: 'quoteItemLevels',
      selectLabel: 'Projeto'
    }
  };

  const {
    addRefurbishModal,
    setAddRefurbishModal,
    listRefurbish,
    addItemType,
    setAddItemType,
    totals,
    openAllRefurbishes,
    setOpenAllRefurbishes,
    purchaseList,
    handleSetList,
    invalidPaymentItems,
    setInvalidPaymentItems,
    removeRefurbishModal,
    addNewProject,
    setRemoveRefurbishModal,
    removeRefurbish,
    pressedButtonType,
    setPressedButtonType,
    showCatalogDrawer,
    setShowCatalogDrawer,
    handleAddAll,
    onItemClick,
    handleSubmitCatalog,
    itemsOnListMap,
    tabEnum,
    apportionmentTotalsList,
    setTabView,
    idQuote
  } = appropriationProps || {};

  const itemWithoutId = () =>
    !purchaseList || Object.keys(purchaseList).length > 1
      ? false
      : Array.isArray(Object.values(purchaseList)[0]) && Object.values(purchaseList)[0].some(item => !item.idItem);

  const paragraphTotal = (
    <Paragraph>
      Total orçado: <Subtitle>{formatCurrency(totals?.[1] || 0, { currencySymbol: 'R$' })}</Subtitle>
    </Paragraph>
  );

  const refurbishApportionment = (
    <RefurbishApportionment
      isPrinting={isPrinting}
      list={listRefurbish}
      addItemType={addItemType}
      setOpenAllRefurbishes={setOpenAllRefurbishes}
      openAllRefurbishes={openAllRefurbishes}
      setRemoveRefurbishModal={setRemoveRefurbishModal}
      totals={totals}
      handleSetList={handleSetList}
      purchaseList={purchaseList}
      itemObjectsMap={itemObjectsMap}
      itemSettersMap={itemSettersMap}
      isEdit={!isView}
      invalidPaymentItems={invalidPaymentItems}
      setInvalidPaymentItems={setInvalidPaymentItems}
      emptyObj={{
        title: 'Selecione um projeto',
        description: `Adicione um projeto e tenha acesso aos itens em sua ${stringsMap[model].model}.`
      }}
      setAddRefurbishModal={setAddRefurbishModal}
      parentColumnName={stringsMap[model].parentColumnName}
      childrenColumnName={stringsMap[model].childrenColumnName}
      isOrderBlock={!!idQuote}
      isQuote={isQuote}
    />
  );

  const apportionmentTotals = (
    <TableContainer padding={!isPrinting && !isMobile ? spaces.space2 : undefined}>
      <SimpleTable
        columns={apportionmentTotalsColumns({ isPrinting, isQuote, isMobile })}
        data={apportionmentTotalsList}
        isExpandable={false}
        $borderBottom
        border={isMobile ? 'none' : undefined}
      />
    </TableContainer>
  );

  return (
    <>
      {isPrinting ? (
        <>
          {refurbishApportionment}
          {listRefurbish.length > 1 && (
            <>
              <Divider style={{ margin: '0' }} />
              <Div $fullWidth direction="column" align="start" gap={spaces.space2} padding={spaces.space2}>
                <Subtitle>Totais dos itens</Subtitle>
                {apportionmentTotals}
              </Div>
            </>
          )}
        </>
      ) : (
        <Tabs
          width="100%"
          activeKey={selectedTabApportionment}
          onChange={setSelectedTabApportionment}
          background={colors.neutral50}
          tabBarExtraContent={
            <Div justify="end" gap={spaces.space2}>
              {!isPrinting && showTabHeader && !isMobile && paragraphTotal}
              {selectedTabApportionment === tabEnum.list &&
              !isView &&
              !idQuote &&
              (hasSplitOrderPermission || !listRefurbish.length) ? (
                <Button onClick={() => setAddRefurbishModal(true)} type="primary" ghost>
                  + Projeto
                </Button>
              ) : null}
            </Div>
          }
          onTabClick={e => {
            setTabView(e);
          }}
        >
          <TabPane key={tabEnum?.list} tab="Itens solicitados">
            {isMobile ? (
              <Div justify="space-between" padding={`${spaces.space2} ${spaces.space2} 0 ${spaces.space2}`}>
                <Title>Itens*</Title>
                {paragraphTotal}
              </Div>
            ) : null}
            {refurbishApportionment}
            {listRefurbish.length && !isView ? (
              <SearchItemApportionment
                addItemType={addItemType}
                pressedButtonType={pressedButtonType}
                setPressedButtonType={setPressedButtonType}
                setAddItemType={setAddItemType}
                onItemClick={onItemClick}
                setShowCatalogDrawer={setShowCatalogDrawer}
                handleAddAll={handleAddAll}
              />
            ) : null}
          </TabPane>
          {listRefurbish.length > 1 ? (
            <TabPane key={tabEnum?.totals} tab="Totais dos itens" style={{ width: '100%' }}>
              <Div $fullWidth>{apportionmentTotals}</Div>
            </TabPane>
          ) : null}
        </Tabs>
      )}

      {addRefurbishModal ? (
        <AddRefurbishApportionmentModal
          onSubmit={list => addNewProject(list, itemWithoutId())}
          onClose={() => setAddRefurbishModal(false)}
          refurbishList={listRefurbish?.map(r => r.id)}
          hasAddLibrary={itemWithoutId()}
          texts={stringsMap[model]}
          hasSplitOrderPermission={hasSplitOrderPermission}
          hasSinapiItems={
            Array.isArray(Object.values(purchaseList)[0]) &&
            Object.values(purchaseList)[0].some(item => !item.idItem && !item.idRefurbishItem)
          }
        />
      ) : null}
      {removeRefurbishModal ? (
        <ConfirmModal
          alertInfo={listRefurbish?.length === 1 ? 'Essa operação não pode ser desfeita.' : null}
          text={
            listRefurbish?.length === 1
              ? 'Ao remover o projeto todos os itens do rateio serão perdidos.'
              : 'Deseja realmente remover esse projeto do rateio?'
          }
          onSubmit={() => removeRefurbish({ id: removeRefurbishModal })}
          onClose={() => setRemoveRefurbishModal(null)}
        />
      ) : null}
      {showCatalogDrawer && (
        <CatalogDrawer
          open
          onClose={() => {
            setShowCatalogDrawer(false);
            setPressedButtonType(null);
          }}
          createLibrary
          onSubmit={(input, _, idReference) => handleSubmitCatalog(input, idReference)}
          tabsToOpen={[-1, 0, 1]}
          idReference={listRefurbish[0].id}
          refurbishesOptions={listRefurbish?.map(r => r.id)}
          itemsOnListMap={itemsOnListMap}
          subtitle={
            showCatalogDrawer?.isLink
              ? 'Busque o item da biblioteca para vincular com o item selecionado'
              : 'Busque pelo produto ou serviço que deseja adicionar'
          }
          fixedType={refurbishItemType.productLabor}
          blockSinapi={listRefurbish?.length > 1}
        />
      )}
    </>
  );
};

PurchaseApportionment.propTypes = {
  isPrinting: PropTypes.bool,
  isView: PropTypes.bool,
  model: PropTypes.string,
  itemObjectsMap: PropTypes.instanceOf(Object),
  itemSettersMap: PropTypes.instanceOf(Object),
  showTabHeader: PropTypes.bool,
  isMobile: PropTypes.bool,
  isSupplierView: PropTypes.bool
};

export default PurchaseApportionment;
