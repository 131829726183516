import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { faShop } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

import { columns as quoteSuppliersColumns } from '../../lib/mapping/TableOrList/quoteSuppliersColumns';
import useCRUD from '../../_Hooks/useCRUD';
import { colors, spaces } from '../../styles/style';
import useViewport from '../../_Hooks/useViewport';
import TableOrListV2 from '../List/TableOrListV2';
import NoContent from '../NoContent/NoContent';
import Button from '../Button/Button';
import EditQuoteSupplierDrawer from '../Drawer/EditQuoteSupplierDrawer';
import usePagination from '../../_Hooks/usePagination';
import CenteredLoader from '../Loader/CenteredLoader';
import AddQuoteSuppliersDrawer from '../Drawer/AddQuoteSuppliersDrawer';
import { exportToModel } from '../../lib/helpers/helper';

const limit = 20;

const QuoteSuppliersTable = ({ idQuote, splitId }) => {
  const { quoteSupplierStatus } = useSelector(state => state.setup.enums);
  const { user } = useSelector(state => state.authReducer);
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();

  const { pending, waitingResponse, refused, answered, analyzed, buy } = quoteSupplierStatus || {};
  const { company } = user || {};

  const [list, setList] = useState([]);
  const [bestPrice, setBestPrice] = useState(null);
  const [openSuppliersDrawer, setOpenSuppliersDrawer] = useState(false);
  const [openEditQuoteSupplierDrawer, setOpenEditQuoteSupplierDrawer] = useState({ open: false });
  const [order, setOrder] = useState([['status', 'desc']]);

  const { totalItems, setTotalItems: _setTotalItems, handleGet, loading } = useCRUD({
    model: 'quoteSuppliers',
    immediatelyLoadData: false
  });

  const { handleUpdate } = useCRUD({
    model: 'quoteSuppliers',
    immediatelyLoadData: false
  });

  const { handleGet: getBestPrice, loading: bestPriceLoading } = useCRUD({
    model: `quoteSuppliers/bestPrice/${idQuote}`,
    immediatelyLoadData: false
  });

  const { handleGet: handleExportItem } = useCRUD({
    model: 'quoteItems',
    immediatelyLoadData: false
  });

  const composeSort = {
    supplier: ['supplier', 'name'],
    total: ['subTotal']
  };

  const quoteSupplierStatusMap = {
    [pending.id]: { ...pending },
    [waitingResponse.id]: { ...waitingResponse },
    [refused.id]: { ...refused },
    [answered.id]: { ...answered },
    [analyzed.id]: { ...analyzed },
    [buy.id]: { ...buy },
    disabledStatusUpdate: [buy.id]
  };

  const handleExport = (idQuoteSupplier, exportType, supplierReference) =>
    exportToModel({
      handleGet: handleExportItem,
      refetchOptions: {
        where: {
          idQuote
        },
        include: [
          { model: 'unit', attributes: ['id', 'name'], as: 'units' },
          {
            model: 'quoteItemSuppliers',
            as: 'quoteItemSuppliers',
            include: [{ model: 'quoteSuppliers', ...(supplierReference ? { where: { supplierReference } } : {}) }]
          }
        ]
      },
      exportType,
      prefixName: 'quoteItems'
    });

  const handleLoad = ({ offset }) => {
    getBestPrice({
      generateLoading: !totalItems
    })
      .then(resp => {
        setBestPrice(resp?.bestPrice);
        return handleGet({
          refetchOptions: {
            where: {
              idQuote: splitId || idQuote,
              ...(!!splitId && { isSplit: true })
            },
            include: ['supplier', 'files'],
            order,
            limit,
            offset: offset || 1
          },
          generateLoading: !totalItems
        });
      })
      .then(response => {
        if (response?.error) return;

        if (splitId) _setTotalItems(response.length);
        setList(prev => (offset > 1 ? [...prev, ...response] : response));
      });
  };

  const { handleScroll, setTotalItems } = usePagination.useScrollPagination({
    totalItems,
    handleLoad,
    listLength: list?.length,
    initialOffset: 1,
    order
  });

  useEffect(() => {
    setTotalItems(totalItems);
  }, [totalItems]);

  const handleSorting = ({ key, order: _order }) =>
    setOrder([key ? [...(composeSort[key] || [key]), _order] : [[['status', 'desc']]]]);

  return loading || bestPriceLoading ? (
    <CenteredLoader />
  ) : (
    <>
      {!totalItems ? (
        <NoContent
          description="Busque ou crie novos fornecedores para realizar pedidos de Cotação"
          icon={faShop}
          showButtonIcon={false}
          renderButton={false}
          styleIcon={{ color: colors.primary300 }}
          descriptionColor={colors.neutral500}
          style={{ width: '100%', padding: `${spaces.space3} 0 ${spaces.space3} 0` }}
        />
      ) : (
        <TableOrListV2
          id="quote-suppliers"
          columns={quoteSuppliersColumns({
            handleExport,
            quoteSupplierStatus,
            quoteSupplierStatusMap,
            isMobile: _isMobile,
            bestPrice,
            handleLoad,
            handleOpenEditQuoteSupplierDrawer: ({ data, print }) =>
              setOpenEditQuoteSupplierDrawer({ open: true, data, print }),
            whatsappMessage: company?.configuration?.sendQuoteMessage,
            handleUpdate: (idQuoteSupplier, field, value) =>
              handleUpdate({
                id: idQuoteSupplier,
                values: { [field]: value },
                refresh: false,
                generateLoading: false
              }).then(resp => !resp?.error && handleLoad({ delay: false, offset: 1 }))
          })}
          list={list}
          rowSelection={false}
          readOnlyMobile
          cardPadding={`${spaces.space2} ${spaces.space2} ${spaces.space1}`}
          refreshColumns
          withCommonRow
          onSort={handleSorting}
          onClick={row => setOpenEditQuoteSupplierDrawer({ open: true, data: row })}
          scroll={{ y: '375px', x: '100%' }}
          onReachEnd={handleScroll}
        />
      )}
      <Button
        id="add-quote-supplier"
        text
        type="primary"
        style={{ alignSelf: 'unset' }}
        onClick={() => setOpenSuppliersDrawer(true)}
        align="flex-start"
      >
        <FontAwesomeIcon icon={faPlus} />
        Adicionar fornecedor
      </Button>

      {openSuppliersDrawer && (
        <AddQuoteSuppliersDrawer
          idQuote={idQuote}
          onClose={() => setOpenSuppliersDrawer(false)}
          quoteSupplierList={list}
          afterAddQuoteSupplier={() => {
            handleLoad({ delay: false, offset: 1 });
          }}
          splitId={splitId}
        />
      )}
      {openEditQuoteSupplierDrawer?.open && (
        <EditQuoteSupplierDrawer
          open={openEditQuoteSupplierDrawer?.open}
          idQuoteSupplier={openEditQuoteSupplierDrawer?.data?.id}
          splitId={openEditQuoteSupplierDrawer?.data?.splitId}
          supplierReference={openEditQuoteSupplierDrawer?.data?.supplierReference}
          isPrint={openEditQuoteSupplierDrawer?.print}
          handleClose={refreshOnCLose => {
            setOpenEditQuoteSupplierDrawer(null);
            if (refreshOnCLose) handleLoad({ offset: 1 });
          }}
        />
      )}
    </>
  );
};

QuoteSuppliersTable.propTypes = {
  idQuote: PropTypes.string,
  splitId: PropTypes.string
};

export default QuoteSuppliersTable;
