import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { faPlus, faSearch } from '@fortawesome/pro-solid-svg-icons';
import Button from './Button';
import { colors, Div, spaces } from '../../styles/style';
import AddPurchaseItemForm from '../Form/AddPurchaseItemForm';
import { store } from '../../lib/config/redux-store';

const SearchItemApportionment = ({
  addItemType,
  pressedButtonType,
  setPressedButtonType,
  setAddItemType,
  onItemClick,
  setShowCatalogDrawer,
  handleAddAll,
  renderButtons = true
}) => {
  const { authReducer } = store.getState();
  const { user } = authReducer || {};
  const { refurbishItemType = {} } = useSelector(state => state.setup.enums) || {};
  return (
    <Div
      borderBottom={`1px solid ${colors.neutral100}`}
      borderTop={`1px solid ${colors.neutral100}`}
      id="add-apportionment-item-container"
      $backgroundColor={colors.neutral50}
      $fullWidth
      minHeight={spaces.space6}
      style={{ position: 'sticky', bottom: spaces.space6, zIndex: 100 }}
    >
      <Div padding={spaces.space1} direction="column" $fullWidth>
        {addItemType ? (
          <AddPurchaseItemForm
            idCompany={user?.idCompany}
            handleSubmit={handleAddAll}
            onClose={() => {
              setAddItemType(null);
              setPressedButtonType(null);
            }}
            type={addItemType}
            paymentView
          />
        ) : null}
        {renderButtons ? (
          <Div>
            <Button
              id="add-apportionment-item"
              text={pressedButtonType !== 'search'}
              type="primary"
              onClick={() => {
                if (addItemType) return;
                setPressedButtonType('search');
                !addItemType && setShowCatalogDrawer(true);
              }}
            >
              <FontAwesomeIcon icon={faSearch} />
              Buscar item
            </Button>
            <Button
              id="add-product-purchase-item"
              text={pressedButtonType !== 'addProduct'}
              type="primary"
              onClick={() => {
                if (addItemType) return;
                setPressedButtonType('addProduct');
                !addItemType && onItemClick(refurbishItemType.product);
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
              Produto
            </Button>
            <Button
              id="add-service-purchase-item"
              text={pressedButtonType !== 'addLabor'}
              type="primary"
              onClick={() => {
                if (addItemType) return;
                setPressedButtonType('addLabor');
                !addItemType && onItemClick(refurbishItemType.labor);
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
              Serviço
            </Button>
          </Div>
        ) : null}
      </Div>
    </Div>
  );
};

SearchItemApportionment.propTypes = {
  addItemType: PropTypes.bool,
  pressedButtonType: PropTypes.string,
  setPressedButtonType: PropTypes.func,
  setAddItemType: PropTypes.func,
  onItemClick: PropTypes.func,
  setShowCatalogDrawer: PropTypes.func,
  handleAddAll: PropTypes.func,
  renderButtons: PropTypes.bool
};

export default SearchItemApportionment;
