import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useContextHook } from '../../contexts/GeneralContext';
import { Container, RowValue } from './PaymentExtraValuesForm.styled';
import formatCurrency from '../../lib/helpers/formatCurrency';
import Button from '../Button/Button';
import MaskedInput from '../Input/NewMaskedInput';
import formatNumber from '../../lib/helpers/formatNumber';
import { calcPercentageByValue, calcValuesByPercentage } from '../../lib/helpers/helper';
import { colors, spaces } from '../../styles/style';
import { useIsMount } from '../../_Hooks/useIsMount';
import { Paragraph } from '../Text/Text';
import TooltipIcon from '../Tooltip/TooltipIcon';

const PaymentExtraValuesForm = ({
  creditDisabled,
  fixSubTotal,
  childColumnName = 'paymentItems',
  showTotalDetails = true,
  showOtherTaxes,
  useDynamicListValues
}) => {
  const { values, setField, isView, extraValueErrors, setExtraValueErrors, getCustomValues } = useContextHook();
  const {
    [childColumnName]: list,
    discount,
    shipping,
    taxes,
    paidValue,
    total,
    cancelledValue,
    allInterest,
    allDueDateDiscount,
    openAmount,
    otherTaxes,
    allSplitInterest,
    allSplitDueDateDiscount
  } = values || {};
  const [subtotal, setSubtotal] = useState(fixSubTotal || 0);
  const [addDiscount, setAddDiscount] = useState(formatNumber(discount) > 0);
  const [discountPercentage, setDiscountPercentage] = useState();
  const [addShipping, setAddShipping] = useState(formatNumber(shipping) > 0);
  const [shippingPercentage, setShippingPercentage] = useState();
  const [addTaxes, setAddTaxes] = useState(formatNumber(taxes) > 0);
  const [taxesPercentage, setTaxesPercentage] = useState();
  const [addOtherTaxes, setAddOtherTaxes] = useState(formatNumber(otherTaxes) > 0);
  const [otherTaxesPercentage, setOtherTaxesPercentage] = useState();
  const [viewTotal, setViewTotal] = useState(total);

  const [totalsListMap, setTotalsListMap] = useState({});

  const isMount = useIsMount(values);
  const _allInterest = allSplitInterest || allInterest;
  const _allDueDateDiscount = allSplitDueDateDiscount || allDueDateDiscount;
  const resetField = (property, setPercentageField, setState) => {
    setField(property)(null);
    setPercentageField('0,00');
    setState(false);
  };

  useEffect(() => {
    let _subtotal = 0;
    if (!fixSubTotal) {
      list?.forEach(i => {
        const item = getCustomValues ? getCustomValues(i) : i;
        _subtotal += Number(item.quantity) * Number(item.price);
      });

      setSubtotal(Number(_subtotal?.toFixed(2) || 0));
    }
    if (fixSubTotal && useDynamicListValues) {
      _subtotal = Number(fixSubTotal || 0);
      const newTotalsListMap = {};

      list?.forEach(i => {
        const item = getCustomValues ? getCustomValues(i) : i;
        const quantity = Number(item?.splitTotalQuantity) || Number(item?.quantity) || 0;
        const price = Number(item?.price) || 0;

        if (totalsListMap?.[item?.id]) {
          const { originalTotal } = totalsListMap[item?.id];
          const currentTotal = quantity * price - originalTotal;

          _subtotal += currentTotal;
        } else newTotalsListMap[item?.id] = { originalTotal: quantity * price };
      });

      setTotalsListMap(prev => ({ ...prev, ...newTotalsListMap }));
      setSubtotal(Number(_subtotal?.toFixed(2) || 0));
    }
    setAddDiscount(formatNumber(discount) > 0);
    setAddShipping(formatNumber(shipping) > 0);
    setAddTaxes(formatNumber(taxes) > 0);
    setAddOtherTaxes(formatNumber(otherTaxes) > 0);
  }, [values]);

  const calculateTotal = ({ interest, dueDateDiscount }) =>
    formatNumber(subtotal || 0) +
    formatNumber(shipping || 0) +
    formatNumber(interest || 0) +
    formatNumber(taxes || 0) -
    formatNumber(discount || 0) -
    formatNumber(dueDateDiscount || 0) +
    formatNumber(otherTaxes || 0);

  useEffect(() => {
    if (isMount) return;
    const _total = calculateTotal({ interest: allInterest, dueDateDiscount: allDueDateDiscount });
    const _viewTotal = calculateTotal({ interest: _allInterest, dueDateDiscount: _allDueDateDiscount });

    _total !== total && setField('total')(_total, true);
    setViewTotal(_viewTotal);
  }, [subtotal, discount, shipping, taxes, otherTaxes, total]);

  useEffect(() => {
    if (formatNumber(discount) > 0)
      calcPercentageByValue({ setPercentage: setDiscountPercentage, field: discount, subtotal });
    else if (formatNumber(discountPercentage) > 0)
      calcValuesByPercentage({ property: 'discount', percentageField: discountPercentage, setField, subtotal });

    if (formatNumber(shipping) > 0)
      calcPercentageByValue({ setPercentage: setShippingPercentage, field: shipping, subtotal });
    else if (formatNumber(shippingPercentage) > 0)
      calcValuesByPercentage({ property: 'shipping', percentageField: shippingPercentage, setField, subtotal });

    if (formatNumber(taxes) > 0) calcPercentageByValue({ setPercentage: setTaxesPercentage, field: taxes, subtotal });
    else if (formatNumber(shippingPercentage) > 0)
      calcValuesByPercentage({ property: 'taxes', percentageField: taxesPercentage, setField, subtotal });

    if (formatNumber(otherTaxes) > 0)
      calcPercentageByValue({ setPercentage: setOtherTaxesPercentage, field: otherTaxes, subtotal });
    else if (formatNumber(otherTaxesPercentage) > 0)
      calcValuesByPercentage({ property: 'otherTaxes', percentageField: otherTaxesPercentage, setField, subtotal });
  }, [subtotal, total]);

  useEffect(() => {
    setExtraValueErrors({});

    if (total < 0) {
      setExtraValueErrors(prev => ({ ...prev, total: 'O valor total não pode ser negativo' }));
    }
  }, [total]);

  const renderInput = ({ percentage, setPercentage, setAddState, property, disabled }) => (
    <>
      <RowValue flex="none" gap="none">
        {!disabled && (
          <Button text onClick={() => resetField(property, setPercentage, setAddState)}>
            <TooltipIcon icon={faTrashAlt} text="Excluir" color={colors.red500} />
          </Button>
        )}
        <MaskedInput
          disabled={disabled}
          type="number"
          value={percentage}
          id={`item-${property}-percentage`}
          placeholder="0,00%"
          onChange={e => {
            setPercentage(e?.target?.value);
            calcValuesByPercentage({ property, percentageField: e?.target?.value, setField, subtotal });
          }}
          maskOptions={{
            suffix: '%',
            prefix: '',
            includeThousandsSeparator: false,
            allowDecimal: true
          }}
          style={{ height: spaces.space3, width: spaces.space10, paddingRight: 4, paddingLeft: 4 }}
        />

        <MaskedInput
          disabled={disabled}
          type="currency"
          value={values[property]}
          id={`item-${property}-value`}
          onChange={e => {
            setField(property)(e.target.value);
            calcPercentageByValue({ setPercentage, field: formatNumber(e.target.value), subtotal });
          }}
          defaultValue={formatCurrency(values[property], {
            currencySymbol: 'R$ '
          })}
          maskOptions={{
            prefix: 'R$',
            thousandsSeparatorSymbol: '.',
            allowDecimal: true
          }}
          size="small"
          style={{ flex: 4, marginLeft: 8, height: spaces.space3, paddingRight: 4, paddingLeft: 4, fontSize: '12px' }}
        />
      </RowValue>
      {extraValueErrors?.[property] && <small>{extraValueErrors?.[property]}</small>}
    </>
  );
  const hasCancelledValue = cancelledValue && formatNumber(cancelledValue) > 0;
  return (
    <Container>
      {isView ? (
        <div>
          <RowValue className="subTotal">
            <div>
              <Paragraph type="small" color={colors.neutral600}>
                Subtotal
              </Paragraph>
            </div>
            <div>
              <Paragraph type="small" id="subtotalValuePayment" color={colors.neutral600}>
                {formatCurrency(subtotal, { currencySymbol: 'R$ ' })}
              </Paragraph>
            </div>
          </RowValue>
          {discount && formatNumber(discount) > 0 ? (
            <RowValue>
              <div>
                <Paragraph type="small">Desconto</Paragraph>
              </div>
              <div>
                <Paragraph type="small" id="discountValuePayment">
                  - {formatCurrency(formatNumber(discount), { currencySymbol: 'R$ ' })}
                </Paragraph>
              </div>
            </RowValue>
          ) : null}
          {taxes && formatNumber(taxes) ? (
            <RowValue>
              <div>
                <Paragraph type="small">Impostos</Paragraph>
              </div>
              <div>
                <Paragraph type="small" id="taxesValuePayment">
                  {formatCurrency(formatNumber(taxes), { currencySymbol: 'R$ ' })}
                </Paragraph>
              </div>
            </RowValue>
          ) : null}
          {showOtherTaxes && otherTaxes && formatNumber(otherTaxes) ? (
            <RowValue>
              <div>
                <Paragraph type="small">Outras despesas</Paragraph>
              </div>
              <div>
                <Paragraph type="small" id="otherTaxesValuePayment">
                  {formatCurrency(formatNumber(otherTaxes), { currencySymbol: 'R$ ' })}
                </Paragraph>
              </div>
            </RowValue>
          ) : null}
          {shipping && formatNumber(shipping) ? (
            <RowValue>
              <div>
                <Paragraph type="small">Frete</Paragraph>
              </div>
              <div>
                <Paragraph type="small" id="shippingValuePayment">
                  {formatCurrency(formatNumber(shipping), { currencySymbol: 'R$ ' })}
                </Paragraph>
              </div>
            </RowValue>
          ) : null}
          {allInterest && formatNumber(_allInterest) ? (
            <RowValue>
              <div>
                <Paragraph type="small">Juros e Multas</Paragraph>
              </div>
              <div>
                <Paragraph type="small" id="allInterestValuePayment">
                  {formatCurrency(formatNumber(_allInterest), { currencySymbol: 'R$ ' })}
                </Paragraph>
              </div>
            </RowValue>
          ) : null}
          {allDueDateDiscount && formatNumber(_allDueDateDiscount) ? (
            <RowValue>
              <div>
                <Paragraph type="small">Desconto no pagamento</Paragraph>
              </div>
              <div>
                <Paragraph type="small" id="allDueDateDiscountValuePayment">
                  -{formatCurrency(formatNumber(_allDueDateDiscount), { currencySymbol: 'R$ ' })}
                </Paragraph>
              </div>
            </RowValue>
          ) : null}
          <RowValue className="totalValue">
            <div>
              <Paragraph type="small">Total</Paragraph>
            </div>
            <div>
              <Paragraph type="small" id="totalValuePayment">
                {formatCurrency(formatNumber(total), { currencySymbol: 'R$ ' })}
              </Paragraph>
            </div>
          </RowValue>
          {showTotalDetails && (
            <>
              <RowValue className="paidValue hide-on-print">
                <div>
                  <Paragraph type="small">Total pago</Paragraph>
                </div>
                <div>
                  <Paragraph type="small" className="value">
                    {formatCurrency(formatNumber(paidValue || 0), { currencySymbol: 'R$ ' })}
                  </Paragraph>
                </div>
              </RowValue>
              <RowValue className="restValue hide-on-print" $withPaddingBottom={!hasCancelledValue}>
                <div>
                  <Paragraph type="small">Em aberto</Paragraph>
                </div>
                <div>
                  <Paragraph type="small" className="value">
                    {formatCurrency(formatNumber(openAmount), {
                      currencySymbol: 'R$ '
                    })}
                  </Paragraph>
                </div>
              </RowValue>
            </>
          )}
          {hasCancelledValue ? (
            <RowValue className="cancelledValue">
              <div>
                <Paragraph type="small">Cancelado</Paragraph>
              </div>
              <div>
                <Paragraph type="small" className="value">
                  {formatCurrency(formatNumber(formatNumber(cancelledValue || 0)), {
                    currencySymbol: 'R$ '
                  })}
                </Paragraph>
              </div>
            </RowValue>
          ) : null}
        </div>
      ) : (
        <div>
          <RowValue className="subTotal">
            <div>
              <Paragraph type="small" color={colors.neutral600}>
                Subtotal
              </Paragraph>
            </div>
            <div>
              <Paragraph type="small" color={colors.neutral600}>
                {formatCurrency(subtotal, { currencySymbol: 'R$ ' })}
              </Paragraph>
            </div>
          </RowValue>
          {!discount && creditDisabled ? null : (
            <RowValue flex="none" gap="none">
              <div>
                <Paragraph type="small">Desconto</Paragraph>
              </div>
              {addDiscount || discount ? (
                renderInput({
                  percentage: discountPercentage,
                  setPercentage: setDiscountPercentage,
                  setAddState: setAddDiscount,
                  property: 'discount',
                  disabled: creditDisabled
                })
              ) : (
                <div>
                  {!creditDisabled && (
                    <Button text type="primary" onClick={() => setAddDiscount(true)} id="addDiscountButton">
                      <FontAwesomeIcon icon={faPlus} />
                      Adicionar
                    </Button>
                  )}
                </div>
              )}
            </RowValue>
          )}
          {!taxes && creditDisabled ? null : (
            <RowValue flex="none" gap="none">
              <div>
                <Paragraph type="small">Impostos</Paragraph>
              </div>
              {addTaxes || taxes ? (
                renderInput({
                  percentage: taxesPercentage,
                  setPercentage: setTaxesPercentage,
                  setAddState: setAddTaxes,
                  property: 'taxes',
                  disabled: creditDisabled
                })
              ) : (
                <div>
                  {!creditDisabled && (
                    <Button text type="primary" onClick={() => setAddTaxes(true)} id="addTaxesButton">
                      <FontAwesomeIcon icon={faPlus} />
                      Adicionar
                    </Button>
                  )}
                </div>
              )}
            </RowValue>
          )}
          {showOtherTaxes && otherTaxes && !creditDisabled ? (
            <RowValue flex="none" gap="none">
              <div>
                <Paragraph type="small">Outras despesas</Paragraph>
              </div>
              {addOtherTaxes || otherTaxes ? (
                renderInput({
                  percentage: otherTaxesPercentage,
                  setPercentage: setOtherTaxesPercentage,
                  setAddState: setAddOtherTaxes,
                  property: 'otherTaxes',
                  disabled: creditDisabled
                })
              ) : (
                <div>
                  {!creditDisabled && (
                    <Button text type="primary" onClick={() => setAddOtherTaxes(true)} id="addOtherTaxesButton">
                      <FontAwesomeIcon icon={faPlus} />
                      Adicionar
                    </Button>
                  )}
                </div>
              )}
            </RowValue>
          ) : null}
          {!shipping && creditDisabled ? null : (
            <RowValue flex="none" gap="none">
              <div>
                <Paragraph type="small">Frete</Paragraph>
              </div>
              {addShipping || shipping ? (
                renderInput({
                  percentage: shippingPercentage,
                  setPercentage: setShippingPercentage,
                  setAddState: setAddShipping,
                  property: 'shipping',
                  disabled: creditDisabled
                })
              ) : (
                <div>
                  {!creditDisabled && (
                    <Button text type="primary" onClick={() => setAddShipping(true)} id="addShippingButton">
                      <FontAwesomeIcon icon={faPlus} />
                      Adicionar
                    </Button>
                  )}
                </div>
              )}
            </RowValue>
          )}
          {allInterest && formatNumber(_allInterest) ? (
            <RowValue>
              <div>
                <Paragraph type="small">Juros e Multas</Paragraph>
              </div>
              <div>
                <Paragraph type="small" id="allInterestValuePayment">
                  {formatCurrency(formatNumber(_allInterest), { currencySymbol: 'R$ ' })}
                </Paragraph>
              </div>
            </RowValue>
          ) : null}
          {allDueDateDiscount && formatNumber(_allDueDateDiscount) ? (
            <RowValue>
              <div>
                <Paragraph type="small">Desconto no pagamento</Paragraph>
              </div>
              <div>
                <Paragraph type="small" id="allDueDateDiscountValuePayment">
                  -{formatCurrency(formatNumber(_allDueDateDiscount), { currencySymbol: 'R$ ' })}
                </Paragraph>
              </div>
            </RowValue>
          ) : null}
          <RowValue className="totalValue">
            <div>
              <p>Total</p>
            </div>
            <div>
              <p id="totalValuePayment">{formatCurrency(viewTotal, { currencySymbol: 'R$ ' })}</p>
            </div>
            {extraValueErrors?.total && <small>{extraValueErrors?.total}</small>}
          </RowValue>
        </div>
      )}
    </Container>
  );
};

PaymentExtraValuesForm.propTypes = {
  creditDisabled: PropTypes.bool,
  fixSubTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  childColumnName: PropTypes.string,
  showTotalDetails: PropTypes.bool,
  showOtherTaxes: PropTypes.bool,
  useDynamicListValues: PropTypes.bool
};

export default PaymentExtraValuesForm;
