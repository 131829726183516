import React from 'react';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

import { Divider } from 'antd';
import { text, idReference, simpleName } from '../schema';
import Button from '../../../components/Button/Button';
import { Div, colors, spaces } from '../../../styles/style';
import { store } from '../../config/redux-store';
import { capitalize } from '../../helpers/helper';

const purchaseSchema = ({ hasSupplier }) =>
  Yup.object().shape({
    ...(hasSupplier && { idReference }),
    priority: text,
    name: simpleName
  });

const purchaseMapping = ({ idCompany, canEditRefurbish, hasSupplier, canEditSupplier, isPurchaseSolicitation }) => {
  const { setup } = store.getState();
  const { priority = {} } = setup.enums;

  return {
    ...(isPurchaseSolicitation
      ? {
          idRefurbish: {
            id: 'purchase-request-refurbish',
            name: 'Projeto*',
            type: 'select',
            disabled: !canEditRefurbish,
            model: 'refurbish',
            modelOptions: {
              where: { idStatus: 5 },
              order: [['name', 'asc']]
            },
            offset: true,
            md: hasSupplier ? 5 : 6
          }
        }
      : {}),
    name: {
      id: 'purchase-name',
      name: 'Nome*',
      placeholder: 'Ex: Marcenaria',
      type: 'text',
      md: 6
    },
    ...(hasSupplier
      ? {
          idSupplier: {
            id: 'purchase-order-supplier',
            name: 'Fornecedor*',
            type: 'select',
            disabled: !canEditSupplier,
            model: 'supplier',
            allowCustomOptions: true,
            modelOptions: {
              where: { idCompany, isActive: true },
              onlyMine: true,
              order: [['name'], ['id']]
            },
            offset: true,
            md: 6
          }
        }
      : {}),

    priority: {
      id: 'purchase-request-priority',
      type: 'select',
      name: 'Prioridade',
      allowClear: false,
      options: [
        {
          value: priority?.high?.id,
          label: capitalize(priority?.high?.name)
        },
        {
          value: priority?.average?.id,
          label: capitalize(priority?.average?.name)
        },
        {
          value: priority?.low?.id,
          label: capitalize(priority?.low?.name)
        }
      ],
      md: 6
    },
    idResponsible: {
      id: 'purchase-request-responsible',
      name: 'Responsável',
      type: 'select',
      model: 'user',
      modelOptions: {
        where: { idCompany, isActive: true },
        order: [['name'], ['id']]
      },
      offset: true,
      md: 6
    }
  };
};

const purchaseItemMapping = ({
  idCompany,
  type,
  openCustomCreate,
  setItem,
  setUnits,
  handleConfirm,
  handleCancel,
  paymentView,
  isMobile,
  refurbishItemType
}) => {
  const codeColumn = {
    type: 'text',
    name: 'Código',
    md: paymentView ? 4 : 2,
    flexWidth: isMobile && !paymentView && '50%'
  };

  const idItemColumn = {
    type: 'select',
    name: `Item (${type === refurbishItemType?.product ? 'Produto' : 'Serviço'})`,
    model: 'item',
    id: 'purchase-item-name',
    modelOptions: {
      where: { idCompany, type: type || 1 },
      include: ['units'],
      order: [['name'], ['id']]
    },
    allowCreate: true,
    openCustomCreate,
    saveFullObject: setItem,
    extraPropsOnOptions: ['code', 'idUnit', 'price', 'type', 'units'],
    md: paymentView ? 7 : 6,
    autoFocus: true,
    defaultOpen: true
  };

  const quantityColumn = {
    type: 'number',
    name: isMobile && !paymentView ? 'Quantidade' : 'Qtd.',
    mask: 'number',
    id: 'purchase-quantity',
    defaultValue: '1',
    md: 2
  };

  const unitColumn = {
    name: isMobile && !paymentView ? 'Unidade' : 'Un',
    type: 'select',
    model: 'unit',
    modelOptions: {
      where: { idCompany },
      order: [['name'], ['id']]
    },
    onChange: setUnits,
    sendFullObject: true,
    offset: !isMobile,
    md: 2,
    flexWidth: isMobile && '50%'
  };

  const purchaseDateColumn = {
    name: 'Necessidade',
    type: 'date',
    format: 'DD/MM/YY',
    offset: !isMobile,
    placeholder: isMobile && 'Data',
    md: 3,
    flexWidth: isMobile && !paymentView && '50%'
  };

  const priceColumn = {
    name: isMobile ? 'Valor de compra un.' : 'Custo un.',
    type: 'currency',
    offset: !isMobile,
    id: 'purchase-unit-cost',
    placeholder: 'R$0,00',
    md: 4,
    flexWidth: isMobile && !paymentView && '50%'
  };

  const totalPriceColumn = {
    name: isMobile && !paymentView ? 'Total' : 'Custo total',
    type: 'currency',
    offset: !isMobile,
    disabled: true,
    disabledMobile: isMobile,
    placeholder: 'R$0,00',
    md: 3,
    inline: isMobile && !paymentView && '47%'
  };

  const actionColumn = {
    type: 'custom',
    offset: !isMobile,
    disabled: true,
    md: 2,
    style: { alignSelf: 'end' },
    Component: () => {
      return isMobile ? (
        <>
          <Divider
            style={{
              width: `calc(100% + ${spaces.space4})`,
              margin: `${spaces.space1} -${spaces.space2} 0`,
              backgroundColor: colors.neutral100
            }}
          />
          <Div justify="space-between" padding={`${spaces.space1} 0 ${paymentView ? spaces.space1 : '0'} 0`}>
            <Button text style={{ color: colors.neutral500 }} onClick={handleCancel} id="cancel-purchase-item-button">
              Cancelar
            </Button>
            <Button id="add-purchase-item-button" type="primary" onClick={handleConfirm}>
              {paymentView ? 'Adicionar' : 'Criar item'}
            </Button>
          </Div>
        </>
      ) : (
        <Div $height="100%" margin="20px 0 0 0">
          <Button id="add-purchase-item-button" type="primary" size="2xl" onClick={handleConfirm}>
            <FontAwesomeIcon color={colors.white} icon={faCheck} />
          </Button>
          <Button type="danger" text onClick={handleCancel} id="cancel-purchase-item-button">
            <FontAwesomeIcon color={colors.red500} size="lg" icon={faTrashAlt} />
          </Button>
        </Div>
      );
    }
  };

  if (!paymentView && isMobile) {
    return {
      idItem: idItemColumn,
      code: codeColumn,
      unit: unitColumn,
      quantity: quantityColumn,
      purchaseDate: purchaseDateColumn,
      price: priceColumn,
      totalPrice: totalPriceColumn,
      actions: actionColumn
    };
  }

  return {
    code: codeColumn,
    idItem: idItemColumn,
    quantity: quantityColumn,
    idUnit: unitColumn,
    ...(!paymentView
      ? {
          purchaseDate: purchaseDateColumn
        }
      : []),

    price: priceColumn,
    totalPrice: totalPriceColumn,
    actions: actionColumn
  };
};

export { purchaseSchema, purchaseMapping, purchaseItemMapping };
