import React from 'react';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';

import { Div, spaces, colors } from '../../styles/style';
import { Paragraph } from '../Text/Text';
import { addressConcat } from '../../lib/helpers/helper';
import {
  purchaseOrderAddressMapping,
  purchaseOrderAddressSchema
} from '../../lib/mapping/Form/purchaseOrderAddressSchema';
import Form from './Form';
import Input from '../Input/Input';
import TooltipIcon from '../Tooltip/TooltipIcon';

const DeliveryAddressForm = ({
  tooltipText,
  addressFormValues,
  saveAddressToProject,
  setSaveAddressToProject,
  setAddressFormValues,
  forceAddressFormUpdate,
  referObj,
  refurbish,
  isPrinting,
  readOnly,
  ...props
}) => {
  return (
    <Div align="start" direction="column" padding={`0 ${!isPrinting ? spaces.space2 : 0}`} $avoidPageBreak {...props}>
      <br />
      {isPrinting ? (
        <Div $fullWidth>
          <Paragraph type="small">{addressConcat(refurbish || referObj, true)}</Paragraph>
        </Div>
      ) : (
        <Div direction="column" align="flex-start" gap={spaces.space1} $fullWidth>
          <Form
            style={{ width: '100%' }}
            schema={purchaseOrderAddressSchema}
            mapping={() => purchaseOrderAddressMapping(readOnly)}
            data={addressFormValues}
            onFormChange={setAddressFormValues}
            displayButtons={false}
            forceUpdate={forceAddressFormUpdate}
          />
          {!readOnly && (
            <Div gap={spaces.space1}>
              <Input
                style={{ width: 'unset' }}
                type="checkbox"
                checked={saveAddressToProject}
                onChange={e => setSaveAddressToProject(e.target.checked)}
              />
              <Paragraph>Atualizar endereço do projeto</Paragraph>
              <TooltipIcon
                style={{ color: colors.primary600 }}
                icon={faInfoCircle}
                color={colors.primary600}
                text={tooltipText}
              />
            </Div>
          )}
        </Div>
      )}
    </Div>
  );
};

DeliveryAddressForm.propTypes = {
  tooltipText: PropTypes.string,
  addressFormValues: PropTypes.instanceOf(Object),
  saveAddressToProject: PropTypes.bool,
  setSaveAddressToProject: PropTypes.func,
  setAddressFormValues: PropTypes.func,
  forceAddressFormUpdate: PropTypes.bool,
  referObj: PropTypes.instanceOf(Object),
  refurbish: PropTypes.instanceOf(Object),
  isPrinting: PropTypes.bool,
  readOnly: PropTypes.bool
};

export default DeliveryAddressForm;
