import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faUser, faLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { Description, Paragraph, Subtitle } from '../Text/Text';
import { fonts, spaces, Div, colors } from '../../styles/style';
import { addressConcat, formatDocument, formatPhoneNumber } from '../../lib/helpers/helper';

const ViewEntityInfo = ({ data, isPrinting }) => {
  const infoListMap = info => [
    {
      key: 'phone',
      label: formatPhoneNumber(info?.phone?.replace(/\D/g, '')),
      condition: info?.phone,
      icon: faPhone
    },
    {
      key: 'email',
      label: info?.email,
      condition: info?.email,
      icon: faEnvelope
    },
    {
      key: 'cnpj',
      label: `CPF/CNPJ: ${formatDocument(info?.cnpj || info?.document || info?.doc)}`,
      condition: info?.cnpj || info?.document || info?.doc,
      icon: faUser
    },
    {
      key: 'address',
      label: info?.address || addressConcat(info),
      condition: info?.address || addressConcat(info),
      icon: faLocationDot
    }
  ];

  return (
    <Div
      gap={spaces.space0}
      direction="column"
      align="flex-start"
      justify="flex-start"
      $fullHeight
      $fullWidth
      $lineWeight={fonts.sizeSm}
    >
      <Subtitle>{data?.name}</Subtitle>
      {infoListMap(data)?.map(
        ({ key, label, condition, icon }) =>
          !!condition && (
            <Div key={key} gap={spaces.space1}>
              <Div>
                <FontAwesomeIcon icon={icon} color={colors.neutral600} size={isPrinting ? '2xs' : 'sm'} />
              </Div>
              {isPrinting ? <Description>{label}</Description> : <Paragraph type="small">{label}</Paragraph>}
            </Div>
          )
      )}
    </Div>
  );
};

ViewEntityInfo.propTypes = {
  data: PropTypes.instanceOf(Object),
  isPrinting: PropTypes.bool
};

export default ViewEntityInfo;
