const goToNextField = () => {
  const currentElement = document.activeElement;
  const focusableElements = Array.from(document.querySelectorAll('input, textarea, select, button'));
  const currentIndex = focusableElements.indexOf(currentElement);
  if (currentIndex >= 0 && currentIndex < focusableElements.length - 1) {
    const nextElement = focusableElements[currentIndex + 1];
    nextElement.focus();
  }
};

export { goToNextField };
