import React from 'react';
import dayjs from 'dayjs';
import { v4 as uuidV4 } from 'uuid';
import { capitalize as capitalizeHelper, sumByField } from './helper';
import { colors } from '../../styles/style';
import { Paragraph } from '../../components/Text/Text';
import formatCurrency from './formatCurrency';
import formatNumber from './formatNumber';
import { store } from '../config/redux-store';

export const getPaymentLabel = ({ billType, isCharge, billTypeEnum, capitalize }) => {
  const result = isCharge ? 'pagamento' : billTypeEnum[billType];

  return capitalize ? capitalizeHelper(result) : result;
};

export const extractInitialOrderData = (orderData, isOrder) => {
  const { orderDate, id, discount, shipping, taxes, idSupplier, paymentTypes = [] } = orderData || {};
  return {
    orderDate,
    idOrder: id,
    idSupplier,
    ...(isOrder && { shipping, taxes, discount, paymentTypes })
  };
};

export const getViewPaymentUrl = ({ billType, isCharge, id, billTypeEnum, idRefurbish, refurbishType, isCustomer }) => {
  if (isCustomer) return `/cliente/cobrancas/visualizar/${id}`;

  return isCharge
    ? `/profissional/financeiro/cobrancas/visualizar/${id}?${
        idRefurbish && (refurbishType === 'projeto' || refurbishType === 'oportunidade')
          ? `${refurbishType}=${idRefurbish}`
          : ''
      }`
    : `/profissional/financeiro/${billTypeEnum[billType]}s/editar/${id}${
        idRefurbish && refurbishType === 'projeto' ? `?${refurbishType}=${idRefurbish}` : ''
      }`;
};

export const getValidationErrorMessage = ({
  isView,
  isRevision,
  totalInstallmentIsValid,
  paymentItemsIsValid,
  hasInvoiceInvalid
}) => {
  if (!paymentItemsIsValid) return 'É obrigatório adicionar no mínimo 1 item.';
  if (!totalInstallmentIsValid) return 'A soma das parcelas é diferente do total do pagamento!';
  if (hasInvoiceInvalid) return 'É obrigatório informar "Série/Número" da nota fiscal';
  return !isView && !isRevision
    ? 'Por favor verifique os erros de formulário antes da revisão!'
    : 'Por favor verifique os erros de formulário!';
};

export const calculateInstallments = ({ totalValue, maxLimitPayment = {} }) => {
  const minimumInstallmentValue = maxLimitPayment.minimumInstallmentValue.value;
  const maximumInstallments = maxLimitPayment.maximumInstallments.value;

  let installmentsCount = Math.floor(totalValue / minimumInstallmentValue);

  if (installmentsCount > maximumInstallments) {
    installmentsCount = maximumInstallments;
  }

  const installments = [];

  for (let i = 1; i <= installmentsCount; i++) {
    const installmentValue = Math.round((totalValue / i) * 100) / 100;
    const installmentText = `Em até ${i}x de R$${installmentValue.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`;

    if (installmentValue >= minimumInstallmentValue) {
      installments.push({ value: i, price: installmentValue, label: installmentText });
    }
  }

  return installments;
};

export const paymentTypeWhereClause = ({ idPaymentType, installmentStatuses, billType, isExport }) =>
  idPaymentType && {
    idPaymentType: idPaymentType?.includes(0) && !isExport ? { or: { in: idPaymentType, eq: 'null' } } : idPaymentType,
    idInstallmentStatus: installmentStatuses.allPaid,
    ...(billType && { billType })
  };

export const mountInstallmentWhere = ({
  isExport,
  mountFilter,
  isCurrentBalance,
  paymentTypeValues,
  installmentStatuses
} = {}) => {
  const {
    description,
    idPaymentBankAccount,
    initialDate,
    endDate,
    idFinancialCategory,
    idPaymentCostCenter,
    idCompanyCustomer,
    idSupplier,
    idRefurbish,
    rangeField,
    billType: _billType,
    idPaymentType,
    idReconciliation
  } = mountFilter || {};

  let rangeDate;

  if (isExport) {
    rangeDate = {
      initialDate,
      endDate,
      rangeField,
      paidDate: { ne: null }
    };
  } else if (rangeField === 'asaasEstimatedCreditDate') {
    rangeDate = {
      or: [
        { asaasEstimatedCreditDate: { gte: initialDate, lte: endDate } },
        {
          paidDate: { gte: initialDate, lte: endDate },
          asaasEstimatedCreditDate: null
        }
      ]
    };
  } else if (initialDate || endDate) {
    rangeDate = {
      [rangeField]: { gte: initialDate, lte: endDate }
    };
  }

  if (isCurrentBalance) {
    return {
      ...(idPaymentBankAccount && {
        idPaymentBankAccount: idPaymentBankAccount.includes(-1) ? 'null' : idPaymentBankAccount
      }),
      billType: [paymentTypeValues.expense.value, paymentTypeValues.income.value, paymentTypeValues.transfer.value]
    };
  }

  return {
    ...(description && { description: { like: `%${description}%` } }),
    ...(idPaymentBankAccount && {
      idPaymentBankAccount: idPaymentBankAccount === '-1' ? { eq: 'null' } : idPaymentBankAccount
    }),
    ...rangeDate,
    ...(idFinancialCategory && { idFinancialCategory }),
    ...(idPaymentCostCenter && { idPaymentCostCenter }),
    ...(idCompanyCustomer && { idCompanyCustomer }),
    ...(idSupplier && { idSupplier }),
    billType: _billType,
    ...(idRefurbish && { idRefurbish }),
    ...paymentTypeWhereClause({
      idPaymentType,
      installmentStatuses,
      billType: [paymentTypeValues.expense.value, paymentTypeValues.income.value],
      isExport
    }),
    ownBusiness: true,
    ...(idReconciliation && { idReconciliation })
  };
};

export const serializedPaymentItems = orderItemsList => {
  return orderItemsList?.map(item => ({
    ...item,
    paymentItemLevels: item?.orderItemLevels?.length ? item?.orderItemLevels : null,
    orderItemLevels: undefined
  }));
};

export const mountOrderItems = ({ list, idOrder, feePercentage }) => {
  const idOrderIsArray = Array.isArray(idOrder);
  return idOrderIsArray
    ? list?.map(order => {
        return {
          name: `${feePercentage ? 'Taxa de administração' : 'Reembolso'} ${order?.code}`,
          quantity: 1,
          price: feePercentage ? order?.performedCost * feePercentage : order?.performedCost,
          idOrder: order.id
        };
      })
    : serializedPaymentItems(list?.[0]?.orderItems);
};

export const tooltipFineInterestDiscount = ({ row, val }) => {
  const reduxState = store.getState();
  const { installmentStatuses, paymentStatuses } = reduxState.setup.enums;
  const {
    paymentStatus,
    idInstallmentStatus,
    fine,
    interest,
    discount,
    originalValue,
    installments,
    splitInstallmentId,
    totalSplitInterest,
    totalSplitFine,
    totalSplitDiscount,
    totalSplitOriginalValue
  } = row;

  let _discount = discount;
  let _fine = fine;
  let _interest = interest;
  let _originalValue = originalValue;

  if (paymentStatus) {
    _discount = formatNumber(sumByField(installments, 'discount'));
    _fine = formatNumber(sumByField(installments, 'fine'));
    _interest = formatNumber(sumByField(installments, 'interest'));
    _originalValue = formatNumber(sumByField(installments, 'originalValue'));
  } else if (splitInstallmentId) {
    _discount = formatNumber(totalSplitDiscount);
    _fine = formatNumber(totalSplitFine);
    _interest = formatNumber(totalSplitInterest);
    _originalValue = formatNumber(totalSplitOriginalValue);
  }

  const isPaid =
    installmentStatuses?.paidVobiPay.includes(idInstallmentStatus) || paymentStatus?.id === paymentStatuses?.paid;
  return isPaid && (_fine || _interest || _discount) ? (
    <ul style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
      <li>
        <Paragraph type="small" color={colors.white}>
          Valor original:{' '}
          {formatCurrency(_originalValue, {
            currencySymbol: 'R$ '
          })}
        </Paragraph>
      </li>
      {_discount ? (
        <li>
          <Paragraph type="small" color={colors.white}>
            Desconto:{' '}
            {formatCurrency(_discount, {
              currencySymbol: 'R$ '
            })}
          </Paragraph>
        </li>
      ) : null}
      {_fine || _interest ? (
        <li>
          <Paragraph type="small" color={colors.white}>
            Juros e multa:{' '}
            {formatCurrency(Number(_fine) + Number(_interest), {
              currencySymbol: 'R$ '
            })}
          </Paragraph>
        </li>
      ) : null}
      <li>
        <Paragraph type="small" color={colors.white}>
          Valor pago:{' '}
          {formatCurrency(val, {
            currencySymbol: 'R$ '
          })}
        </Paragraph>
      </li>
    </ul>
  ) : (
    ''
  );
};

export const mountPaymentItemLevel = (suggestedItem, item) => {
  const reduxState = store.getState();
  const { refurbishItemType } = reduxState.setup.enums;
  const { parentList, parentLevel } = suggestedItem || {};
  if (!parentList && !parentLevel) return null;
  const list = parentList?.length ? parentList : [parentLevel];
  return list.map(pl => ({
    id: uuidV4(),
    idParent: item?.id,
    idRefurbishItem: pl?.id,
    type: refurbishItemType.parent,
    refurbishItem: { name: pl?.name, id: pl?.id },
    quantity: item?.quantity,
    percentage: 100
  }));
};

export const conciliateProducts = ({
  paymentToCreate,
  setLoading,
  refurbishItemType,
  handleBulkCreateItems,
  setField,
  setShowProductsConciliation
}) => {
  const { products, billType, ownBusiness } = paymentToCreate;
  const itemsToCreate = [];
  setLoading(true);
  products.forEach(item => {
    if (!item.suggestion && !item.item)
      itemsToCreate.push({
        name: item.name,
        type: refurbishItemType.product,
        price: item.price,
        code: item.code,
        units: item.unit,
        idUnit: item.unit?.id,
        quantity: item.quantity
      });
  });
  const _handleBulkCreateItems =
    itemsToCreate.length > 0
      ? handleBulkCreateItems({ values: { items: itemsToCreate }, refresh: false })
      : Promise.resolve([]);

  return _handleBulkCreateItems.then(resp => {
    setLoading(false);
    if (resp?.error) {
      return;
    }
    const itemsCreated = resp.reduce((acc, curr) => ({ ...acc, [curr.name]: curr }), {});
    const paymentItems = [];

    products.forEach(item => {
      const itemSuggested = item.suggestion || item.item;
      const canLevel =
        (billType === 'expense' || !ownBusiness) &&
        (itemSuggested?.parentLevel || itemSuggested?.parentList?.length > 0);
      if (itemSuggested)
        paymentItems.push({
          id: item.id,
          name: itemSuggested.name,
          type: refurbishItemType.product,
          price: item.price,
          code: itemSuggested.code,
          units: itemSuggested.units,
          idUnit: itemSuggested.units?.id,
          quantity: item.quantity,
          totalPrice: item.totalPrice,
          ...(itemSuggested.model === 'item'
            ? { idItem: itemSuggested?.id }
            : {
                idRefurbishItem: itemSuggested?.id,
                ...(canLevel && {
                  paymentItemLevels: mountPaymentItemLevel(itemSuggested, item)
                })
              })
        });
    });
    itemsToCreate.forEach(item => {
      paymentItems.push({
        id: uuidV4(),
        name: item.name,
        type: refurbishItemType.product,
        price: item.price,
        code: itemsCreated[item.name].code,
        units: itemsCreated[item.name].units,
        idUnit: itemsCreated[item.name].idUnit,
        quantity: item.quantity,
        totalPrice: item.totalPrice,
        idItem: itemsCreated[item.name].id
      });
    });
    setField('name')(paymentToCreate.name);
    setField('billType')(paymentToCreate.billType);
    setField('billingDate')(paymentToCreate.billingDate);
    setField('dueDate')(paymentToCreate.dueDate);
    setField('installments')(paymentToCreate.installments);
    setField('isVobiPay')(paymentToCreate.isVobiPay);
    setField('discount')(paymentToCreate.discount);
    setField('taxes')(paymentToCreate.taxes);
    setField('shipping')(paymentToCreate.shipping);
    setField('otherTaxes')(paymentToCreate.otherTaxes);
    setField('isCharge')(paymentToCreate.isCharge);
    setField('ownBusiness')(paymentToCreate.ownBusiness);
    setField('total')(paymentToCreate.total);
    setField('idFinancialCategory')(paymentToCreate.idFinancialCategory);
    setField('idSupplier')(paymentToCreate.idSupplier);
    setField('newSupplier')(paymentToCreate.newSupplier);
    setField('paymentItems')(paymentItems);
    setField('idRefurbish')(paymentToCreate.idRefurbish);
    setField('importedFromXML')(true);
    setField('openAmount')(paymentToCreate.openAmount);
    setField('invoices')(paymentToCreate.invoices);
    setField('paymentComplete')(paymentToCreate.paymentComplete);

    setShowProductsConciliation(false);
  });
};

export const getBulkDisableType = (isPayment, isTransfer, allInstallments) => {
  const reduxState = store.getState();
  const { installmentStatuses } = reduxState.setup.enums;
  if (isTransfer) {
    return allInstallments?.some(t => t?.idReconciliationIn || t?.idReconciliationOut) && 'reconciliation';
  }
  const hasDisabledVobiPay = installments =>
    installments.some(i => i?.idPaymentAsaas && installmentStatuses.paidVobiPay.includes(i?.idInstallmentStatus));
  const hasDisabledReconciliation = installments => installments.some(i => i?.idReconciliation);
  const vobiPayDisabled = hasDisabledVobiPay(allInstallments) ? 'vobiPay' : null;
  const reconciliationDisabled = hasDisabledReconciliation(allInstallments) ? 'reconciliation' : null;
  if (isPayment) {
    return vobiPayDisabled || reconciliationDisabled;
  }
  const disabledPaid =
    allInstallments.some(i => installmentStatuses.allPaid.includes(i?.idInstallmentStatus)) && 'disabledPaid';
  const _disabledOpen =
    allInstallments.some(i => installmentStatuses.pendingPayment === i?.idInstallmentStatus) && 'disableOpen';

  return { disabledPaid, disabledOpen: _disabledOpen || vobiPayDisabled || reconciliationDisabled };
};

export const prepareDataToDuplicate = data => {
  const reduxState = store.getState();
  const { installmentStatuses, paymentStatuses } = reduxState.setup.enums;
  const cloneData = { ...data };
  const {
    id,
    recurrenceId,
    dueDateDiscount,
    dueDateDiscountType,
    dueDateLimitDays,
    fine,
    fineType,
    interest,
    lastModifiedBy,
    lastRecurrenceDate,
    viewedBy,
    idInstallmentAsaas,
    paymentStatus,
    countFile,
    createdAt,
    updatedAt,
    ..._data
  } = cloneData;

  const installments = !_data.isVobiPay
    ? _data.installments.map(item => ({
        price: item.originalValue || item.price,
        percentage: item.percentage,
        idInstallmentStatus: installmentStatuses.pendingPayment,
        dueDate: item.dueDate,
        description: item.description,
        installmentStatus: {
          id: installmentStatuses.pendingPayment,
          name: 'Aguardando'
        },
        number: item.number,
        idCompany: item.idCompany
      }))
    : [];

  const newProperties = {
    invoices: [],
    tags: [],
    dueDate: dayjs(new Date()).format('YYYY-MM-DD'),
    paymentItems: _data.paymentItems.map(item => {
      const _id = uuidV4();
      const paymentItemLevels = item.paymentItemLevels?.map(itemLevel => ({
        ...itemLevel,
        idParent: _id,
        id: uuidV4()
      }));
      return { ...item, idPayment: null, id: _id, idOrder: null, paymentItemLevels };
    }),
    installmentCount: _data.installments?.length,
    files: [],
    idPaymentStatus: paymentStatuses.open,
    paymentTypes: !_data.isVobiPay ? _data.paymentTypes : _data.paymentTypes.map(item => item.id),
    installments,
    recurrence: {
      frequency: 'month',
      recurrenceCount: 1,
      interval: 1
    }
  };

  return { ..._data, ...newProperties };
};

export const calculateTotalPercentageSplit = _totals => {
  const totalsByPercentage = {};
  let totalSum = 0;

  Object.keys(_totals).forEach(key => {
    const total = _totals[key]?.reduce?.((sum, cur) => sum + Number(cur?.price || 0) * Number(cur?.quantity || 0), 0);
    totalSum += total;
    totalsByPercentage[key] = { value: total };
  });

  Object.keys(totalsByPercentage).forEach(key => {
    totalsByPercentage[key].percentageSplitValue = ((totalsByPercentage[key].value / totalSum) * 100).toFixed(8);
  });

  return [totalsByPercentage, totalSum];
};

export const distributeInstallmentsByPercentage = (totals, installments, assembleInstallments) => {
  const reduxState = store.getState();
  const { installmentStatuses } = reduxState.setup.enums;
  const totalInstallments = installments.reduce((acc, installment) => acc + Number(installment.price), 0);

  return Object.entries(totals).reduce((acc, [key, { percentageSplitValue, id }]) => {
    const targetAmount = (percentageSplitValue / 100) * totalInstallments;
    let remainingAmount = targetAmount;

    acc[key] = installments?.map((installment, index, arr) => {
      const isLast = index === arr.length - 1;
      const assembleInstallment = assembleInstallments?.[key]?.find(i => i?.number === installment?.number);

      const _installment = installmentStatuses?.allPaid.includes(installment.idInstallmentStatus)
        ? {
            ...installment,
            ...assembleInstallment
          }
        : {
            ...installment,
            price: targetAmount * (Number(installment.price) / totalInstallments)
          };

      const proportionalPrice = isLast ? Number(remainingAmount) : Number(_installment.price);
      const _id = assembleInstallment?.id;
      if (!isLast) remainingAmount -= proportionalPrice;
      return {
        ..._installment,
        id: _id,
        idPayment: id,
        price: proportionalPrice.toFixed(2)
      };
    });
    return acc;
  }, {});
};

export const distributeExtraValuesByPercentage = (totals, extraValues) => {
  const { discount = 0, taxes = 0, shipping = 0 } = extraValues || {};
  const result = {};

  Object.keys(totals).forEach(key => {
    const percentage = parseFloat(totals[key].percentageSplitValue) / 100;
    result[key] = {
      discount: discount ? discount * percentage : 0,
      shipping: shipping ? shipping * percentage : 0,
      taxes: taxes ? taxes * percentage : 0
    };
  });

  return result;
};

export const mountPaymentToCreate = (data, otherValues) => {
  const {
    apportionmentList,
    isApportionment,
    idRefurbish,
    completeApportionmentList,
    completeApportionmentTotals,
    installments,
    extraValues,
    splitId,
    assembleInstallments,
    hashSplitId,
    ...payment
  } = data || {};
  if (!isApportionment && !completeApportionmentList)
    return {
      ...data,
      ...otherValues,
      totalSplitValue: null,
      percentageSplitValue: null,
      splitId: null,
      isOldSplit: !!splitId
    };
  const removeOriginal = payment?.id && !splitId ? payment?.id : null;

  if (!completeApportionmentList && (isApportionment || splitId)) {
    const newInstallments = distributeInstallmentsByPercentage(apportionmentList, installments, assembleInstallments);
    let error = false;
    const ids = [];
    const paymentList = Object.entries(apportionmentList).map(([key, value]) => {
      if (ids.includes(key) || error) {
        error = true;
        return null;
      }
      ids.push(key);
      return {
        ...payment,
        id: value?.id,
        splitId,
        value: value?.value,
        idRefurbish: key,
        idRefurbishItemLevel: value?.refurbish?.idRefurbishItemLevel,
        percentageSplitValue: value?.percentageSplitValue,
        totalSplitValue: payment?.value,
        installments: newInstallments[key]
      };
    });
    return { payments: paymentList, hasError: error, removeOriginal };
  }

  const [totalsByPercentage, totalSplitValue] = completeApportionmentTotals;
  const newInstallments = distributeInstallmentsByPercentage(totalsByPercentage, installments, assembleInstallments);
  const extraValuesSplit = distributeExtraValuesByPercentage(totalsByPercentage, otherValues);
  const paymentList = Object.entries(completeApportionmentList).map(([key, value]) => {
    return {
      ...payment,
      idOrder: hashSplitId?.[key],
      installments: newInstallments[key],
      ...(removeOriginal ? { id: undefined } : { id: value[0]?.idPayment }),
      splitId,
      paymentItems: value,
      idRefurbish: key,
      totalSplitValue:
        formatNumber(totalSplitValue) -
        formatNumber(otherValues?.discount) +
        formatNumber(otherValues?.shipping) +
        formatNumber(otherValues?.taxes),
      value: totalsByPercentage[key]?.totalSplitValue,
      ...extraValuesSplit[key],
      ...(completeApportionmentList
        ? {
            createdItems: otherValues?.createdItems?.[key] || {},
            createdLevels: otherValues?.createdLevels?.[key] || {},
            deletedItems: otherValues?.deletedItems?.[key] || {},
            deletedLevels: otherValues?.deletedLevels?.[key] || {},
            updatedItems: otherValues?.updatedItems?.[key] || {},
            updatedLevels: otherValues?.updatedLevels?.[key] || {}
          }
        : {})
    };
  });
  return { payments: paymentList, removeOriginal };
};

export const groupParentList = (obj, refurbishesList) => {
  const parentList = {};
  const refurbishNames = Object.fromEntries(refurbishesList.map(refurbish => [refurbish.id, refurbish.name]));

  Object.entries(obj).forEach(([projectId, paymentItems]) => {
    paymentItems.forEach(item => {
      if (!parentList[item.idItem]) parentList[item.idItem] = [];

      parentList[item.idItem].push({
        idRefurbish: projectId,
        refurbishName: refurbishNames[projectId],
        price: item.price,
        quantity: item.quantity,
        totalValue: item.price * item.quantity
      });
    });
  });

  return parentList;
};

export const mergeItems = ({
  items,
  mid,
  refurbishesList,
  isNew,
  idColumnName = 'idPayment',
  levelsColumnName = 'paymentItemLevels',
  removeAppropriation = true
}) => {
  const allItems = Object.values(items).flat();
  const seenItems = {};
  const parentList = mid && refurbishesList ? groupParentList(items, refurbishesList) : null;

  allItems.forEach(item => {
    if (!seenItems[item.idItem]) {
      seenItems[item.idItem] = {
        ...item,
        [idColumnName]: isNew ? undefined : item?.[idColumnName],
        ...(removeAppropriation && { [levelsColumnName]: [] }),
        quantity: 0,
        totalPrice: 0,
        parentList: null,
        paymentItemLevels: null,
        count: 0,
        accPrice: 0,
        accQtd: 0
      };
    }

    if (mid) {
      seenItems[item.idItem].accQtd += Number(item.quantity || 0);
      seenItems[item.idItem].accPrice += item.price * Number(item.quantity || 0) || 0;
      seenItems[item.idItem].count += 1;

      seenItems[item.idItem].parentList = parentList ? parentList[item.idItem] : parentList;
    }
  });

  if (!mid) {
    return Object.values(seenItems);
  }

  return Object.values(seenItems).map(item => ({
    ...item,
    [idColumnName]: isNew ? undefined : item?.[idColumnName],
    price: item.accPrice / item?.accQtd,
    quantity: item.accQtd,
    totalPrice: undefined,
    count: undefined,
    accPrice: 0,
    accQtd: 0
  }));
};

export const clearItemToApportionment = (list, originalRefurbish, levelsColumnName = 'paymentItemLevels') => {
  return Object.entries(list).reduce((acc, [key, value]) => {
    if (Number(key) === Number(originalRefurbish)) {
      acc[key] = value;
    } else {
      acc[key] = value.map(item => ({
        ...item,
        [levelsColumnName]: null,
        parentList: undefined,
        id: uuidV4()
      }));
    }

    return acc;
  }, {});
};

export const mountApportionmentItems = ({ refurbishList, itemsList = [], libraryData = [] }) => {
  if (libraryData?.error) return {};
  const hashCreatedItems = libraryData.reduce((acc, curr) => {
    acc[curr?.referenceRefurbishId] = curr?.id;
    return acc;
  }, {});
  const assemblyItems = itemsList?.map(item => ({
    ...item,
    idItem: hashCreatedItems[item?.idRefurbishItem] || item?.idItem
  }));
  return refurbishList.reduce((acc, item) => {
    acc[item?.id || item?.value] = assemblyItems;
    return acc;
  }, {});
};
