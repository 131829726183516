import React from 'react';
import PropTypes from 'prop-types';
import { faBookmark, faBookmarkSlash } from '@fortawesome/pro-regular-svg-icons';
import FlatTag from './FlagTag';

const LinkedItemTag = ({ linkedItem, isLibrary, isEdit, isSinapi }) => {
  if (linkedItem) {
    return <FlatTag icon={faBookmark} className="neutral" value={isSinapi ? 'SINAPI' : 'Biblioteca'} size="medium" />;
  }
  if (!linkedItem && !isLibrary && !!isEdit) {
    return <FlatTag icon={faBookmarkSlash} className="neutral" value="Desvinculado com a biblioteca" size="medium" />;
  }
  return null;
};

LinkedItemTag.propTypes = {
  linkedItem: PropTypes.bool,
  isLibrary: PropTypes.bool,
  isEdit: PropTypes.bool,
  isSinapi: PropTypes.bool
};

export default LinkedItemTag;
