import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import Button from '../../Button/Button';
import CopyLinkButton from '../../Button/CopyLinkButton';
import AnalyticsServiceFactory from '../../../lib/analytics/analytics';
import { Div, spaces } from '../../../styles/style';
import { Paragraph } from '../../Text/Text';

const DrawerDropdownShareMenu = ({
  id,
  analyticsProps,
  customEvent = 'item-shared',
  urlContext = 'o',
  isMobile = false
}) => {
  const analytics = AnalyticsServiceFactory();

  const handleShareWhatsapp = () => {
    analytics.track({
      event: customEvent,
      eventProperties: { ...analyticsProps, type: 'Whatsapp' }
    });
    window.open(
      // eslint-disable-next-line max-len
      `https://wa.me/?text=Olá%20veja%20esse%20item%20do%20seu%20projeto%20na%20plataforma:%20${window.location.origin}/${urlContext}/${id}`,
      '_blank'
    );
  };

  if (isMobile) {
    return (
      <>
        <Menu.Item style={{ padding: `0 ${spaces.space0}` }}>
          <CopyLinkButton
            customEvent={customEvent}
            customEventProperties={{ ...analyticsProps, type: 'Copy' }}
            urlContext={urlContext}
            idData={id}
            id="copy-link"
            text
            style={{ padding: `0 ${spaces.space0}` }}
          >
            <Div width={spaces.space3} height={spaces.space3} lign="center" justify="center">
              <FontAwesomeIcon icon={faLink} />
            </Div>
            <Paragraph type="small">Copiar link</Paragraph>
          </CopyLinkButton>
        </Menu.Item>
        <Menu.Item style={{ padding: `0 ${spaces.space0}` }}>
          <Button padding={`0 ${spaces.space0}`} text onClick={handleShareWhatsapp}>
            <Div width={spaces.space3} height={spaces.space3} lign="center" justify="center">
              <FontAwesomeIcon icon={faWhatsapp} />
            </Div>
            <Paragraph type="small">Compartilhar no Whatsapp</Paragraph>
          </Button>
        </Menu.Item>
      </>
    );
  }

  return (
    <Menu>
      <Menu.Item>
        <Button onClick={handleShareWhatsapp} id={`share-${urlContext}`} text>
          Compartilhar no Whatsapp
        </Button>
      </Menu.Item>
      <Menu.Item>
        <CopyLinkButton
          customEvent={customEvent}
          customEventProperties={{ ...analyticsProps, type: 'Copy' }}
          urlContext={urlContext}
          idData={id}
          id="copy-link"
          text
        >
          Copiar link
        </CopyLinkButton>
      </Menu.Item>
    </Menu>
  );
};

DrawerDropdownShareMenu.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  analyticsProps: PropTypes.instanceOf(Object),
  customEvent: PropTypes.string,
  urlContext: PropTypes.string,
  isMobile: PropTypes.bool
};

export default DrawerDropdownShareMenu;
