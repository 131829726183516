import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Div, spaces } from '../../styles/style';
import useCRUD from '../../_Hooks/useCRUD';
import TableOrListV2 from '../List/TableOrListV2';
import { columns as quoteItemSuppliersColumns } from '../../lib/mapping/TableOrList/quoteItemSuppliersColumns';
import PaymentExtraValuesForm from '../Form/PaymentExtraValuesForm';
import { Context } from '../../contexts/GeneralContext';
import usePagination from '../../_Hooks/usePagination';
import Table from './Table';
import curry from '../../lib/helpers/curry';
import useViewport from '../../_Hooks/useViewport';
import QuoteSupplierItemFooter from '../MobileFooter/QuoteSupplierItemFooter';

const limit = 20;

const QuoteSupplierItemsTable = ({
  idQuote,
  quoteSupplier,
  setField,
  readOnly,
  isPrinting,
  awaitForIsPrinting,
  setIsPrinting,
  setAwaitForIsPrinting,
  splitId,
  isCustomer,
  isViewAsSupplier
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();

  const [list, setList] = useState([]);
  const [listPrint, setListPrint] = useState([]);
  const { extraValues, splitExtraValues, subTotal, splitSubTotal, splitTotal, total } = quoteSupplier || {};
  const [extraValueErrors, setExtraValueErrors] = useState();

  const renderTotal = !isViewAsSupplier ? total : undefined;

  const [delayLoad, setDelayLoad] = useState(false);

  const { handleGet: getQuoteItems, totalItems, loading } = useCRUD({
    model: 'quoteItems',
    immediatelyLoadData: false
  });

  const setExtraValues = (key, value) => {
    if (splitId && !isCustomer) {
      setField('splitExtraValues')({
        ...quoteSupplier?.splitExtraValues,
        [key]: value
      });
      return;
    }
    setField('extraValues')({ ...quoteSupplier?.extraValues, [key]: value });
  };

  const handleLoad = ({ delay = true, offset }) => {
    if (delay) setDelayLoad(true);
    if (!idQuote || !quoteSupplier?.id) return;

    getQuoteItems({
      refetchOptions: {
        where: {
          idQuote: isCustomer ? idQuote : splitId || idQuote,
          ...(!!splitId && !isCustomer && { isSplit: true, idQuoteSuppliers: quoteSupplier?.id })
        },
        attributes: ['id', 'type', 'code', 'name', 'price', 'quantity'],
        include: [
          { model: 'item', attributes: ['id', 'type', 'name', 'code'] },
          { model: 'unit', attributes: ['id', 'name'], as: 'units' },
          {
            model: 'quoteItemSuppliers',
            where: { idQuoteSuppliers: quoteSupplier?.id },
            required: false,
            as: 'quoteItemSuppliers'
          }
        ],
        ...(awaitForIsPrinting ? {} : { limit, offset: offset || 1 })
      },
      generateLoading: offset <= 1
    }).then(response => {
      if (response?.error) return;

      if (awaitForIsPrinting) {
        setListPrint(response);
        setIsPrinting(true);
        setAwaitForIsPrinting(false);
      } else setList(prev => (offset > 1 ? [...prev, ...response] : response));

      if (delay) setDelayLoad(false);
    });
  };

  const { handleScroll, setTotalItems } = usePagination.useScrollPagination({
    totalItems,
    handleLoad,
    listLength: list.length,
    initialOffset: 1
  });

  useEffect(() => {
    setField('quoteItems')(list);
  }, [list]);

  useEffect(() => {
    setTotalItems(totalItems);
  }, [totalItems]);

  useEffect(() => {
    if (!awaitForIsPrinting) return;
    handleLoad({});
  }, [awaitForIsPrinting]);

  const setUnavailable = ({ id, isUnavailable }) => {
    setList(prev => {
      return prev.map(item => {
        if (item.id !== id) return item;
        const _item = { ...item };
        if (_item?.quoteItemSuppliers?.[0]) {
          _item.quoteItemSuppliers[0].isUnavailable = isUnavailable;
          _item.quoteItemSuppliers[0].quantity = isUnavailable ? 0 : item.quantity;
        } else {
          _item.quoteItemSuppliers = [
            {
              isUnavailable,
              quantity: isUnavailable ? 0 : item.quantity,
              idQuoteSuppliers: quoteSupplier?.id,
              idQuoteItem: id
            }
          ];
        }

        return _item;
      });
    });
  };

  const editRow = ({ id, value, key }) => {
    const otherKey = key === 'price' ? 'quantity' : 'price';

    setList(prev => {
      return prev.map(item => {
        if (item.id !== id) return item;
        const _item = { ...item };
        if (_item?.quoteItemSuppliers?.[0]) {
          _item.quoteItemSuppliers[0][key] = value;
        } else {
          _item.quoteItemSuppliers = [
            { [key]: value, [otherKey]: 0, idQuoteSuppliers: quoteSupplier?.id, idQuoteItem: id }
          ];
        }

        return _item;
      });
    });
  };

  return (
    <Div direction="column" align="flex-start" gap={spaces.space2} width="100%">
      {!loading && (
        <>
          {awaitForIsPrinting || isPrinting ? (
            <Table
              list={listPrint}
              columns={quoteItemSuppliersColumns({ isPrint: true, readOnly: true, isCustomer })}
              isExportToPdf
              fullWidth
            />
          ) : (
            <TableOrListV2
              id="quote-item-suppliers-list"
              columns={quoteItemSuppliersColumns({
                editRow,
                setUnavailable,
                readOnly,
                isMobile: _isMobile,
                isCustomer
              })}
              list={list}
              rowSelection={false}
              scroll={{ y: '300px', x: '100%' }}
              withCommonRow
              delayLoad={delayLoad}
              refreshColumns
              onReachEnd={handleScroll}
              handleChange={setUnavailable}
              readOnlyMobile={readOnly}
              mobileItemFooter={QuoteSupplierItemFooter}
            />
          )}
        </>
      )}

      <Context
        data={{
          values: {
            ...extraValues,
            ...(!isCustomer && splitExtraValues),
            total: isCustomer ? total : splitTotal || renderTotal,
            list
          },
          setField: curry(setExtraValues),
          getCustomValues: item => ({
            id: item?.id,
            price: item?.quoteItemSuppliers?.[0]?.price || 0,
            quantity: item?.quoteItemSuppliers?.[0]?.quantity || 0,
            ...(!isCustomer && { splitTotalQuantity: item?.quoteItemSuppliers?.[0]?.splitTotalQuantity || 0 })
          }),
          extraValueErrors,
          setExtraValueErrors,
          isView: readOnly
        }}
      >
        <div style={{ width: _isMobile ? '100%' : 400 }}>
          <PaymentExtraValuesForm
            childColumnName="list"
            showTotalDetails={false}
            fixSubTotal={isCustomer ? subTotal : splitSubTotal || subTotal}
            useDynamicListValues
          />
        </div>
      </Context>
    </Div>
  );
};

QuoteSupplierItemsTable.propTypes = {
  idQuote: PropTypes.string,
  quoteSupplier: PropTypes.instanceOf(Object),
  setField: PropTypes.func,
  readOnly: PropTypes.bool,
  isPrinting: PropTypes.bool,
  awaitForIsPrinting: PropTypes.bool,
  setIsPrinting: PropTypes.func,
  setAwaitForIsPrinting: PropTypes.func,
  splitId: PropTypes.string,
  isCustomer: PropTypes.bool,
  isViewAsSupplier: PropTypes.bool
};

export default QuoteSupplierItemsTable;
