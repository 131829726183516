import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import useCRUD from './useCRUD';
import { getBrazilianDate, getMinutesFromHHMM } from '../lib/helpers/helper';
import {
  handleDateRelatedFieldChange,
  handleLinkAdd,
  handleLinkDelete,
  handleLinkUpdate
} from '../lib/helpers/ganttHelperFunctions';

const useTaskDrawer = ({
  data,
  setIsSubmitting,
  setNewFormValues,
  newFormValues,
  setHasChanges,
  setSelectedItems,
  afterSubmit,
  ganttInstance
}) => {
  const [ganttChanges, setGanttChanges] = useState({
    updatedLinks: {},
    updatedTasks: {},
    deletedLinks: {},
    createdLinks: {},
    deletedTasks: {}
  });

  const [taskIsGoingToBeDeleted, setTaskIsGoingToBeDeleted] = useState(false);

  const { handleUpdate, handleCreate } = useCRUD({
    model: 'task',
    pathOptions: `/bulkUpdateWithPlanningLinks`,
    immediatelyLoadData: false
  });

  const onChangeDateRelatedField = field => val => {
    handleDateRelatedFieldChange(ganttInstance, data, newFormValues, setNewFormValues, field, val);
  };

  useEffect(() => {
    if (!ganttInstance || !data) return;

    const linkUpdateHandler = handleLinkUpdate(ganttInstance, data, setGanttChanges, setSelectedItems, setHasChanges);
    const linkDeleteHandler = handleLinkDelete(ganttInstance, data, setGanttChanges, setHasChanges);
    const linkAddHandler = handleLinkAdd(ganttInstance, data, setGanttChanges, setHasChanges);

    const handleTaskUpdate = (taskId, task) => {
      setGanttChanges(prev => ({
        ...prev,
        updatedTasks: { ...prev.updatedTasks, [taskId]: task }
      }));

      if (taskId === data?.id) {
        const startDate = getBrazilianDate(task?.start_date).format('YYYY-MM-DD');
        const endDate = getBrazilianDate(task?.end_date).format('YYYY-MM-DD');

        setNewFormValues(prev => ({
          ...(prev || {}),
          startDate,
          endDate,
          duration: task?.duration
        }));
      }
      setHasChanges(true);
    };

    const handleTaskDelete = taskId => {
      setGanttChanges(prev => {
        const { updatedTasks, deletedTasks, ...rest } = prev;
        const newUpdatedTasks = { ...prev.updatedTasks };
        if (newUpdatedTasks[taskId]) {
          delete newUpdatedTasks[taskId];
        }
        return {
          ...rest,
          updatedTasks: newUpdatedTasks,
          deletedTasks: { ...prev.deletedTasks, [taskId]: true }
        };
      });
      ganttInstance.autoSchedule();
      setTaskIsGoingToBeDeleted(true);
      setHasChanges(true);
    };

    ganttInstance.attachEvent('onAfterLinkUpdate', linkUpdateHandler);
    ganttInstance.attachEvent('onAfterLinkDelete', linkDeleteHandler);
    ganttInstance.attachEvent('onAfterLinkAdd', linkAddHandler);
    ganttInstance.attachEvent('onAfterTaskUpdate', handleTaskUpdate);
    ganttInstance.attachEvent('onAfterTaskDelete', handleTaskDelete);

    // eslint-disable-next-line consistent-return
    return () => {
      ganttInstance.detachEvent('onAfterLinkUpdate');
      ganttInstance.detachEvent('onAfterLinkDelete');
      ganttInstance.detachEvent('onAfterLinkAdd');
      ganttInstance.detachEvent('onAfterTaskUpdate');
      ganttInstance.detachEvent('onAfterTaskDelete');
    };
  }, [ganttInstance, data]);

  const prepareData = parsedData => {
    const {
      responsible,
      refurbishStep,
      refurbish,
      user,
      startHours,
      endHours,
      startDate,
      endDate,
      estimativeDuration,
      ...rest
    } = parsedData;

    let adjustedStartDate = startDate;
    let adjustedEndDate = endDate;
    let estimativeDurationInMinutes = 0;

    if (startDate) {
      const startDateObj = dayjs(startDate);
      if (startHours) {
        const [startHour, startMinute] = startHours.split(':').map(Number);
        adjustedStartDate = startDateObj
          .hour(startHour)
          .minute(startMinute)
          .toISOString();
      } else {
        adjustedStartDate = startDateObj
          .hour(0)
          .minute(0)
          .toISOString();
      }
    }

    if (endDate) {
      const endDateObj = dayjs(endDate);
      if (endHours) {
        const [endHour, endMinute] = endHours.split(':').map(Number);
        adjustedEndDate = endDateObj
          .hour(endHour)
          .minute(endMinute)
          .toISOString();
      } else {
        adjustedEndDate = endDateObj
          .hour(23)
          .minute(59)
          .toISOString();
      }
    }

    if (estimativeDuration && estimativeDuration !== '') {
      estimativeDurationInMinutes = getMinutesFromHHMM(estimativeDuration);
    }

    return {
      ...rest,
      idResponsible: responsible?.id || responsible || null,
      idRefurbishStep: refurbishStep || 1,
      estimativeDuration: estimativeDurationInMinutes,
      startDate: adjustedStartDate,
      endDate: adjustedEndDate
    };
  };

  const addTruthfulHoursToUpdatedTasks = () => {
    const updatedTasksWithHours = {};

    Object.keys(ganttChanges.updatedTasks).forEach(taskId => {
      const task = ganttChanges.updatedTasks[taskId];
      const {
        duration,

        isCurrentTask,
        unscheduled,
        start_date: ganttStartDate,
        end_date: ganttEndDate,
        originalStartTime,
        originalEndTime,
        ...rest
      } = task;

      if (isCurrentTask || unscheduled) {
        updatedTasksWithHours[taskId] = {
          ...task
        };
        return;
      }

      const adjustedStartDate =
        duration === 0 || !ganttStartDate
          ? null
          : dayjs(ganttStartDate)
              .hour(originalStartTime?.hour || 23)
              .minute(originalStartTime?.minute || 59) || undefined;

      const adjustedEndDate =
        duration === 0 || !ganttEndDate
          ? null
          : dayjs(ganttEndDate)
              .hour(originalEndTime?.hour || 23)
              .minute(originalEndTime?.minute || 59) || undefined;

      updatedTasksWithHours[taskId] = {
        ...rest,
        isCurrentTask,
        duration,
        unscheduled,
        startDate: adjustedStartDate,
        endDate: adjustedEndDate
      };
    });

    return updatedTasksWithHours;
  };

  const handleSubmit = (submitData, files) => {
    setIsSubmitting(true);
    const preparedData = prepareData({ ...submitData, isCurrentTask: true });

    const updatedTasksWithHours = addTruthfulHoursToUpdatedTasks();
    const func = data.id === -1 ? handleCreate : handleUpdate;

    const payload = {
      ...ganttChanges,
      updatedTasks: {
        ...(preparedData?.startDate && preparedData?.endDate ? updatedTasksWithHours : []),
        [data?.id]: preparedData
      },
      files
    };
    func({
      values: { ...payload, idRefurbish: data?.idRefurbish || preparedData?.idRefurbish, from: 'Drawer' },
      refresh: false
    })
      .then(resp => {
        afterSubmit(resp, data?.idRefurbish);
        return resp;
      })
      .catch(error => {
        setIsSubmitting(false);
        setHasChanges(false);
        throw error;
      });
  };

  const handleSubmitDelete = () => {
    setIsSubmitting(true);

    const updatedTasksWithHours = addTruthfulHoursToUpdatedTasks();

    const payload = {
      ...ganttChanges,
      updatedTasks: {
        ...updatedTasksWithHours
      }
    };
    handleUpdate({
      values: { ...payload, idRefurbish: data?.idRefurbish, from: 'Drawer' },
      refresh: false
    })
      .then(resp => {
        afterSubmit(resp);
        return resp;
      })
      .catch(error => {
        setIsSubmitting(false);
        setHasChanges(false);
        throw error;
      });
  };

  const updateLink = (link, updates) => {
    setSelectedItems(prev =>
      prev?.map(item => {
        if (item?.id === link?.id) {
          const updatedItem = { ...item, ...updates };

          if (ganttInstance && updatedItem.id) {
            const ganttLink = ganttInstance.getLink(updatedItem.id);
            if (ganttLink) {
              ganttLink.source = updatedItem.source;
              ganttLink.type = updatedItem.type;
              ganttLink.target = data.id;
              ganttLink.lag = updatedItem.lag;
              ganttInstance.updateLink(updatedItem.id);
            }
          }

          return updatedItem;
        }
        return item;
      })
    );
  };

  const removeLink = link => {
    if (ganttInstance && link?.id) {
      ganttInstance.deleteLink(link?.id);
    }
    setSelectedItems(prev => prev.filter(item => item?.id !== link?.id));
  };

  const createLink = selectedItemId => {
    const newLink = {
      source: Number(selectedItemId),
      target: Number(data.id),
      type: ganttInstance.config.links.finish_to_start,
      lag: 0
    };

    if (!ganttInstance.isLinkAllowed(newLink)) {
      toast.error(`O link não pôde ser criado pois resultaria em uma dependência circular.`);
      return;
    }
    const linkId = ganttInstance.addLink(newLink);

    const newItem = {
      source: Number(selectedItemId),
      target: Number(data.id),
      lag: 0,
      type: ganttInstance.config.links.finish_to_start,
      id: linkId
    };
    setSelectedItems(prev => [...prev, newItem]);
  };

  useEffect(() => {
    if (taskIsGoingToBeDeleted) {
      handleSubmitDelete();
      setTaskIsGoingToBeDeleted(false);
    }
  }, [taskIsGoingToBeDeleted]);

  const removeTask = () => {
    if (ganttInstance && data.id) {
      ganttInstance.deleteTask(Number(data.id));
    }
  };

  return {
    createLink,
    removeLink,
    updateLink,
    handleSubmit,
    handleSubmitDelete,
    removeTask,
    onChangeDateRelatedField,
    ganttInstance
  };
};

export default useTaskDrawer;
