import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons';
import { Input } from 'antd';
import { useSelector } from 'react-redux';
import { colors, Div, spaces } from '../../styles/style';
import Select from './Select';
import TooltipIcon from '../Tooltip/TooltipIcon';
import { EditContainer } from '../Input/EditableInput.style';
import Button from '../Button/Button';
import { Line } from '../Line/Line';
import { Paragraph } from '../Text/Text';
import { goToNextField } from '../../lib/helpers/nextField';

const ItemSelect = ({
  value,
  disabled,
  allowPropagation = false,
  onBlur = f => f,
  onChange = f => f,
  onClick = f => f,
  isTemplate,
  initEdit,
  idCompany,
  handleDelete = f => f,
  placeholder = 'Pesquise na biblioteca ou adicione um item',
  isMobile,
  EditModalFactory,
  footerOptions = 'default',
  sendFullObject = false,
  extraPropsOnOptions = [],
  fullWidth = false,
  handleLoad = f => f,
  isComposition = false,
  useEditContainer = true
}) => {
  const [isEdit, setEdit] = useState(initEdit);
  const [_value, setValue] = useState(value?.name);
  const [editItemModalParams, setEditItemModalParams] = useState({});
  const { refurbishItemType } = useSelector(state => state.setup.enums);
  const isClick = useRef();
  const blurRef = useRef();
  const selectRef = useRef(null);
  const showSelect = !value?.id || sendFullObject;

  useEffect(() => {
    setValue(value?.name);
  }, [value?.name]);

  const handleBlur = (newValue, key = 'name', object = null) => {
    if (typeof newValue === 'string' && isComposition && showSelect) {
      setEditItemModalParams({ show: true, values: { ...value, id: undefined, name: newValue } });
    } else if (value[key] !== newValue || value?.isVirtual) {
      onChange(value, key, !value?.type, object)(newValue);
    }

    setEdit(false);
    isClick.current = true;
    onBlur();
    goToNextField();
  };

  const handleKey = (e, searchValue, list = []) => {
    if (e.key === 'Escape') {
      setEdit(false);
      if (value?.isVirtual) handleDelete(value);
    }
    if (e.key === 'Enter' && !list?.length) {
      e.target.blur();
    }
  };

  const handleClick = e => {
    if (!allowPropagation || isClick.current) e.stopPropagation();
    setEdit(!isClick.current);
    isClick.current = false;
  };

  return (
    <Div $fullWidth>
      {isEdit && !disabled ? (
        <>
          {showSelect ? (
            <Select
              ref={selectRef}
              autoFocus
              id={`select${value?.id}`}
              model="item"
              modelOptions={{
                where: {
                  idCompany,
                  type: value?.type,
                  isActive: true,
                  ...(isComposition ? {} : { isSimple: false })
                }
              }}
              onChange={(val, object) => {
                blurRef.current = true;
                handleBlur(val, 'idItem', object);
              }}
              value={value?.id}
              onSelect={() => setEdit(false)}
              onClick={e => e.stopPropagation()}
              onBlur={(e, searchValue) => {
                if (blurRef.current) {
                  blurRef.current = false;
                  return;
                }

                if (isMobile && !e?.relatedTarget?.id) return;
                if (!editItemModalParams?.show && e?.relatedTarget?.id !== 'delete-virtual-row')
                  handleBlur(searchValue);
                setEdit(false);
              }}
              dropdownMatchSelectWidth
              notFoundContent={<div />}
              onKeyDown={handleKey}
              sendFullObject={sendFullObject}
              fullWidth={fullWidth}
              extraPropsOnOptions={extraPropsOnOptions}
              {...(footerOptions === 'default' && {
                footerOptions: searchValue => (
                  <div>
                    <Line style={{ margin: `${spaces.space1} 0` }} />
                    {!isComposition && (
                      <Button
                        text
                        type="primary"
                        onMouseDown={e => {
                          if (isMobile) {
                            handleBlur(searchValue);
                          } else {
                            e.stopPropagation();
                          }
                        }}
                      >
                        + Adicionar no orçamento
                      </Button>
                    )}
                    {(!isTemplate || value?.type === refurbishItemType.composition) && (
                      <Button
                        text
                        type="primary"
                        onMouseDown={e => {
                          e.stopPropagation();
                          setEditItemModalParams({
                            show: true,
                            values: { ...value, id: undefined, name: searchValue }
                          });
                          setEdit(false);
                        }}
                      >
                        + Criar na bilioteca e adicionar ao orçamento
                      </Button>
                    )}
                  </div>
                )
              })}
              {...(footerOptions === 'composition-item' && {
                footerOptions: searchValue => (
                  <div>
                    <Line style={{ margin: `${spaces.space1} 0` }} />
                    <Button
                      text
                      type="primary"
                      onMouseDown={e => {
                        e.stopPropagation();
                        handleBlur(searchValue);
                      }}
                    >
                      + Adicionar na composição
                    </Button>
                  </div>
                )
              })}
              placeholder={placeholder}
              open
            />
          ) : (
            <Input
              id={`select${value?.id}`}
              value={_value}
              onKeyDown={handleKey}
              onChange={e => setValue(e.target?.value)}
              onBlur={e => handleBlur(e.target?.value)}
              onClick={e => e.stopPropagation()}
              disabled={disabled}
              autoFocus
            />
          )}
        </>
      ) : (
        <>
          {useEditContainer ? (
            <EditContainer
              id={`item${value?.id}`}
              style={{ padding: spaces.space0 }}
              role="presentation"
              onMouseDown={e => {
                isClick.current = true;
                e.stopPropagation();
                onClick();
              }}
              tabIndex={-1}
              onFocus={handleClick}
            >
              {value?.name || _value || '-'}
              {!disabled && (
                <TooltipIcon
                  style={{ height: spaces.space2, width: spaces.space2 }}
                  className="renameItem showHover"
                  text="Renomear"
                  icon={faPenToSquare}
                  iconColor={colors.neutral600}
                  onClick={e => {
                    isClick.current = false;
                    handleClick(e);
                  }}
                />
              )}
            </EditContainer>
          ) : (
            <Div
              id={`item${value?.id}`}
              tabIndex={-1}
              onClick={e => {
                isClick.current = false;
                handleClick(e);
              }}
              onFocus={handleClick}
              onMouseDown={e => {
                isClick.current = true;
                e.stopPropagation();
                onClick();
              }}
              style={{
                width: '100%',
                backgroundColor: colors.white,
                borderRadius: spaces.space0,
                border: `1px solid ${colors.neutral100}`,
                padding: spaces.space1,
                height: spaces.space4,
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}
            >
              <Paragraph ellipsis>{value?.name || _value || '-'}</Paragraph>
            </Div>
          )}
        </>
      )}
      {editItemModalParams?.show && EditModalFactory ? (
        <EditModalFactory
          type={value?.type}
          model={isTemplate ? 'template-item' : 'refurbish-items'}
          idReference={isTemplate ? value?.idTemplate : value?.idRefurbish}
          onClose={() => {
            setEditItemModalParams({});
            handleDelete(value);
            handleLoad();
          }}
          groupedReference={editItemModalParams?.values?.groupedReference}
          initialValues={{ ...editItemModalParams?.values }}
          {...editItemModalParams}
          isTemplate={isTemplate}
          createInLibraryAndAddToRefurbish
        />
      ) : null}
    </Div>
  );
};

ItemSelect.propTypes = {
  value: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
  allowPropagation: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  isTemplate: PropTypes.bool,
  initEdit: PropTypes.bool,
  idCompany: PropTypes.number,
  handleDelete: PropTypes.func,
  placeholder: PropTypes.string,
  isMobile: PropTypes.bool,
  handleLoad: PropTypes.func,
  EditModalFactory: PropTypes.func,
  footerOptions: PropTypes.string,
  sendFullObject: PropTypes.bool,
  extraPropsOnOptions: PropTypes.instanceOf(Array),
  fullWidth: PropTypes.bool,
  useEditContainer: PropTypes.bool,
  isComposition: PropTypes.bool
};

export default ItemSelect;
