import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// helper
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import InstallmentConfiguration from '../../../components/Payments/InstallmentConfiguration';

import {
  calculatePercentage,
  calculatePriceByPercentage,
  generateInstallments,
  recalculateInstallments
} from '../../../lib/helpers/installment';
import { columns as orderConfigurationColumns } from '../../../lib/mapping/TableOrList/orderConfigurationColumns';

const OrderInstallmentsComponent = ({ total, installments, setInstallments, disable, orderCode }) => {
  const { user } = useSelector(state => state.authReducer) || {};
  const updateFieldWhenChangingNumberOfInstallments = number => {
    const newInstallments = generateInstallments({
      quantity: number,
      total,
      installments,
      otherData: {
        paymentName: `Pagamento ${orderCode}`,
        billingDate: dayjs().format('YYYY-MM-DD'),
        idCompany: user?.idCompany
      }
    });
    if (!Array.isArray(newInstallments) && newInstallments.error) {
      toast.error(newInstallments.error);
      return;
    }
    setInstallments(newInstallments);
  };

  const row = useRef();
  const handleChange = (index, field, value) => {
    row.current = {
      index,
      installment: installments[index]
    };
    row.current.installment[field] = value;

    if (field === 'percentage') row.current.installment.price = calculatePriceByPercentage(value, total);
    else if (field === 'price') row.current.installment.percentage = calculatePercentage(value, total);
    else {
      const newInstallments = [...installments];
      newInstallments[index] = row.current.installment;
      setInstallments(newInstallments);
      row.current = null;
    }
  };

  const installmentHandleBlur = () => {
    const result = recalculateInstallments({ current: row.current, value: total, installments });
    if (!result) return;
    if (result.error) {
      toast.error(result.error);
      row.current = null;
      return;
    }
    setInstallments(result?.installments || result);
    row.current = null;
  };

  const columns = orderConfigurationColumns({
    numberOfInstalments: installments?.length,
    handleChange,
    isView: disable,
    installmentHandleBlur
  });

  const mobileParams = {
    handleChange,
    installmentHandleBlur
  };

  return (
    <>
      <InstallmentConfiguration
        installments={installments}
        columns={columns}
        typeLabel="Para interagir, o valor deve ser diferente de zero."
        total={total}
        generateInstallments={updateFieldWhenChangingNumberOfInstallments}
        mobileParams={mobileParams}
        showExtraComponents
        disable={disable}
        isOrder
      />
    </>
  );
};

OrderInstallmentsComponent.propTypes = {
  total: PropTypes.number,
  installments: PropTypes.instanceOf(Array),
  setInstallments: PropTypes.func,
  disable: PropTypes.bool,
  orderCode: PropTypes.string
};

export default OrderInstallmentsComponent;
