import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Radio, Space, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import Select from '../../../components/Select/Select';
import { colors } from '../../../styles/style';
import { Paragraph, Subtitle } from '../../../components/Text/Text';
import Card from '../../../components/Card/Card';
import useViewport from '../../../_Hooks/useViewport';
import { formatDocument } from '../../../lib/helpers/helper';

const propsMap = {
  order: {
    title: 'Meu negócio',
    width: '488px',
    height: '152px',
    expense: {
      title: 'Meu negócio',
      text: `Quando seu negócio é responsável pelo pagamento do fornecedor.
    Nesse caso a ordem de compra será criada com os dados de faturamento do seu negócio.`
    },
    payment: {
      title: 'Cliente',
      text: `Quando o seu cliente é responsável pelo pagamento do fornecedor.
    Nesse caso a ordem de compra será criada os dados de faturamento do seu cliente.`
    }
  },
  modal: {
    width: '330px',
    height: '152px',
    expense: {
      title: 'Despesa do negócio',
      text: `Quando seu negócio é responsável pelo pagamento do fornecedor.`
    },
    payment: {
      title: 'Pagamento do cliente',
      text: `Quando o seu cliente é responsável pelo pagamento do fornecedor.`
    }
  }
};

const PaymentOrderComponent = ({
  idCompanyCustomer,
  setIdCompanyCustomer,
  initialChecked,
  setIsValid = f => f,
  propsMapKey = 'order',
  readOnly,
  companyCustomerName,
  setOrderRecipient = f => f,
  blockPayment
}) => {
  const [selectedCompanyCustomer, setSelectedCompanyCustomer] = useState(idCompanyCustomer);
  const { paymentTypeValues } = useSelector(state => state.setup.enums);

  const expenseValue = paymentTypeValues?.expense?.value;
  const paymentValue = paymentTypeValues?.payment?.value;

  const [expenseSelected, setExpenseSelected] = useState(initialChecked === expenseValue);
  const [paymentSelected, setPaymentSelected] = useState(initialChecked === paymentValue);
  const { isMobile } = useViewport(window.innerWidth);

  const isPayment = !!idCompanyCustomer;
  const textOptions = propsMap[propsMapKey][isPayment ? paymentValue : expenseValue];

  const handleSelectCard = type => {
    if (type === expenseValue || blockPayment) {
      setExpenseSelected(true);
      setPaymentSelected(false);
      setIdCompanyCustomer(null);
      setOrderRecipient(type);
    } else {
      setExpenseSelected(false);
      setPaymentSelected(true);
      setIdCompanyCustomer(selectedCompanyCustomer);
      setOrderRecipient(type);
    }
  };

  useEffect(() => {
    if (!readOnly) {
      setIsValid((paymentSelected && selectedCompanyCustomer) || expenseSelected);
    }
  }, [expenseSelected, paymentSelected, selectedCompanyCustomer]);

  useEffect(() => {
    if (idCompanyCustomer) {
      setSelectedCompanyCustomer(idCompanyCustomer);
    }
  }, [idCompanyCustomer]);

  useEffect(() => {
    setExpenseSelected(initialChecked === expenseValue || blockPayment);
    setPaymentSelected(initialChecked === paymentValue);
  }, [initialChecked, blockPayment]);

  return (
    <Space direction={isMobile() ? 'vertical' : 'horizontal'} size={16}>
      {readOnly ? (
        <Card bodyBackgroundColor={colors.primary50} maxWidth={propsMap[propsMapKey].width}>
          <Subtitle>{textOptions.title}</Subtitle>
          <br />
          <Paragraph type="small">{textOptions.text}</Paragraph>
          <br />
          {isPayment && companyCustomerName && <Paragraph>{companyCustomerName}</Paragraph>}
        </Card>
      ) : (
        <>
          <Card
            bodyBackgroundColor={expenseSelected ? colors.primary50 : colors.neutral50}
            maxWidth={propsMap[propsMapKey].width}
            height={propsMap[propsMapKey].height}
            hover
            onClick={() => handleSelectCard(expenseValue)}
            id="expense-option-card"
            bodyJustify="start"
          >
            <Radio checked={expenseSelected} onChange={() => handleSelectCard(expenseValue)}>
              <Subtitle>{propsMap[propsMapKey].expense.title}</Subtitle>
            </Radio>
            <br />
            <Paragraph type="small">{propsMap[propsMapKey].expense.text}</Paragraph>
          </Card>
          <Tooltip
            title={
              blockPayment
                ? `O faturamento para o cliente está desabilitado
                porque existe mais de um projeto nessa ordem de compra.`
                : ''
            }
          >
            <Card
              bodyBackgroundColor={paymentSelected ? colors.primary50 : colors.neutral50}
              maxWidth={propsMap[propsMapKey].width}
              height={propsMap[propsMapKey].height}
              hover
              onClick={() => !blockPayment && handleSelectCard(paymentValue)}
              id="payment-option-card"
            >
              <Radio checked={paymentSelected} onChange={() => handleSelectCard(paymentValue)} disabled={blockPayment}>
                <Subtitle>{propsMap[propsMapKey].payment.title}</Subtitle>
              </Radio>
              <br />
              <Paragraph type="small">{propsMap[propsMapKey].payment.text}</Paragraph>
              <br />
              <Select
                onClick={e => e.stopPropagation()}
                name="paymentCompanyCustomer"
                id="paymentCompanyCustomer"
                model="companyCustomer"
                modelOptions={{ where: { isActive: true }, order: ['name'] }}
                placeholder="Selecione o cliente"
                value={selectedCompanyCustomer}
                onChange={value => {
                  setSelectedCompanyCustomer(value);
                  setIdCompanyCustomer(value);
                }}
                showSearch
                disabled={expenseSelected || blockPayment}
                allowCreate
                allowSearchByExtraPropsOnOption
                extraPropsOnOptions={['doc']}
                customLabel={option => {
                  return option.doc ? `${option.name} - ${formatDocument(option.doc)}` : option.name;
                }}
              />
            </Card>
          </Tooltip>
        </>
      )}
    </Space>
  );
};

PaymentOrderComponent.propTypes = {
  idCompanyCustomer: PropTypes.number,
  initialChecked: PropTypes.string,
  setIdCompanyCustomer: PropTypes.func,
  setIsValid: PropTypes.func,
  propsMapKey: PropTypes.string,
  readOnly: PropTypes.bool,
  companyCustomerName: PropTypes.string,
  setOrderRecipient: PropTypes.func,
  blockPayment: PropTypes.bool
};

export default PaymentOrderComponent;
