import { Col } from 'antd';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { breakpoints, colors, fonts, spaces } from '../../styles/style';

export const TypeCol = styled(Col)`
  display: flex;
  align-items: center;

  .c-attachment--action {
    cursor: pointer;
    align-self: center;
    padding-left: ${spaces.space1};

    svg {
      color: ${colors.red500};
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    margin-top: ${spaces.space1};
  }
`;

export const IconRemove = styled(FontAwesomeIcon)`
  color: ${colors.neutral600};
  font-size: ${fonts.sizeLg};
  max-width: 100px;
  cursor: pointer;
  @media (max-width: ${breakpoints.tablet}) {
    width: auto;
  }
`;

export const FileStatus = styled.span`
  color: ${props => props.color};
`;
