import React from 'react';
import dayjs from 'dayjs';
import { Checkbox, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowRight, faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import {
  faCircleExclamation,
  faCircleDollar,
  faFileInvoiceDollar,
  faCircleXmark
} from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import formatCurrency from '../../helpers/formatCurrency';
import { colors, Div, spaces } from '../../../styles/style';
import { Paragraph } from '../../../components/Text/Text';
import { formatDecimalsDinamically, validDate } from '../../helpers/helper';
import ImageInput from '../../../components/Input/ImageInput';
import { store } from '../../config/redux-store';
import EditableInput from '../../../components/Input/EditableInput';
import formatNumber from '../../helpers/formatNumber';
import DatePicker from '../../../components/Datepicker/Datepicker';
import QuoteSupplierActionDropdown from '../../../components/Dropdown/QuoteSupplierActionDropdown';
import ApproveButton from '../../../components/Button/ApproveButton';
import EditableInputBuilder from '../../../components/Input/EditableInputBuilder';
import Button from '../../../components/Button/Button';

const StyledUnavailableDiv = styled(Div)`
  height: ${spaces.space3};
  .mark-as-available-button {
    display: ${props => (props.$isMobile ? 'flex' : 'none')};
  }
  :hover {
    .mark-as-available-button {
      display: flex;
    }
  }
`;

const columns = ({
  activeQuoteSuppliersColumns = [],
  readOnly,
  editRow,
  onCheck,
  currentSelectedSupplierCheckbox,
  handleLoad,
  handleOpenEditQuoteSupplierDrawer,
  editDetails,
  columnsToShow,
  supplierColumnsWidthMap,
  columnsWidthMap,
  selectedItems,
  totals,
  fixedColumns,
  isMobile,
  editAvailableItem,
  isCustomer
}) => {
  const { setup } = store.getState();
  const { refurbishItemType = {}, quoteSupplierStatus = {} } = setup.enums;
  const { refurbishItemTypes = [] } = setup.systemData;
  const { user } = store.getState().authReducer || {};
  const isPublicRoute = user?.anonymous || !user;

  const statusIdNameMap = {};
  Object.entries(quoteSupplierStatus).forEach(([, val]) => {
    if (val?.id && val?.name) {
      statusIdNameMap[val?.id] = val?.name;
    }
  });

  const { pending, waitingResponse, refused, answered, analyzed, buy, canApprove } = quoteSupplierStatus;

  const quoteSupplierStatusMap = {
    [pending.id]: { ...pending },
    [waitingResponse.id]: { ...waitingResponse },
    [refused.id]: { ...refused },
    [answered.id]: { ...answered },
    [analyzed.id]: { ...analyzed },
    [buy.id]: { ...buy }
  };

  const itemColumn = {
    title: 'Item',
    dataIndex: 'item',
    key: 'item',
    className: 'cell-before-80',
    fixed: fixedColumns ? 'left' : undefined,
    width: columnsWidthMap.item,
    alwaysShow: true,
    render: (val, row, index) => {
      const { id, item } = row || {};
      const type = item?.type || row?.type;
      const name = item?.name || row?.name;
      const typeName = refurbishItemTypes?.find(r => r.value === row.type)?.label;
      const itemImage = refurbishItemType?.product === type ? 'boxOpen' : 'screwdriverWrench';

      return (
        <Div>
          <Div width={spaces.space4} justify="center">
            <Tooltip title={typeName}>
              <ImageInput
                key={`image${id}`}
                id={id}
                value={itemImage || 'boxOpen'}
                disabled
                mobileWidth={spaces.space4}
              />
            </Tooltip>
          </Div>
          <Paragraph type="small" id={`quote-item-supplier-item-${index + 1}`}>
            {name}
          </Paragraph>
        </Div>
      );
    },
    shouldCellUpdate: false
  };

  const codeColumn = {
    title: 'Código',
    dataIndex: 'code',
    key: 'code',
    fixed: fixedColumns ? 'left' : undefined,
    className: 'cell-before-80',
    width: columnsWidthMap.code,
    render: (val, row, index) => {
      return (
        <Paragraph type="small" id={`quote-item-code-${index + 1}`}>
          {val || '-'}
        </Paragraph>
      );
    },
    shouldCellUpdate: false
  };

  const quantityColumn = {
    title: 'Qtd.',
    dataIndex: 'quantity',
    key: 'quantity',
    fixed: fixedColumns ? 'left' : undefined,
    className: 'cell-before-80',
    width: columnsWidthMap.quantity,
    render: (val, row, index) => {
      const { quantity } = row || {};

      return (
        <Paragraph type="small" id={`quote-item-quantity-${index + 1}`}>
          {formatDecimalsDinamically(quantity)}
        </Paragraph>
      );
    },
    shouldCellUpdate: false
  };

  const unitColumn = {
    title: 'Un.',
    dataIndex: 'units',
    key: 'units',
    fixed: fixedColumns ? 'left' : undefined,
    className: 'cell-before-80',
    width: columnsWidthMap.units,
    render: (val, row, index) => {
      const { units } = row;

      return (
        <Paragraph type="small" id={`quote-item-supplier-unit-${index + 1}`}>
          {units?.name || '-'}
        </Paragraph>
      );
    },
    shouldCellUpdate: false
  };

  const needDateColumn = {
    title: 'Necessidade',
    dataIndex: 'purchaseDate',
    key: 'purchaseDate',
    fixed: fixedColumns ? 'left' : undefined,
    className: 'cell-before-80',
    width: columnsWidthMap.purchaseDate,
    render: (val, row, index) => {
      return (
        <Div id={`item-date-${index + 1}`} $fullWidth margin={val ? '0' : '0 0 0 60px'}>
          <Paragraph type="small">{validDate(val, 'DD/MM/YY') || '-'}</Paragraph>
        </Div>
      );
    },
    shouldCellUpdate: false
  };

  const priceColumn = {
    title: 'Custo unit.',
    dataIndex: 'price',
    key: 'price',
    fixed: fixedColumns ? 'left' : undefined,
    className: 'cell-before-80',
    width: columnsWidthMap.price,
    render: (val, row, index) => {
      return (
        <Div id={`quote-item-price-${index + 1}`} $fullWidth margin={val ? '0' : '0 0 0 60px'}>
          <Paragraph type="small">{formatCurrency(val || 0, { currencySymbol: 'R$' })}</Paragraph>
        </Div>
      );
    },
    shouldCellUpdate: false
  };

  const totalColumn = {
    title: 'Custo total',
    dataIndex: 'total',
    key: 'total',
    fixed: fixedColumns ? 'left' : undefined,
    className: 'cell-before-80',
    width: columnsWidthMap.total,
    render: (val, row, index) => {
      const itemTotal = row?.price * row?.quantity;
      return (
        <Div id={`quote-item-total-${index + 1}`} $fullWidth>
          <Paragraph type="small">{formatCurrency(itemTotal || 0, { currencySymbol: 'R$' })}</Paragraph>
        </Div>
      );
    },
    shouldCellUpdate: false
  };

  const approveOrReject = ({ id, value }) => editDetails({ id, values: { approved: value }, refresh: [] });

  const supplierColumns = activeQuoteSuppliersColumns.map(quoteSupplier => {
    const isRefused = quoteSupplier?.status === quoteSupplierStatus.refused.id;

    return {
      alwaysShow: true,
      className:
        quoteSupplier?.approved && quoteSupplier.status !== quoteSupplierStatus.buy.id
          ? 'cell-with-border-top-primary-40'
          : undefined,
      title: (
        <Div $fullWidth justify="space-between" className="cell-with-before cell-before-full">
          <Paragraph
            type="small"
            ellipsis
            color={quoteSupplier?.status === quoteSupplierStatus.refused.id ? colors.neutral400 : colors.primary600}
            style={{ maxWidth: '40%', cursor: 'pointer' }}
            onClick={() => handleOpenEditQuoteSupplierDrawer(quoteSupplier)}
            id={`${quoteSupplier.supplier?.name}-header`}
          >
            <strong>{quoteSupplier.supplier?.name}</strong>
          </Paragraph>
          <Div gap={spaces.space1}>
            {canApprove.includes(quoteSupplier?.status) && !isPublicRoute && (
              <ApproveButton
                approved={quoteSupplier?.approved}
                onClick={value => approveOrReject({ id: quoteSupplier?.id, value })}
              />
            )}
            <Paragraph color={colors[quoteSupplierStatusMap[quoteSupplier.status]?.color || 'orange400']}>
              {statusIdNameMap[quoteSupplier.status]}
            </Paragraph>
            <QuoteSupplierActionDropdown
              quoteSupplier={quoteSupplier}
              isPublicRoute={isPublicRoute}
              handleLoad={({ status } = {}) =>
                status
                  ? editDetails({
                      id: quoteSupplier?.id,
                      values: { status },
                      refresh: ['items', 'quote', 'totals', 'selected']
                    })
                  : handleLoad({})
              }
              handleApproveOrReject={value => approveOrReject({ id: quoteSupplier?.id, value })}
              handleOpenEditQuoteSupplierDrawer={() => handleOpenEditQuoteSupplierDrawer(quoteSupplier)}
            />
          </Div>
        </Div>
      ),
      children: [
        ...(readOnly
          ? []
          : [
              {
                title: '',
                dataIndex: 'checkbox',
                key: 'checkbox',
                className: 'cell-before-none',
                width: supplierColumnsWidthMap.checkbox,
                render: (val, row) => {
                  const quoteItemSupplier = row.quoteItemSuppliers.find(
                    qits =>
                      qits.idQuoteSuppliers === quoteSupplier.id ||
                      qits?.quoteSupplier?.supplierReference === quoteSupplier?.supplierReference
                  );
                  const { isUnavailable } = quoteItemSupplier || { quantity: 0 };
                  let idRef;
                  if (row?.idItem) idRef = `i-${row?.idItem}`;
                  if (row?.externalBaseCode) idRef = `e-${row?.externalBaseCode}`;
                  if (!row?.idItem && !row?.externalBaseCode) idRef = `r-${row?.idRefurbishItem}`;

                  return (
                    <Div gap={spaces.space2}>
                      <Checkbox
                        disabled={
                          !quoteItemSupplier ||
                          (currentSelectedSupplierCheckbox && quoteSupplier?.id !== currentSelectedSupplierCheckbox) ||
                          isUnavailable
                        }
                        checked={!!selectedItems[`${row?.id}-${quoteSupplier?.id}`]}
                        onChange={e => onCheck(row.id, quoteSupplier?.id, e.target.checked)}
                      />
                      {totals?.orderItemsRef?.[quoteSupplier?.idSupplier]?.[idRef] && (
                        <Tooltip title="Ordens de compra já foram realizadas com esse item">
                          <FontAwesomeIcon icon={faFileInvoiceDollar} color={colors.neutral400} size={spaces.space2} />
                        </Tooltip>
                      )}
                    </Div>
                  );
                },
                shouldCellUpdate: false
              }
            ]),
        {
          title: 'Qtd.',
          dataIndex: 'quantity',
          key: 'quantity',
          width: supplierColumnsWidthMap.quantity,
          render: (val, row, index) => {
            const quoteItemSupplier = row.quoteItemSuppliers.find(
              qits =>
                qits.idQuoteSuppliers === quoteSupplier.id ||
                qits?.quoteSupplier?.supplierReference === quoteSupplier?.supplierReference
            );
            const { quantity, isUnavailable, splitTotalQuantity } = quoteItemSupplier || {
              quantity: row?.quantity || 1
            };

            const useQuantity = isCustomer ? quantity : splitTotalQuantity || quantity;

            const quantityDiff = useQuantity - row?.quantity;

            const formattedQuantity = formatDecimalsDinamically(useQuantity || '');

            const hasQuantityDiffColor = quantityDiff !== 0 && !isUnavailable && !isRefused;

            let captionColor = hasQuantityDiffColor ? colors.orange500 : undefined;
            if (isRefused) captionColor = colors.neutral400;

            const _values = {
              idItem: row?.idItem,
              splitId: row?.splitQuoteItemId,
              supplierReference: quoteSupplier?.supplierReference
            };

            const content =
              readOnly || isUnavailable ? (
                <StyledUnavailableDiv $fullWidth gap={spaces.space1} $isMobile={isMobile}>
                  <Paragraph type="small" id="quote-item-quantity" color={captionColor}>
                    {isUnavailable ? 'Ind.' : formattedQuantity}
                  </Paragraph>
                  {isUnavailable && !readOnly && (
                    <Tooltip title="Marcar que o item está disponível">
                      <Button
                        id="markAsAvailableButton"
                        className="mark-as-available-button"
                        padding="0"
                        text
                        onClick={() =>
                          editAvailableItem({
                            idQuoteSupplier: quoteSupplier.id,
                            idQuoteItems: row.id,
                            isSplit: !!row?.splitQuoteItemId
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faCircleXmark} color={colors.red500} />
                      </Button>
                    </Tooltip>
                  )}
                </StyledUnavailableDiv>
              ) : (
                <EditableInput
                  id={`quotation-map-item-quantity-${index + 1}`}
                  key={`quantity-${quoteSupplier.id}`}
                  noWrap
                  value={formattedQuantity}
                  row={row}
                  width="100%"
                  style={{ color: isRefused ? colors.neutral400 : undefined }}
                  onChange={value => {
                    editRow({
                      values: { quantity: formatNumber(value), ..._values },
                      idQuoteItem: row.id,
                      idQuoteSupplier: quoteSupplier.id,
                      idQuoteItemSupplier: quoteItemSupplier?.id
                    });
                  }}
                  placeholder="0,00"
                />
              );

            return hasQuantityDiffColor ? (
              <Tooltip title="Quantidade diferente da solicitada">
                <Div style={{ color: colors.orange500 }} gap={readOnly ? spaces.space1 : 0}>
                  <FontAwesomeIcon icon={quantityDiff > 0 ? faArrowUp : faArrowDown} />
                  {content}
                </Div>
              </Tooltip>
            ) : (
              content
            );
          },
          shouldCellUpdate: false
        },

        {
          title: 'Valor unit.',
          dataIndex: 'price',
          key: 'price',
          width: supplierColumnsWidthMap.price,
          render: (val, row, index) => {
            const quoteItemSupplier = row.quoteItemSuppliers.find(
              qits =>
                qits.idQuoteSuppliers === quoteSupplier.id ||
                qits?.quoteSupplier?.supplierReference === quoteSupplier?.supplierReference
            );
            const { price, isUnavailable, splitTotalQuantity } = quoteItemSupplier || {};

            const isBestPrice = quoteItemSupplier && row?.bestPrice === Number(price || 0) && !isRefused;
            const formattedPrice = formatCurrency(price || 0, { currencySymbol: 'R$' });
            let captionColor = isBestPrice ? colors.green600 : undefined;
            if (isRefused) captionColor = colors.neutral400;

            if (isUnavailable) {
              return '-';
            }

            const content = readOnly ? (
              <Paragraph type="small" id="quote-item-price" color={captionColor}>
                {formattedPrice}
              </Paragraph>
            ) : (
              <EditableInput
                type="currency"
                id={`quotation-map-item-price-${
                  quoteSupplier?.supplier?.name ? `${quoteSupplier?.supplier?.name}-` : ''
                }${index + 1}`}
                key={`price-${quoteSupplier?.id}`}
                noWrap
                value={formattedPrice}
                row={row}
                width="100%"
                InputStyle={{ color: isRefused ? colors.neutral400 : captionColor }}
                onChange={value => {
                  editRow({
                    values: {
                      price: formatNumber(value),
                      idItem: row?.idItem,
                      splitId: row?.splitQuoteItemId,
                      supplierReference: quoteSupplier?.supplierReference,
                      ...(quoteSupplier?.supplierReference && { quantity: splitTotalQuantity || row?.quantity || 1 })
                    },
                    idQuoteItem: row.id,
                    idQuoteSupplier: quoteSupplier.id,
                    idQuoteItemSupplier: quoteItemSupplier?.id
                  });
                }}
                placeholder="R$ 0,00"
              />
            );

            return isBestPrice ? (
              <Tooltip title="Valor unitário com menor preço">
                <Div style={{ color: captionColor }}>{content}</Div>
              </Tooltip>
            ) : (
              content
            );
          },
          shouldCellUpdate: false
        },
        {
          title: 'Valor total',
          dataIndex: 'total',
          key: 'total',
          className: 'cell-with-before cell-before-full',
          width: supplierColumnsWidthMap.total,
          render: (val, row) => {
            const quoteItemSupplier = row.quoteItemSuppliers.find(
              qits =>
                qits.idQuoteSuppliers === quoteSupplier.id ||
                qits?.quoteSupplier?.supplierReference === quoteSupplier?.supplierReference
            );
            const { price, quantity, isUnavailable, splitTotalQuantity } = quoteItemSupplier || {};

            const total = formatCurrency(
              Number(price) * Number(isCustomer ? quantity : splitTotalQuantity || quantity) || 0,
              {
                currencySymbol: 'R$'
              }
            );

            return (
              <Paragraph type="small" id="quote-item-total" color={isRefused ? colors.neutral400 : undefined}>
                {isUnavailable ? '-' : total}
              </Paragraph>
            );
          },
          shouldCellUpdate: false
        }
      ]
    };
  });

  const allColumns = [
    itemColumn,
    codeColumn,
    quantityColumn,
    unitColumn,
    needDateColumn,
    priceColumn,
    totalColumn
  ].filter(c => {
    return columnsToShow?.[c.key] || c.alwaysShow;
  });

  const lastColumn = allColumns.pop();

  return [...allColumns, { ...lastColumn, className: 'cell-with-before cell-before-full' }, ...supplierColumns];
};

const detailsRows = ({ editDetails, totals, onClickTotal, quoteSupplierStatus, readOnly }) => {
  const rows = {};

  rows.price = ({ quoteSupplier, key, isCustomer }) => {
    const { id, extraValues, splitExtraValues, splitId, [key]: value, status } = quoteSupplier;
    const isRefused = status === quoteSupplierStatus.refused.id;
    return (
      <EditableInput
        type="currency"
        noWrap
        value={formatCurrency(
          !isCustomer ? splitExtraValues?.[key] || extraValues?.[key] : extraValues?.[key] || value || 0,
          {
            currencySymbol: 'R$'
          }
        )}
        width="100%"
        disabled={readOnly}
        onChange={_value => {
          editDetails({
            id,
            values: {
              extraValues: {
                ...extraValues,
                ...(!isCustomer ? splitExtraValues : {}),
                [key]: formatNumber(_value)
              },
              supplierReference: quoteSupplier?.supplierReference,
              ...(!isCustomer && splitId && { splitId })
            },
            refresh: ['totals']
          });
        }}
        placeholder="R$ 0,00"
        justifyContent="flex-end"
        noPadding
        allowQuitOnClickOutside
        style={{ color: isRefused ? colors.neutral400 : undefined }}
      />
    );
  };

  rows.date = ({ quoteSupplier, key }) => {
    const { id, [key]: value, status } = quoteSupplier;
    const isRefused = status === quoteSupplierStatus.refused.id;

    return (
      <EditableInputBuilder
        id="purchase-date-picker"
        value={value ? dayjs(value).format('DD/MM/YY') : '-'}
        justifyContent="flex-end"
        noPadding
        noWrap
        style={{ color: isRefused ? colors.neutral400 : undefined }}
        disabled={readOnly}
        InputComponent={({ handleBlur }) => (
          <DatePicker
            id="purchase-date-picker"
            name="purchaseDate"
            format="DD/MM/YY"
            value={value ? dayjs(value) : null}
            placeholder="-"
            allowClear={false}
            onChange={_value => {
              editDetails({
                id,
                values: { [key]: _value.toISOString(), supplierReference: quoteSupplier?.supplierReference },
                refresh: []
              });
            }}
            onClick={e => e.stopPropagation()}
            suffixIcon={null}
            $width="100%"
            $textAlign="right"
            open
            onOpenChange={open => {
              if (!open) {
                handleBlur();
              }
            }}
          />
        )}
      />
    );
  };

  rows.subTotal = ({ quoteSupplier, key }) => {
    const { id, status, supplierReference } = quoteSupplier;
    const isRefused = status === quoteSupplierStatus.refused.id;
    return (
      <Paragraph type="small" id="quote-supplier-total" color={isRefused ? colors.neutral400 : undefined}>
        <strong>
          {totals?.[key] ? formatCurrency(totals?.[key]?.[supplierReference || id], { currencySymbol: 'R$' }) : '-'}
        </strong>
      </Paragraph>
    );
  };

  rows.total = ({ quoteSupplier, key, isCustomer }) => {
    const { id, status, idSupplier, approved, supplierReference } = quoteSupplier;
    const rowTotal = totals?.[key]
      ? formatCurrency(totals?.[key]?.[supplierReference || id], { currencySymbol: 'R$' })
      : '-';

    const ordersTotal = totals?.ordersMap?.[idSupplier];
    const isAnswered = quoteSupplierStatus.answeredOptions.includes(status);
    const isRefused = status === quoteSupplierStatus.refused.id;
    const isBestPrice = isAnswered && totals?.[key]?.[id] === totals?.bestPrice;
    const isApprovedAndNotBuy = approved && status !== quoteSupplierStatus.buy.id;

    let totalColor;

    if (isRefused) totalColor = colors.neutral400;
    else if (isApprovedAndNotBuy) totalColor = colors.white;

    return (
      <Div
        onClick={isAnswered && !isCustomer ? () => onClickTotal(idSupplier) : null}
        role="presentation"
        justify={isAnswered ? 'space-between' : 'flex-end'}
        style={isAnswered ? { cursor: 'pointer' } : undefined}
        $fullWidth
      >
        {isAnswered && !isCustomer ? (
          <Div gap={spaces.space1} $fullWidth>
            {totals?.hasDiffMap?.[id] && !isRefused && (
              <Tooltip title="Existem itens nessa cotação com quantidades diferentes da solicitada">
                <Div
                  $backgroundColor={isApprovedAndNotBuy ? colors.white : undefined}
                  width="12px"
                  height="12px"
                  $borderRadius={spaces.space1}
                >
                  <FontAwesomeIcon
                    color={colors.orange500}
                    icon={faCircleExclamation}
                    size="lg"
                    style={{ marginLeft: '-1px' }}
                  />
                </Div>
              </Tooltip>
            )}
            <Paragraph type="small" id="quote-supplier-total" color={isApprovedAndNotBuy ? colors.white : undefined}>
              <strong>Ordem de compra</strong>
            </Paragraph>
            {ordersTotal ? (
              <Tooltip
                title={`${ordersTotal} ${
                  ordersTotal > 1
                    ? 'ordens de compra já foram realizadas com esse fornecedor'
                    : 'ordem de compra já foi realizada com esse fornecedor'
                }`}
              >
                <Div
                  height={spaces.space2}
                  $backgroundColor={colors.neutral500}
                  $borderRadius={spaces.space0}
                  padding={spaces.space0}
                >
                  <Paragraph type="small" id="quote-supplier-total" color={colors.white}>
                    <strong>{ordersTotal}</strong>
                  </Paragraph>
                </Div>
              </Tooltip>
            ) : null}
          </Div>
        ) : null}
        <Div gap={spaces.space1} $fullWidth justify="flex-end">
          {isBestPrice && (
            <Div
              $backgroundColor={isApprovedAndNotBuy ? colors.white : undefined}
              width="12px"
              height="12px"
              $borderRadius={spaces.space1}
            >
              <Tooltip title="Menor preço">
                <FontAwesomeIcon
                  icon={faCircleDollar}
                  size="lg"
                  color={colors.green500}
                  style={{ marginLeft: '-1px' }}
                />
              </Tooltip>
            </Div>
          )}
          <Paragraph type="small" id="quote-supplier-total" color={totalColor}>
            <strong>{rowTotal}</strong>
            {isAnswered && !isCustomer && (
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ marginLeft: spaces.space1 }}
                color={isApprovedAndNotBuy ? colors.white : colors.primary600}
                size="lg"
              />
            )}
          </Paragraph>
        </Div>
      </Div>
    );
  };

  return rows;
};

export { columns, detailsRows };
