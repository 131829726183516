import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Space, Tooltip } from 'antd';
import * as dayjs from 'dayjs';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { toast } from 'react-toastify';

import ConfirmModal from './ConfirmModal';
import DatePicker from '../Datepicker/Datepicker';
import { Paragraph, Subtitle } from '../Text/Text';
import { Div, colors, fonts, spaces } from '../../styles/style';
import TooltipIcon from '../Tooltip/TooltipIcon';
import useViewport from '../../_Hooks/useViewport';
import useCRUD from '../../_Hooks/useCRUD';
import PaymentOrderComponent from '../../_Pages/Purchases/Order/PaymentOrderComponent';
import CenteredLoader from '../Loader/CenteredLoader';

const OrderPaymentModal = ({ onClose, idOrder, isSplit, ...props }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const [orderDate, setOrderDate] = useState(dayjs());
  const [idCompanyCustomer, setIdCompanyCustomer] = useState(null);
  const [orderRecipient, setOrderRecipient] = useState(null);
  const [isPaymentResponsibleValid, setIsPaymentResponsibleValid] = useState(true);

  const { handleGet, loading } = useCRUD({
    model: 'order',
    pathOptions: `/${isSplit ? 'split/' : ''}${idOrder}`,
    immediatelyLoadData: false,
    options: {
      include: [{ model: 'refurbish', attributes: ['idCompanyCustomer'] }]
    }
  });

  useEffect(() => {
    if (idOrder) {
      handleGet().then(resp => {
        if (resp?.error) return;

        if (!resp) {
          toast.error('Não foi possível encontrar a ordem de compra.');
          return;
        }
        const _idCompanyCustomer = resp?.idCompanyCustomer || resp?.refurbish?.idCompanyCustomer;
        setOrderRecipient(resp?.idCompanyCustomer ? 'payment' : 'expense');
        setIdCompanyCustomer(_idCompanyCustomer);
      });
    }
  }, [idOrder]);

  const handleSubmit = () => {
    return onClose({
      idOrder,
      type: orderRecipient,
      idCompanyCustomer,
      orderDate: orderDate.format('YYYY-MM-DD'),
      isSplit
    });
  };

  return (
    <ConfirmModal
      open
      center
      title="Confirmar compra"
      onClose={() => onClose()}
      zIndex={1000}
      modalWidth={736}
      loading={loading}
      onSubmit={handleSubmit}
      disableButton={!isPaymentResponsibleValid}
      {...props}
    >
      {loading ? (
        <CenteredLoader />
      ) : (
        <>
          <Subtitle type="secondary" weight={fonts.weight600} color={colors.neutral600}>
            Ao aceitar a ordem de compra, um lançamento financeiro será automaticamente cadastrado. Preencha as
            informações para prosseguir.
          </Subtitle>
          <Space size={24} direction="vertical" style={{ width: '100%', paddingTop: spaces.space1 }}>
            <Space direction="vertical">
              <Div gap={spaces.space1}>
                <Paragraph type="small">Data de compra</Paragraph>
                <TooltipIcon
                  icon={faInfoCircle}
                  iconColor={colors.primary500}
                  tooltipProps={{ overlayStyle: !_isMobile ? { minWidth: '440px' } : {}, id: 'orderDateInfo' }}
                  text={`A data quando a compra foi realizada.
                  Essa informação estará disponível na solicitação de compra.`}
                />
              </Div>
              <Tooltip
                title={`A data quando a compra foi realizada.
              Essa informação estará disponível na ordem de compra.`}
                overlayStyle={{ maxWidth: '400px' }}
              >
                <DatePicker
                  id="orderDate"
                  name="orderDate"
                  format="DD/MM/YYYY"
                  value={orderDate}
                  $width="200px"
                  onChange={value => setOrderDate(value)}
                  allowClear={false}
                />
              </Tooltip>
            </Space>
            <Space direction="vertical">
              <Paragraph type="small">Tipo de lançamento</Paragraph>
              <PaymentOrderComponent
                idCompanyCustomer={idCompanyCustomer}
                setIdCompanyCustomer={setIdCompanyCustomer}
                setOrderRecipient={setOrderRecipient}
                initialChecked={orderRecipient}
                propsMapKey="modal"
                setIsValid={setIsPaymentResponsibleValid}
                padding={`${spaces.space2} 0 ${spaces.space8} 0`}
                blockPayment={isSplit}
              />
            </Space>
          </Space>
        </>
      )}
    </ConfirmModal>
  );
};

OrderPaymentModal.propTypes = {
  onClose: PropTypes.func,
  idOrder: PropTypes.number,
  isSplit: PropTypes.bool
};

export default OrderPaymentModal;
