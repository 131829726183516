import React from 'react';
import PropTypes from 'prop-types';
import SimpleAccordion from '../Accordion/SimpleAccordion';
import DeliveryAddressForm from './DeliveryAddressForm';
import { getObjectWithSelectedFields } from '../../lib/helpers/helper';
import { Paragraph, Subtitle } from '../Text/Text';
import { Div, spaces } from '../../styles/style';

const AddressApportionment = ({
  listRefurbish,
  setRefurbishesAddress,
  refurbishesAddress,
  forceAddressFormUpdate,
  referObj,
  isPrinting,
  readOnly
}) => {
  return (
    <>
      <Div padding={spaces.space2}>
        <Subtitle type="secondary">Endereços de entrega</Subtitle>
      </Div>
      <Div
        gap={spaces.space2}
        direction="column"
        padding={`0 0 ${!isPrinting ? spaces.space2 : 0} ${!listRefurbish?.length || isPrinting ? spaces.space2 : 0}`}
        align="flex-start"
      >
        {!listRefurbish?.length && (
          <Paragraph type="small">Adicione um projeto para habilitar o endereço de entrega.</Paragraph>
        )}
        {listRefurbish?.map(refurbish => {
          return (
            <SimpleAccordion
              isPrinting={isPrinting}
              initOpen
              title={` ${refurbish?.name}`}
              paddingTop={isPrinting ? '0' : spaces.space2}
            >
              <DeliveryAddressForm
                tooltipText={`Ao salvar, o endereço cadastrado no projeto
                            será atualizado para o endereço de entrega informado`}
                addressFormValues={refurbish}
                saveAddressToProject={refurbishesAddress?.[refurbish.id]?.saveAddressToProject}
                setSaveAddressToProject={value =>
                  setRefurbishesAddress(prev => ({
                    ...prev,
                    [refurbish.id]: { ...prev[refurbish.id], saveAddressToProject: value }
                  }))
                }
                setAddressFormValues={values =>
                  setRefurbishesAddress(prev => ({
                    ...prev,
                    [refurbish.id]: getObjectWithSelectedFields(
                      { saveAddressToProject: prev[refurbish.id]?.saveAddressToProject, ...values },
                      [
                        'zipcode',
                        'street',
                        'number',
                        'complement',
                        'state',
                        'city',
                        'neighborhood',
                        'saveAddressToProject'
                      ]
                    )
                  }))
                }
                forceAddressFormUpdate={forceAddressFormUpdate}
                referObj={referObj}
                refurbish={refurbish}
                isPrinting={isPrinting}
                readOnly={readOnly}
              />
            </SimpleAccordion>
          );
        })}
      </Div>
    </>
  );
};

AddressApportionment.propTypes = {
  listRefurbish: PropTypes.instanceOf(Array),
  setRefurbishesAddress: PropTypes.func,
  refurbishesAddress: PropTypes.instanceOf(Object),
  forceAddressFormUpdate: PropTypes.bool,
  referObj: PropTypes.instanceOf(Object),
  isPrinting: PropTypes.bool,
  readOnly: PropTypes.bool
};

export default AddressApportionment;
