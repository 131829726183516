import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { getDateWithCustomHours } from './helper';

const getTypesMap = ganttInstance => ({
  all: [
    ganttInstance.config.links.finish_to_start,
    ganttInstance.config.links.start_to_start,
    ganttInstance.config.links.finish_to_finish,
    ganttInstance.config.links.start_to_finish
  ],
  start: [ganttInstance.config.links.start_to_start, ganttInstance.config.links.start_to_finish],
  end: [ganttInstance.config.links.finish_to_start, ganttInstance.config.links.finish_to_finish],
  sucessorStart: [ganttInstance.config.links.start_to_start, ganttInstance.config.links.finish_to_start],
  sucessorEnd: [ganttInstance.config.links.start_to_finish, ganttInstance.config.links.finish_to_finish]
});

const addDatesToCurrentTask = (ganttInstance, data) => {
  const currentTask = ganttInstance.getTask(data.id);
  if (!currentTask?.unscheduled) return;

  currentTask.end_date = getDateWithCustomHours(
    ganttInstance.calculateEndDate({
      start_date: ganttInstance.date.convert_to_utc(currentTask.start_date),
      duration: currentTask.originalDuration ? currentTask.originalDuration - 1 : 0
    }),
    {
      hours: 15
    }
  );

  currentTask.unscheduled = false;
  ganttInstance.updateTask(currentTask.id);
};

const handleLinkUpdate = (ganttInstance, data, setGanttChanges, setSelectedItems, setHasChanges) => (linkId, link) => {
  setGanttChanges(prev => {
    if (prev.createdLinks[linkId]) {
      return {
        ...prev,
        createdLinks: { ...prev.createdLinks, [linkId]: link }
      };
    }
    return {
      ...prev,
      updatedLinks: { ...prev.updatedLinks, [linkId]: link }
    };
  });

  setSelectedItems?.(prev =>
    prev.map(item => {
      if (item.id === linkId) {
        return { ...item, lag: link.lag };
      }
      return item;
    })
  );

  addDatesToCurrentTask(ganttInstance, data);
  ganttInstance.autoSchedule();
  setHasChanges(true);
};

const handleLinkDelete = (ganttInstance, data, setGanttChanges, setHasChanges) => (linkId, link) => {
  setGanttChanges(prev => {
    const { updatedLinks, createdLinks, deletedLinks, ...rest } = prev;
    const newUpdatedLinks = { ...updatedLinks };
    const newCreatedLinks = { ...createdLinks };
    const newDeletedLinks = { ...deletedLinks };

    if (createdLinks[linkId]) {
      // If the link was in createdLinks, just remove it from there
      delete newCreatedLinks[linkId];
    } else {
      // If it wasn't in createdLinks, remove from updatedLinks and add to deletedLinks
      delete newUpdatedLinks[linkId];
      newDeletedLinks[linkId] = link;
    }

    return {
      ...rest,
      updatedLinks: newUpdatedLinks,
      createdLinks: newCreatedLinks,
      deletedLinks: newDeletedLinks
    };
  });

  addDatesToCurrentTask(ganttInstance, data);
  ganttInstance.autoSchedule();
  setHasChanges(true);
};

const handleLinkAdd = (ganttInstance, data, setGanttChanges, setHasChanges) => (linkId, link) => {
  setGanttChanges(prev => ({
    ...prev,
    createdLinks: { ...prev.createdLinks, [linkId]: link }
  }));

  addDatesToCurrentTask(ganttInstance, data);
  ganttInstance.autoSchedule();
  setHasChanges(true);
};

const calculateColumnDurationChange = (ganttInstance, isTemplate, row, dateFields) => value => {
  const { startDateField, endDateField } = dateFields;

  if (isTemplate) {
    return {
      id: row.id,
      duration: value || 0,
      from: 'List'
    };
  }

  if (!value || Number(value) === 0) {
    return {
      id: row.id,
      unscheduled: true,
      duration: 0,
      start_date: null,
      end_date: null,
      from: 'List'
    };
  }

  if (value && !row[startDateField] && !row[endDateField]) {
    const task = ganttInstance.getTask(row.id);
    task.duration = value;
    task.unscheduled = false;
    ganttInstance.autoSchedule(task.id);

    return {
      id: row.id,
      unscheduled: false,
      duration: value,
      end_date: getDateWithCustomHours(
        ganttInstance.calculateEndDate({
          start_date: getDateWithCustomHours(new Date(task.start_date), { hours: 3 }),
          duration: value - 1
        }),
        { hours: 15 }
      ),
      from: 'List'
    };
  }

  const startDate = getDateWithCustomHours(row[startDateField] || row[endDateField] || dayjs().format('YYYY-MM-DD'), {
    hours: 3
  });

  const endDate = value
    ? getDateWithCustomHours(
        ganttInstance.calculateEndDate({
          start_date: startDate,
          duration: value - 1
        }),
        {
          hours: 15
        }
      )
    : row[endDateField];

  return {
    id: row.id,
    unscheduled: false,
    duration: value,
    end_date: endDate,
    from: 'List'
  };
};

const updateLinkLags = (ganttInstance, task) => {
  const typesMap = getTypesMap(ganttInstance);

  task.$target.forEach(linkId => {
    const link = ganttInstance.getLink(linkId);
    if (link.target === Number(task.id)) {
      const sourceTask = ganttInstance.getTask(link.source);
      const sourceRefDate = typesMap.start.includes(link.type) ? sourceTask.start_date : sourceTask.end_date;
      const targetRefDate = typesMap.sucessorStart.includes(link.type) ? task.start_date : task.end_date;

      if (sourceRefDate && targetRefDate) {
        link.lag = ganttInstance.calculateDuration({
          start_date: getDateWithCustomHours(sourceRefDate),
          end_date: getDateWithCustomHours(targetRefDate)
        });
      }
      ganttInstance.updateLink(linkId);
    }
  });
};

const cleanUnscheduledFromChildren = (ganttInstance, task) => {
  const typesMap = getTypesMap(ganttInstance);

  if (task?.unscheduled) return;

  task?.$source?.forEach(linkId => {
    const link = ganttInstance.getLink(linkId);
    const targetTask = ganttInstance.getTask(link.target);

    if (targetTask?.unscheduled) {
      targetTask.unscheduled = false;

      const sourceRefDate = typesMap.start.includes(link.type) ? task.start_date : task.end_date;

      const newTargetDate = ganttInstance.calculateEndDate({
        start_date: getDateWithCustomHours(sourceRefDate),
        duration: link.lag
      });

      const newDuration = Number(targetTask.originalDuration) ? targetTask.originalDuration - 1 : 0;

      if (typesMap.sucessorStart.includes(link.type)) {
        targetTask.start_date = getDateWithCustomHours(newTargetDate, targetTask.startHours);
        targetTask.end_date = getDateWithCustomHours(
          ganttInstance.calculateEndDate({ start_date: targetTask.start_date, duration: newDuration }),
          targetTask.endHours
        );
      } else {
        targetTask.end_date = getDateWithCustomHours(newTargetDate, targetTask.endHours);
        targetTask.start_date = getDateWithCustomHours(
          ganttInstance.calculateEndDate({ start_date: targetTask.end_date, duration: -newDuration }),
          targetTask.startHours
        );
      }
    }

    if (targetTask?.$source?.length) cleanUnscheduledFromChildren(ganttInstance, targetTask);
  });
};

const calculateDurationChange = (ganttInstance, task) => {
  if (!Number(task.duration)) {
    return {};
  }

  const startDate = getDateWithCustomHours(task.start_date, task.startHours);
  const endDate = ganttInstance.calculateEndDate(startDate, task.duration - 1);
  const endDateWithCustomHours = task.endHours && endDate ? getDateWithCustomHours(endDate, task.endHours) : endDate;

  return { startDate, endDate: endDateWithCustomHours };
};

const calculateStartDateChange = (ganttInstance, task) => {
  const _duration = task.unscheduled ? task.originalDuration || 1 : task.duration;
  const duration = _duration >= 1 ? _duration - 1 : 1;
  const startDate = getDateWithCustomHours(task.start_date, task.startHours);
  const endDate = ganttInstance.calculateEndDate(startDate, duration);
  const endDateWithCustomHours = task.endHours && endDate ? getDateWithCustomHours(endDate, task.endHours) : endDate;

  return { startDate, endDate: endDateWithCustomHours, duration };
};

const calculateEndDateChange = (ganttInstance, task) => {
  const duration =
    task.start_date && task.end_date
      ? ganttInstance.calculateDuration({
          start_date: ganttInstance.date.convert_to_utc(task.start_date),
          end_date: ganttInstance.date.convert_to_utc(task.end_date)
        })
      : 0;
  const startDate = getDateWithCustomHours(task.start_date, task.startHours);
  const endDate = getDateWithCustomHours(task.end_date, task.endHours);

  return { startDate, endDate, duration };
};

const calculateFormNewEndDate = (ganttInstance, start, duration, endHours) => {
  return getDateWithCustomHours(
    ganttInstance.calculateEndDate({
      start_date: ganttInstance.date.convert_to_utc(start),
      duration: duration - 1
    }),
    endHours
  );
};

const calculateFormNewStartDate = (ganttInstance, end, duration, startHours) => {
  return getDateWithCustomHours(
    ganttInstance.calculateEndDate({
      start_date: ganttInstance.date.convert_to_utc(end),
      duration: -duration + 1
    }),
    startHours
  );
};

const handleNegativeDuration = (ganttInstance, start, end, setNewFormValues) => {
  toast.error('A duração não pode ser negativa.');
  setNewFormValues(prev => ({
    ...(prev || {}),
    duration:
      start && end
        ? ganttInstance.calculateDuration({
            start_date: ganttInstance.date.convert_to_utc(start),
            end_date: ganttInstance.date.convert_to_utc(end)
          })
        : 0
  }));
};

const handleTaskDateChange = (ganttInstance, task, options = {}) => {
  const {
    delay = 100,
    refreshTask = true,
    showTask = false,
    skipUpdateLinkLags = false,
    skipCleanUnscheduledFromChildren = false,
    autoSchedule = true,
    preserveUnscheduled = false
  } = options;

  // eslint-disable-next-line no-param-reassign
  task.auto_scheduling = false;

  if (refreshTask) {
    ganttInstance.refreshTask(task.id);
  }

  if (!skipUpdateLinkLags) {
    updateLinkLags(ganttInstance, task);
  }

  if (!skipCleanUnscheduledFromChildren) {
    cleanUnscheduledFromChildren(ganttInstance, task);
  }

  setTimeout(() => {
    // eslint-disable-next-line no-param-reassign
    task.auto_scheduling = true;
    // eslint-disable-next-line no-param-reassign
    task.isDateChange = true;

    if (!preserveUnscheduled) {
      // eslint-disable-next-line no-param-reassign
      task.unscheduled = false;
    }
    ganttInstance.updateTask(task.id);

    if (autoSchedule) {
      ganttInstance.autoSchedule(task.id);
    }
    if (showTask) {
      ganttInstance.showTask(task.id);
    }
  }, delay);

  return task;
};

const shouldOnlyUpdateFormValues = (field, startDate, endDate, task, duration, config) => {
  const { startDateField, endDateField } = config;
  return (
    (field === 'duration' && !startDate && !endDate && task?.$target?.length === 0) ||
    (field === startDateField && !duration && !endDate) ||
    (field === endDateField && !startDate && !duration)
  );
};

const getResetValues = (field, startDateField, endDateField) => {
  const isStartDateField = startDateField === 'startDate';
  const isDuration = field === 'duration';

  return {
    ...(isDuration && { duration: 0 }),
    [startDateField]: null,
    [endDateField]: null,
    ...(isStartDateField && {
      startHours: null,
      endHours: null
    })
  };
};

const handleDateRelatedFieldChange = (
  ganttInstance,
  data,
  newFormValues,
  setNewFormValues,
  field,
  val,
  config = {
    startDateField: 'startDate',
    endDateField: 'endDate'
  }
) => {
  const { startDateField, endDateField } = config;
  const { [startDateField]: startDate, [endDateField]: endDate, duration: _duration } = {
    ...data,
    ...(newFormValues || {})
  };

  const task = ganttInstance.getTask(data.id);

  const isErasingDuration = field === 'duration' && (!val || Number(val) === 0);
  const isErasingStartOrEndDate = field !== 'duration' && !val;

  // Handle field reset in a single condition
  if (isErasingStartOrEndDate || isErasingDuration) {
    setNewFormValues(prev => ({
      ...(prev || {}),
      ...getResetValues(field, startDateField, endDateField)
    }));
    return;
  }

  let start = field === startDateField ? val : startDate || task?.start_date;
  let end = field === endDateField ? val : endDate || task?.end_date;

  if (field === 'duration' && val < 0) {
    handleNegativeDuration(ganttInstance, start, end, setNewFormValues);
    return;
  }

  // Handle simple form value updates
  if (shouldOnlyUpdateFormValues(field, startDate, endDate, task, _duration, config)) {
    setNewFormValues(prev => ({ ...(prev || {}), [field]: val }));
    return;
  }

  const newDuration = field === 'duration' ? val || 0 : _duration;

  // Format dates
  if (start) {
    start = ganttInstance.date.date_part(getDateWithCustomHours(start, task.startHours));
  }
  if (end) {
    end = getDateWithCustomHours(end, task.endHours);
  }

  const isChangingDurationWithStartDate = field === 'duration' && startDate;
  const isChangingStartDateWithDuration = field === startDateField && _duration;
  const isChangingDurationWithoutStartDate = field === 'duration' && !startDate;
  const isChangingEndDateWithoutStartDate = field === endDateField && !startDate;

  if (isChangingDurationWithStartDate || isChangingStartDateWithDuration) {
    end = calculateFormNewEndDate(ganttInstance, start, newDuration, task.endHours);
  } else if (isChangingDurationWithoutStartDate || isChangingEndDateWithoutStartDate) {
    start = calculateFormNewStartDate(ganttInstance, end, newDuration, task.startHours);
  }

  task.start_date = start;
  task.end_date = end;
  task.unscheduled = !start || !end;

  handleTaskDateChange(ganttInstance, task, {
    skipUpdateLinkLags: field === 'duration',
    skipCleanUnscheduledFromChildren: false,
    autoSchedule: true,
    showTask: true
  });
};

const calculateGanttComponentHeight = (ganttInstance, isPrintGantt, isMobile) => {
  const tasksLength = ganttInstance.getVisibleTaskCount();

  if (isPrintGantt) {
    return `${tasksLength * 40 + 120}px`;
  }
  if (isMobile) {
    return 'calc(100vh - 310px)';
  }
  return 'calc(100vh - 200px)';
};

const calculateDateRange = (ganttInstance, currentFilterId) => {
  if (!currentFilterId) return ganttInstance.getSubtaskDates();

  const filteredTasks = ganttInstance.getTaskBy(
    task => task?.filtered === currentFilterId && task?.start_date && task?.end_date
  );

  if (!filteredTasks.length) return ganttInstance.getSubtaskDates();

  let minDate = new Date(filteredTasks[0].start_time || filteredTasks[0].start_date);
  let maxDate = new Date(filteredTasks[0].end_time || filteredTasks[0].end_date);

  filteredTasks.forEach(task => {
    if (task?.start_date < minDate) minDate = task?.start_date;

    if (task?.end_date > maxDate) maxDate = task?.end_date;
  });

  return {
    start_date: new Date(minDate),
    end_date: new Date(maxDate)
  };
};

export {
  addDatesToCurrentTask,
  handleLinkUpdate,
  handleLinkDelete,
  handleLinkAdd,
  calculateColumnDurationChange,
  handleDateRelatedFieldChange,
  handleTaskDateChange,
  getTypesMap,
  updateLinkLags,
  cleanUnscheduledFromChildren,
  calculateDurationChange,
  calculateStartDateChange,
  calculateEndDateChange,
  calculateGanttComponentHeight,
  calculateDateRange
};
