import React, { memo, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/pro-solid-svg-icons';
import {
  faArrowLeft,
  faArrowRotateRight,
  faEllipsis,
  faInfoCircle,
  faLink,
  faMessage,
  faPenToSquare,
  faShareNodes,
  faTrashCan,
  faXmark
} from '@fortawesome/pro-regular-svg-icons';

import { Menu, Switch } from 'antd';

import useViewport from '../../_Hooks/useViewport';
import useFilter from '../../_Hooks/useFilter';
import useCRUD from '../../_Hooks/useCRUD';

import { colors, Div, fonts, radius, spaces } from '../../styles/style';

import Button from '../Button/Button';
import Box from '../Box/Box';
import Drawer from '../Drawer/Drawer';
import ConfirmModal from './ConfirmModal';
import FormV2 from '../Form/FormV2';
import EditableInput from '../Input/EditableInput';
import CenteredLoader from '../Loader/CenteredLoader';
import WarningBar from '../Alert/WarningBar';

import EditOrCreateCustomerOrSupplier from '../Drawer/EditOrCreateCustomerOrSupplier';
import CatalogDrawer from '../Drawer/CatalogDrawer';
import DrawerComments, { calcDrawerWidth } from '../Drawer/DrawerComponents/DrawerComments';
import DrawerHeaderComponents from '../Drawer/DrawerComponents/DrawerHeaderComponents';
import DrawerTabs from '../Drawer/DrawerComponents/DrawerTabs';
import DrawerDropdownShareMenu from '../Drawer/DrawerComponents/DrawerDropdownShareMenu';

import Description from '../Content/Description';
import ItemAttachmentContent from '../Content/ItemAttachmentContent';

import CompositionBreadcrumb from '../Breadcrumb/CompositionBreadcrumb';
import CompositionItems from '../Composition/CompositionItems';
import { Paragraph, Subtitle, Title } from '../Text/Text';
import TooltipIcon from '../Tooltip/TooltipIcon';

import { getCatalogDrawerDisplayToast, getObjectWithSelectedFields } from '../../lib/helpers/helper';
import formatNumber from '../../lib/helpers/formatNumber';
import formatNumberWithDynamicDecimals from '../../lib/helpers/formatNumberWithDynamicDecimals';

import { compositionMappingV2, compositionSchemaV2 } from '../../lib/mapping/Form/itemSchemaV2';
import { mappingDescriptionConf } from '../../lib/mapping/Description/mappingDescriptionConf';
import ConfirmModalAlertInfoBuilder from './ConfirmModalAlertInfoBuilder';
import LinkedItemTag from '../Tag/LinkedItemTag';
import { StyledMenu } from '../Dropdown/DropdownV2.styled';

const childrenModel = {
  item: 'item',
  'refurbish-items': 'refurbishItems',
  'template-item': 'templateItem'
};

const EditCompositionModal = ({
  id,
  idReference,
  initialValues,
  onClose = f => f,
  onAfterSubmit = f => f,
  model = 'refurbish-items',
  readOnly,
  tab = '0',
  isTemplate,
  groupedReference,
  renderColumns,
  idCompany,
  libraryProps,
  onlyLabor,
  parentIsComposition,
  onlyLibraryFields,
  ...props
}) => {
  const { user } = useSelector(state => state.authReducer);
  const { userType } = useSelector(state => state.setup.enums);
  const { refurbishItemType: itemTypeEnum } = useSelector(state => state.setup.enums);

  const isCustomer = user.userType === userType.customer.value;
  const isUserAnonymous = user?.anonymous;
  const { isMobile, isUltraWide } = useViewport(window.innerWidth);
  const _isMobile = isMobile();

  const [_id, setId] = useState(id);
  const [name, setName] = useState(initialValues?.name || '');
  const [observation, setObservation] = useState('');
  const [listItems, setListItems] = useState([]);
  const [formData, setFormData] = useState({});
  const [changeableData, setChangeableData] = useState({});
  const [linkedItem, setLinkedItem] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState({ map: {}, list: [] });

  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);

  const [selectedTab, setSelectedTab] = useState(tab || '0');
  const [isSimple, setIsSimple] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [checkedValue, setCheckedValue] = useState(true);
  const [touched, setTouched] = useState(false);
  const [preventSave, setPreventSave] = useState(false);
  const [isEditableFormValid, setIsEditableFormValid] = useState(true);

  const [showCatalogDrawer, setShowCatalogDrawer] = useState(false);
  const [secondDrawer, setSecondDrawer] = useState(false);
  const [extraDrawerStyle, setExtraDrawerStyle] = useState(0);
  const [editSupplierId, setEditSupplierId] = useState(null);
  const [showEditLibraryItemModal, setShowEditLibraryItemModal] = useState(false);
  const [editOrCreateComposition, setEditOrCreateComposition] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState(false);
  const [showConfirmContextChangeModal, setShowConfirmContextChangeModal] = useState(false);

  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [triggerLink, setTriggerLink] = useState(false);
  const [reload, setReload] = useState();
  const [refreshComposition, setRefreshComposition] = useState(false);

  const widthToComposition = isUltraWide() ? 440 : 336;

  const { storedFilters, updateFilters } = useFilter({
    persistKey: 'show-comments-side-component-refurbish-items'
  });

  let initialShowComments = storedFilters !== null ? storedFilters?.showCommentsSideComponent : true;
  if (isUserAnonymous || _isMobile || !libraryProps?.isLibrary || !isEdit || isTemplate) initialShowComments = false;

  const [showComponents, setShowComponents] = useState({
    showComments: initialShowComments,
    showOptions: false
  });

  useEffect(() => {
    if (showComponents.showComments !== storedFilters?.showCommentsSideComponent)
      updateFilters({ ...storedFilters, showCommentsSideComponent: showComponents.showComments });
  }, [showComponents.showComments]);

  const handleSetLinkedItem = modelItem => {
    if (modelItem?.item) setLinkedItem(modelItem?.item);
    else if (modelItem?.externalBaseCode) setLinkedItem(modelItem);
    else setLinkedItem(null);
  };

  const { supplier } = changeableData?.refurbishItem || changeableData || {};
  const hideSupplier = !renderColumns?.idSupplier && isCustomer;

  const hasSupplier = supplier?.id && supplier?.id !== 1;

  const { data, loading, handleGet, handleCreate, handleUpdate, handleDelete } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  const {
    data: compositionItemsData,
    handleGet: handleGetCompositionItems,
    loading: loadingCompositionItems
  } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  const { handleCreate: handleCreateItem } = useCRUD({
    model: 'item',
    immediatelyLoadData: false
  });

  const getRefetchOptions = ({ isCompositionItemsReload = false } = {}) => {
    const isModelItem = model === 'item';
    const baseOptions = {
      where: { ...(isTemplate ? { idTemplate: idReference } : { idRefurbish: idReference }), idCompany },
      order: isModelItem
        ? '"children->relationItem"."createdAt"'
        : [['children', 'createdAt', 'asc'], [['children', 'name', 'asc']]],
      include: [
        {
          model: childrenModel[model],
          as: 'children',
          include: [
            'units',
            ...(!isModelItem
              ? [
                  {
                    attributes: ['id', 'code', 'name', 'images'],
                    model: 'Item',
                    as: 'item',
                    include: [{ attributes: ['id', 'name'], model: 'Unit', as: 'units' }]
                  }
                ]
              : [])
          ]
        },
        ...(!isModelItem
          ? [
              {
                model: 'Item',
                as: 'item',
                include: [
                  { attributes: ['id', 'name'], model: 'Unit', as: 'units' },
                  { attributes: ['id', 'name', 'code'], model: 'ItemClass', as: 'class' }
                ]
              }
            ]
          : [])
      ]
    };

    if (isCompositionItemsReload) {
      return {
        ...baseOptions,
        attributes: [
          'totalProductRaw',
          'totalLaborRaw',
          'total',
          'totalProduct',
          'totalLabor',
          'calculatedBdi',
          'bdi',
          'totalBdi',
          'price'
        ]
      };
    }

    return {
      ...baseOptions,
      include: [
        'supplier',
        'units',
        'class',
        ...(!isTemplate && !onlyLabor && model !== 'item' ? ['files'] : []),
        ...baseOptions.include,
        ...(!isTemplate && !libraryProps?.isLibrary ? [{ model: 'comments', attributes: ['id'], as: 'comments' }] : [])
      ]
    };
  };

  const handleLoad = (generateLoading = true) => {
    if (_id) {
      handleGet({
        refetchPathOptions: _id ? `/${_id}` : '',
        refetchOptions: props?.options || getRefetchOptions(),
        generateLoading
      });
      setIsEdit(true);
    } else setIsEdit(false);
  };

  const handleReloadCompositionItems = () => {
    if (_id) {
      handleGetCompositionItems({
        refetchPathOptions: _id ? `/${_id}` : '',
        refetchOptions: props?.options || getRefetchOptions({ isCompositionItemsReload: true })
      });
    }
  };

  const mergedCompositionItemsData = useMemo(() => {
    return {
      ...data,
      totalProductRaw: compositionItemsData?.totalProductRaw ?? data?.totalProductRaw,
      totalLaborRaw: compositionItemsData?.totalLaborRaw ?? data?.totalLaborRaw,
      total: compositionItemsData?.total ?? data?.total,
      totalProduct: compositionItemsData?.totalProduct ?? data?.totalProduct,
      totalLabor: compositionItemsData?.totalLabor ?? data?.totalLabor,
      calculatedBdi: compositionItemsData?.calculatedBdi ?? data?.calculatedBdi,
      bdi: compositionItemsData?.bdi ?? data?.bdi,
      totalBdi: compositionItemsData?.totalBdi ?? data?.totalBdi,
      price: compositionItemsData?.price ?? data?.price
    };
  }, [data, compositionItemsData]);

  useEffect(() => {
    if (!loadingCompositionItems) {
      setListItems(compositionItemsData?.children);
    }
  }, [loadingCompositionItems, compositionItemsData]);

  const parseToListItem = item => {
    const price = Number(item.price) || 0;
    const bdi = Number(item.bdi) || 0;
    const quantity = Number(item.quantity) || 1;

    const selectedFields = getObjectWithSelectedFields(item, ['id', 'idItem', 'name', 'type', 'code', 'idUnit']);

    return {
      ...selectedFields,
      tempId: `temp-${item.id}`,
      price,
      bdi,
      quantity,
      total: price * quantity,
      totalBdi: (parseFloat(price) * (1 + parseFloat(bdi) / 100) * parseFloat(quantity)).toFixed(2),
      idItem: item.idItem || item.id
    };
  };

  const simpleItems = [
    {
      id: -1,
      type: itemTypeEnum.product,
      name: 'Item',
      bdi: '0.00',
      price: '0.00',
      totalBdi: '0.00'
    },
    {
      id: -2,
      type: itemTypeEnum.labor,
      name: 'Serviço',
      bdi: '0.00',
      price: '0.00',
      totalBdi: '0.00'
    }
  ];

  const getSimpleItemsValues = _listItems => {
    const itemsByType = _listItems.reduce((acc, item) => {
      acc[item.type] = item;
      return acc;
    }, {});

    return {
      productPrice: itemsByType[itemTypeEnum.product]?.price || '0.00',
      laborPrice: itemsByType[itemTypeEnum.labor]?.price || '0.00',
      productBdi: itemsByType[itemTypeEnum.product]?.bdi || '0.00',
      laborBdi: itemsByType[itemTypeEnum.labor]?.bdi || '0.00'
    };
  };

  const _handleCreateItems = childValues => {
    const checkItemExists = (items, idToCheck) => items.some(item => (item?.idItem || item?.id) === idToCheck);
    const itemExists = checkItemExists(listItems, childValues?.idItem);

    if (itemExists && childValues?.idItem) {
      toast.error('Este item já está presente na composição.');
      return;
    }

    if (!isEdit) {
      setListItems(prev => [...prev, parseToListItem(childValues)]);
      handleLoad(false);
      return;
    }

    const { idCostCenter, idRefurbishGroup, idSupplier, idSearch, ..._values } = childValues;

    const values = {
      idSupplier: idSupplier || undefined,
      idCostCenter: idCostCenter || undefined,
      idRefurbishGroup: idRefurbishGroup || undefined,
      ..._values,
      ...(isTemplate ? { idTemplate: idReference } : { idRefurbish: idReference }),
      idParent: _id,
      ...(idSearch ? { idSearch } : { idItem: childValues?.id })
    };

    handleCreate({
      values,
      refresh: false,
      noLoading: true
    }).then(res => {
      if (!res?.error) {
        toast.success('Item adicionado na composição com sucesso.');
        handleReloadCompositionItems();
      }
    });
  };

  const handleSaveBreadcrumb = dataToSave => {
    if (dataToSave?.id) {
      const newList = breadcrumb.map[dataToSave?.id]
        ? breadcrumb.list?.map(breadcrumbItem =>
            breadcrumbItem?.id === dataToSave?.id ? { id: dataToSave?.id, name: dataToSave?.name } : breadcrumbItem
          )
        : [...breadcrumb.list, { id: dataToSave?.id, name: dataToSave?.name }];

      setBreadcrumb(prev => ({
        map: { ...prev.map, [dataToSave?.id]: true },
        list: newList
      }));
      setId(dataToSave?.id);
    }
  };

  const handleRemoveBreadcrumb = ({ indexToGo }) => {
    const newList = breadcrumb?.list?.slice(0, indexToGo);
    const last = breadcrumb?.list?.[indexToGo];
    const newMap = {};
    newList.forEach(el => {
      newMap[el] = true;
    });

    setBreadcrumb({ map: newMap, list: newList });
    setId(last?.id);
  };

  const handleSubmit = () => {
    if (!isEditableFormValid) {
      setTriggerSubmit(false);
      return;
    }

    const libraryValues = getObjectWithSelectedFields(formData?.original, ['code', 'idUnit', 'idClass']);

    const commonValues = getObjectWithSelectedFields(formData?.original, [
      'description',
      'idSupplier',
      'idRefurbishGroup',
      'idCostCenter'
    ]);

    const refurbishItemValues = getObjectWithSelectedFields(formData?.original, [
      'quantity',
      ...(!parentIsComposition ? ['idParent'] : [])
    ]);

    if (refurbishItemValues.quantity != null) {
      refurbishItemValues.quantity = formatNumber(refurbishItemValues?.quantity);
    }

    if (!isEdit) {
      const createItemValues = {
        name,
        ...libraryValues,
        ...commonValues,
        ...groupedReference,
        type: itemTypeEnum.composition,
        idUnit: libraryValues?.idUnit || 1,
        isSimple,
        autoCode: !_id && checkedValue,
        ...(isSimple
          ? getSimpleItemsValues(listItems)
          : {
              children: listItems.map(({ tempId, ...item }) => item)
            })
      };

      handleCreateItem({
        values: createItemValues,
        refresh: false,
        noLoading: false
      }).then(res => {
        setTriggerSubmit(false);

        if (!res?.error) {
          if (libraryProps?.isLibrary) {
            setIsEdit(true);
            setReload(true);
            setTouched(false);
            setId(res.id);
            handleLoad();
            toast.success('Composição criada com sucesso.');
            onAfterSubmit(res);

            if (parentIsComposition) {
              onClose(res);
            }

            return;
          }
          const createValues = {
            ...initialValues,
            ...commonValues,
            ...refurbishItemValues,
            refurbishItemValues: listItems.reduce((acc, { idItem, price, bdi }) => {
              if (idItem) {
                acc[idItem] = { price, bdi };
              }
              return acc;
            }, {}),
            ...(isSimple
              ? {
                  productBdi: listItems[0]?.bdi,
                  laborBdi: listItems[1]?.bdi
                }
              : {}),
            isSimple,
            idItem: res?.id,
            id: res?.id,
            type: itemTypeEnum.composition,
            ...(initialValues?.idParent !== refurbishItemValues.idParent &&
              refurbishItemValues.idParent !== undefined && { order: null }),
            ...(isTemplate ? { idTemplate: idReference } : { idRefurbish: idReference }),
            images,
            name
          };

          handleCreate({
            values: createValues,
            refresh: false
          }).then(createRes => {
            if (!createRes?.error) {
              setIsEdit(true);
              setTriggerSubmit(false);
              setReload(true);
              setTouched(false);
              setId(createRes.id);
              handleLoad(false);
              toast.success('Composição criada com sucesso.');
              setTriggerSubmit(false);
              onAfterSubmit(createRes);
            }
          });
        }
      });
    } else {
      const currentId = _id || changeableData?.id;

      handleUpdate({
        updatePathOptions: `/${currentId}`,
        values: {
          ...(libraryProps?.isLibrary ? libraryValues : refurbishItemValues),
          ...commonValues,
          ...(!libraryProps?.isLibrary && !linkedItem ? libraryValues : {}),
          ...(libraryProps?.isLibrary || !linkedItem ? { name } : {}),
          ...(isTemplate ? { idTemplate: idReference } : { idRefurbish: idReference }),
          images,
          price: undefined,
          isSimple,
          observation,
          files,
          ...(data?.idParent !== refurbishItemValues.idParent && { order: null })
        },
        noLoading: true,
        refresh: false
      }).then(res => {
        if (!res?.error) {
          toast.success('Composição atualizada com sucesso.');
          setTouched(false);
          if (triggerSubmit.goBack) {
            handleRemoveBreadcrumb({ indexToGo: breadcrumb?.list?.length - 2 });
          } else handleLoad();
          setTriggerSubmit(false);
          onAfterSubmit(res);
        }
      });
    }
  };

  const _setFormData = source => values => {
    if (values) setFormData(prev => ({ ...prev, [source]: { ...prev?.[source], ...values } }));
  };

  const handleSaveLink = itemToSave => {
    handleUpdate({
      updatePathOptions: `/${_id}/setChildrenFromItem`,
      values: { idItem: itemToSave?.id || null, externalBaseCode: itemToSave?.externalBaseCode || null },
      refresh: false,
      noLoading: true,
      displayToast: getCatalogDrawerDisplayToast(itemToSave)
    }).then(resp => {
      if (!resp?.error) {
        handleLoad();
        setLinkedItem(itemToSave);
        if (!itemToSave) _setFormData('labor')(data);
      }
    });
  };

  const handleConfirmModal = confirmModalType => {
    const confirmModalPropsMap = {
      unlinkItem: {
        title: 'Desvincular item',
        alertInfo: 'Ao desvincular esse item, ele perderá a relação com a Biblioteca',
        preInfoDescription: 'Deseja concluir ação?',
        onSubmit: () => handleSaveLink(null)
      },
      editLibraryItem: {
        title: 'Todas as ocorrências serão editadas',
        alertInfo: (
          <ConfirmModalAlertInfoBuilder
            alertText={`Ao editar essa composição, os
            seguintes campos de todas as aparições desse item serão alteradas:`}
            afterAlertList={['Nome do item', 'Código', 'Unidade', 'Classe']}
          />
        ),
        preInfoDescription: 'Deseja confirmar?',
        onSubmit: () => handleSubmit()
      },
      deleteItem: {
        title: 'Excluir composição',
        text: 'Deseja realmente excluir essa composição?',
        onSubmit: () => {
          handleDelete({
            deletePathOptions: `/${_id}`,
            displayToast: 'Operação realizada com sucesso!',
            refresh: false
          }).then(resp => {
            if (!resp?.error) {
              handleLoad();
              onClose(true);
            }
          });
        }
      },
      createDetailedComposition: {
        title: 'Atualização de itens na Biblioteca',
        showOnCreate: true,
        alertInfo: (
          <ConfirmModalAlertInfoBuilder
            alertText={`Ao salvar, os itens vinculados à Biblioteca que estão dentro dessa composição
              terão os seguintes valores atualizados na Biblioteca, caso tenham sido alterados:`}
            afterAlertList={['Nome', 'Unidade', 'Quantidade', 'Custo unitário']}
          />
        ),
        preInfoDescription: 'Deseja continuar?',
        onSubmit: () => handleSubmit()
      }
    };
    if (_id || confirmModalPropsMap[confirmModalType].showOnCreate) {
      setConfirmModalProps(confirmModalPropsMap[confirmModalType]);
    } else {
      confirmModalPropsMap[confirmModalType].onSubmit();
    }
  };

  const handleLinkClick = () => {
    if (linkedItem || data?.externalBaseCode) {
      handleConfirmModal('unlinkItem');
      return;
    }
    setShowCatalogDrawer(true);
  };

  useEffect(() => {
    handleLoad();
  }, [_id]);

  useEffect(() => {
    if (isSimple && (!data?.children || data?.children.length === 0)) {
      setListItems(simpleItems);
    } else {
      setListItems(data?.children || []);
    }
    handleSetLinkedItem(data);
    setChangeableData && setChangeableData(data);
    if (data) {
      setFormData({
        original: getObjectWithSelectedFields(data, [
          ...(!parentIsComposition ? ['idParent'] : []),
          'quantity',
          'idSupplier',
          'idCostCenter',
          'idRefurbishGroup',
          'description',
          'status'
        ]),
        labor: !linkedItem ? getObjectWithSelectedFields(data, ['code', 'name', 'autoCode', 'idClass', 'idUnit']) : {}
      });
    }
  }, [data, isSimple]);

  useEffect(() => {
    if (refreshComposition) {
      handleLoad();
      setRefreshComposition(false);
    }
  }, [refreshComposition]);

  useEffect(() => {
    if (triggerLink) {
      handleLinkClick();
      setTriggerLink(false);
    }
  }, [triggerLink]);

  useEffect(() => {
    if (triggerSubmit) {
      if (libraryProps?.isLibrary) {
        handleConfirmModal('editLibraryItem');
        return;
      }
      if (!isEdit && listItems.some(item => item.idItem) && model === 'item') {
        handleConfirmModal('createDetailedComposition');
        return;
      }
      handleSubmit();
    }
  }, [triggerSubmit]);

  useEffect(() => {
    if (changeableData?.id) {
      setFiles(changeableData.files || []);
      setObservation(changeableData.observation || '');
      setImages(changeableData.images || []);
      setName(changeableData.name || '');
      setIsSimple(changeableData.isSimple || false);
      handleSetLinkedItem(changeableData);
      handleSaveBreadcrumb(changeableData);
    }
  }, [changeableData]);

  useEffect(() => {
    if (typeof editOrCreateComposition === 'object') {
      if (touched) {
        setShowConfirmContextChangeModal({ onSubmit: handleSaveBreadcrumb, args: { ...editOrCreateComposition } });
      } else {
        setId(editOrCreateComposition?.id || _id);
      }
    }
  }, [editOrCreateComposition]);

  useEffect(() => {
    if (showConfirmContextChangeModal && !touched) {
      const { onSubmit, args } = showConfirmContextChangeModal;
      onSubmit(args);
      setShowConfirmContextChangeModal(null);
    }
  }, [touched]);

  const CompositionComponent = () => (
    <Div padding={spaces.space2} direction="column" align="start" $fullWidth>
      <>
        {loading ? (
          <CenteredLoader />
        ) : (
          <>
            <ConfirmModal
              open={!!confirmModalProps}
              onClose={() => {
                setConfirmModalProps(null);
                setTriggerSubmit(false);
              }}
              alertInfoPadding={`${spaces.space1} ${spaces.space1}`}
              {...(confirmModalProps || {})}
            />
            {showCatalogDrawer && (
              <CatalogDrawer
                open
                shouldConfirm
                cardProps={{ addTooltipText: 'Vincular', addIcon: faLink, iconStyle: { margin: '-3.5px' } }}
                fixedType={itemTypeEnum.composition}
                disableTypeFilter
                tabsToOpen={[0, 1]}
                newItemTypeRedirect={itemTypeEnum.composition}
                onClose={() => setShowCatalogDrawer(false)}
                onSubmit={val => {
                  setShowCatalogDrawer(false);
                  handleSaveLink(val);
                }}
              />
            )}
            <Box
              style={{
                marginRight: `${secondDrawer && !isMobile() && !idReference ? widthToComposition : 0}px`,
                position: 'relative',
                borderRadius: `${radius.radius1} ${radius.radius1} 0 0`,
                padding: '0px'
              }}
            />
            {!onlyLabor && (
              <>
                <FormV2
                  schema={compositionSchemaV2}
                  readOnly={isCustomer || readOnly}
                  isFormValid={setIsEditableFormValid}
                  mapping={compositionMappingV2({
                    isTemplate,
                    isMobile: _isMobile,
                    refurbishItemTypeEnum: itemTypeEnum,
                    idReference,
                    idCompany,
                    isEdit,
                    isLibrary: libraryProps?.isLibrary,
                    checkedValue,
                    setCheckedValue,
                    onlyLibraryFields,
                    parentIsComposition: parentIsComposition || breadcrumb.list?.length > 1,
                    isSinapi: !!changeableData?.externalBaseCode,
                    linkedItem,
                    columnsToShow: renderColumns,
                    isCustomer,
                    readOnly
                  })}
                  displayButtons={false}
                  onTouchedChanged={_setFormData('original')}
                  onValueChanged={setTouched}
                  data={{
                    ...initialValues,
                    ...(data || {}),
                    quantity: formatNumberWithDynamicDecimals(data?.quantity)
                  }}
                  style={{
                    width: '100%'
                  }}
                />
              </>
            )}
            {!isCustomer && (
              <CatalogDrawer
                open={secondDrawer}
                idParent={_id}
                secondDrawer={secondDrawer}
                tabsToOpen={[0, 2]}
                onClose={() => {
                  setSecondDrawer(false);
                  setExtraDrawerStyle(0);
                }}
                onSubmit={_handleCreateItems}
                isComposition
              />
            )}
          </>
        )}
      </>
    </Div>
  );

  const drawerFooter = (
    <>
      <Button text loading={loading} onClick={() => onClose(reload || libraryProps?.isLibrary)}>
        Cancelar
      </Button>
      {!readOnly && !isCustomer && (
        <Div gap={spaces.space1}>
          <Button
            id="submit-drawer"
            type="primary"
            ghost={breadcrumb?.list?.length > 1 && isEdit}
            onClick={() => setTriggerSubmit(true)}
            loading={preventSave}
          >
            {isEdit || libraryProps?.isLibrary ? 'Salvar' : 'Criar na biblioteca e adicionar no orçamento'}
          </Button>
          {breadcrumb?.list?.length > 1 && (
            <Button
              id="submit-drawer-and-return"
              type="primary"
              onClick={() => setTriggerSubmit({ goBack: true })}
              loading={preventSave}
            >
              Salvar e voltar
            </Button>
          )}
        </Div>
      )}
    </>
  );

  const propsForTrack = { idCompany: user?.idCompany, idRefurbish: idReference, idUser: user?.id };
  const urlContext = libraryProps?.isLibrary ? 'lc' : 'o';

  const { handleGet: getLibraryItem } = useCRUD({
    model: 'item',
    immediatelyLoadData: false
  });

  const editOptionsMenu = () => {
    const editItemObj = {
      id: 'edit-library-item',
      icon: faPenToSquare,
      text: 'Editar na biblioteca',
      onClick: () => {
        setShowEditLibraryItemModal(true);
        setShowComponents({ ...showComponents, showOptions: false });
      },
      shouldDisplayWhen: linkedItem && !changeableData?.externalBaseCode
    };

    const updateLibraryObj = {
      id: 'update-from-library',
      icon: faArrowRotateRight,
      text: changeableData?.externalBaseCode ? 'Restaurar valores SINAPI' : 'Atualizar item com a biblioteca',
      onClick: () => {
        setConfirmModalProps({
          title: 'Atualizar item',
          alertInfo: (
            <ConfirmModalAlertInfoBuilder
              alertText={`Ao atualizar esse item, os seguintes campos serão
              substituídos pelas informações do item na ${changeableData?.externalBaseCode ? 'SINAPI' : 'Biblioteca'}:`}
              afterAlertList={[
                'Custo',
                'Fornecedor',
                'Marca',
                'Grupo',
                'Categoria',
                'Link',
                'Descrição',
                'Itens dentro da composição'
              ]}
            />
          ),
          preInfoDescription: 'Deseja atualizar as informações do item?',
          onSubmit: async () => {
            if (data?.externalBaseCode) {
              const updateResp = await handleUpdate({
                updatePathOptions: `/${data?.id}/setChildrenFromItem`,
                displayToast: 'Operação realizada com sucesso!',
                values: {
                  externalBaseCode: data?.externalBaseCode,
                  event: 'item-nature-changed',
                  action: 'updated'
                },
                refresh: false
              });

              if (!updateResp?.error) {
                setReload(true);
                setFiles([]);
                setImages([]);
                setName('');
                setObservation('');
                handleLoad();
              }
              return;
            }

            if (!data?.idItem) return;

            const resp = await getLibraryItem({
              refetchPathOptions: `/${data.idItem}`,
              refetchOptions: {
                attributes: ['price', 'idSupplier', 'idRefurbishGroup', 'idCostCenter', 'brand', 'link', 'description']
              }
            });

            if (!resp) return;

            const updateResp = await handleUpdate({
              updatePathOptions: `/${data?.id}/setChildrenFromItem`,
              displayToast: 'Operação realizada com sucesso!',
              values: { ...resp, idItem: data.idItem },
              refresh: false
            });

            if (!updateResp?.error) {
              setReload(true);
              setFiles([]);
              setImages([]);
              setName('');
              setObservation('');

              handleLoad();
            }
          }
        });
      },
      shouldDisplayWhen: linkedItem && !libraryProps?.isLibrary
    };

    const deleteObj = {
      id: 'delete-item',
      icon: faTrashCan,
      text: 'Excluir',
      type: 'danger',
      onClick: () => {
        handleConfirmModal('deleteItem');
      },
      shouldDisplayWhen: true
    };

    const menuItems = _isMobile ? [updateLibraryObj, deleteObj] : [editItemObj, updateLibraryObj, deleteObj];

    return (
      <>
        <StyledMenu>
          {_isMobile && (
            <DrawerDropdownShareMenu
              urlContext={urlContext}
              id={_id}
              analyticsProps={propsForTrack}
              customEvent="composition-item-shared"
              isMobile
            />
          )}
          {menuItems
            .filter(item => item.shouldDisplayWhen)
            .map(item => (
              <Menu.Item key={item.id}>
                <Button
                  $justifyContent="flex-start"
                  fullWidth
                  onClick={item.onClick}
                  id={item.id}
                  text
                  type={item.type}
                >
                  <Div width={spaces.space3} height={spaces.space3} align="center" justify="center">
                    <FontAwesomeIcon icon={item.icon} />
                  </Div>
                  <Div $fullWidth style={{ fontSize: fonts.sizeSm }}>
                    {item.text}
                  </Div>
                </Button>
              </Menu.Item>
            ))}
        </StyledMenu>
      </>
    );
  };

  const headerComponents = [
    {
      icon: faMessage,
      title: 'Comentários',
      hasBadge: true,
      badgeCount: data?.comments?.length || 0,
      showOnMobile: true,
      shouldDisplayWhen: !isTemplate && !isUserAnonymous && isEdit && !libraryProps?.isLibrary && !readOnly,
      isPressed: showComponents.showComments,
      onClick: () =>
        setShowComponents({
          ...showComponents,
          showComments: !showComponents.showComments
        })
    },
    {
      icon: faShareNodes,
      title: 'Compartilhar',
      menu: () => (
        <DrawerDropdownShareMenu
          urlContext={urlContext}
          id={_id}
          analyticsProps={propsForTrack}
          customEvent="composition-item-shared"
        />
      ),
      isOpen: showComponents?.showShareDropdown,
      shouldDisplayWhen: !isTemplate && !isUserAnonymous && isEdit && !onlyLibraryFields,
      onClick: v => setShowComponents({ ...showComponents, showShareDropdown: v })
    },
    {
      icon: faEllipsis,
      title: 'Opções',
      menu: editOptionsMenu,
      showOnMobile: true,
      isOpen: showComponents?.showOptions,
      shouldDisplayWhen: isEdit && !isCustomer && !onlyLibraryFields && !readOnly,
      onClick: v => setShowComponents({ ...showComponents, showOptions: v })
    },
    {
      icon: faXmark,
      title: 'Fechar',
      onClick: () => onClose(false)
    }
  ];

  const sections = [
    {
      key: '0',
      title: 'Valores',
      component: (
        <Div padding={spaces.space2} $fullWidth direction="column" align="inherit" gap={spaces.space2}>
          <Div direction="column" align="inherit" gap={spaces.space1}>
            <Subtitle>Valores unitários</Subtitle>
            {!isEdit && (
              <Div gap={spaces.space1} align="center">
                <Switch checked={!isSimple} onChange={val => setIsSimple(!val)} size="small" />
                <Paragraph type="small" color={colors.neutral500}>
                  Definir itens
                </Paragraph>
                <TooltipIcon
                  icon={faInfoCircle}
                  iconColor={colors.primary500}
                  tooltipProps={{ placement: 'right' }}
                  text="Ao habilitar essa opção, você poderá detalhar os itens (produtos,
                    serviços e composições) existentes dentro dessa composição."
                />
              </Div>
            )}
          </Div>
          <CompositionItems
            id={_id}
            model={model}
            list={listItems}
            setListItems={setListItems}
            handleCreate={_handleCreateItems}
            handleLoadItems={handleReloadCompositionItems}
            setOpenCatalog={setSecondDrawer}
            setExtraDrawerStyle={setExtraDrawerStyle}
            isCustomer={isCustomer}
            isLibrary={libraryProps?.isLibrary}
            idReference={idReference}
            renderColumns={renderColumns}
            readOnly={readOnly}
            isTemplate={isTemplate}
            idCompany={idCompany}
            isSimple={isSimple}
            isEdit={isEdit}
            externalTriggerProps={{
              triggerSubmit,
              triggerLink,
              setTriggerLink,
              setTriggerSubmit,
              refreshComposition,
              setRefreshComposition,
              setEditOrCreateComposition,
              setTouched,
              touched,
              setPreventSave
            }}
            data={mergedCompositionItemsData}
            hideProductService={model === 'item'}
            {...props}
          />
        </Div>
      )
    },
    ...(idReference && changeableData?.id && !isTemplate
      ? [
          {
            key: '1',
            title: 'Anexos',
            component: (
              <Div padding={spaces.space2} $fullWidth>
                <ItemAttachmentContent
                  data={{ files, observation }}
                  setFiles={setFiles}
                  setObservation={setObservation}
                  readOnly={isCustomer || readOnly}
                  idRefurbish={idReference}
                  hasDescription={false}
                />
              </Div>
            )
          }
        ]
      : []),
    ...(!onlyLibraryFields && (!isCustomer || renderColumns?.idSupplier)
      ? [
          {
            key: '2',
            title: 'Dados do fornecedor',
            component: (
              <Div padding={spaces.space2} direction="column" $fullWidth align="flex-start">
                {_isMobile ? (
                  <Div padding={`0 0 ${spaces.space2} 0`}>
                    <Title> Dados do fornecedor</Title>
                  </Div>
                ) : null}
                <Description
                  title="Dados bancários"
                  mapping={mappingDescriptionConf.bank}
                  column={_isMobile ? 1 : 3}
                  data={data?.supplier || {}}
                  background={null}
                  className="form-component"
                  noPadding
                />
                <Description
                  style={{ paddingTop: spaces.space2, borderTop: `1px solid ${colors.neutral100}` }}
                  title="Dados da empresa"
                  mapping={mappingDescriptionConf.supplier}
                  column={_isMobile ? 1 : 3}
                  data={data?.supplier || {}}
                  background={null}
                  noPadding
                  className="form-component"
                  extra={
                    hasSupplier && !readOnly && !isCustomer ? (
                      <Button text onClick={() => setEditSupplierId(data?.supplier?.id)}>
                        <FontAwesomeIcon icon={faPenToSquare} color={colors.primary600} />
                      </Button>
                    ) : null
                  }
                />
              </Div>
            )
          }
        ]
      : [])
  ];

  return (
    <Drawer
      open
      title={
        <Div gap={spaces.space1}>
          {_isMobile && (
            <Div onClick={onClose} padding={spaces.space1}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Div>
          )}
          <Div width={spaces.space4} height={spaces.space4} align="center" justify="center">
            <FontAwesomeIcon icon={faLayerGroup} color={colors.neutral300} />
          </Div>
          <Title>{onlyLibraryFields ? 'Editar composição na biblioteca' : 'Composição'}</Title>
          {!onlyLibraryFields && !_isMobile && (
            <LinkedItemTag
              linkedItem={linkedItem}
              isLibrary={libraryProps?.isLibrary}
              isEdit={isEdit}
              isSinapi={changeableData?.externalBaseCode}
            />
          )}
        </Div>
      }
      onClose={() => onClose(reload || libraryProps?.isLibrary)}
      width={calcDrawerWidth(showComponents?.showComments, _isMobile, '920px')}
      hideFooter={readOnly}
      footer={drawerFooter}
      closeIcon={false}
      padding="0"
      extra={<DrawerHeaderComponents components={headerComponents} />}
      style={{ transform: `translateX(-${extraDrawerStyle}px)` }}
      loading={loading}
    >
      <ConfirmModal
        open={!!showConfirmContextChangeModal && touched}
        onClose={() => {
          setShowConfirmContextChangeModal(null);
          setEditOrCreateComposition(null);
        }}
        alertInfoPadding={`${spaces.space1} ${spaces.space1}`}
        title="Todos os dados não salvos serão perdidos"
        alertInfo="Ao trocar de composição, todos os dados não salvos serão perdidos"
        preInfoDescription="Deseja continuar?"
        onlySubmit
        onSubmit={() => {
          setTouched(false);
        }}
      />
      <Div direction="row" $fullHeight>
        <Div direction="column" $fullHeight $fullWidth align="flex-start">
          {isEdit && !onlyLibraryFields && breadcrumb.list.length > 1 && (
            <CompositionBreadcrumb
              list={breadcrumb.list}
              onSelectItem={args => {
                return touched
                  ? setShowConfirmContextChangeModal({ onSubmit: handleRemoveBreadcrumb, args })
                  : handleRemoveBreadcrumb(args);
              }}
            />
          )}
          {isEdit && libraryProps?.isLibrary && (
            <WarningBar
              type="light"
              padding={spaces.space1}
              margin={`${spaces.space2} ${spaces.space2} 0 ${spaces.space2}`}
              align="flex-start"
              width={`calc(100% - ${spaces.space4})`}
              style={{ flexShrink: 0 }}
              warning={
                onlyLibraryFields
                  ? // eslint-disable-next-line max-len
                    'Ao editar os campos abaixo, essas informações são atualizadas em todos os orçamentos onde o item aparece.'
                  : // eslint-disable-next-line max-len
                    'Ao editar o nome, código, unidade ou classe, essas informações são atualizadas em todos os orçamentos onde o item aparece.'
              }
            />
          )}
          {!onlyLibraryFields && _isMobile && (
            <Div padding={`${spaces.space2} ${spaces.space2} 0`}>
              <LinkedItemTag
                linkedItem={linkedItem}
                isLibrary={libraryProps?.isLibrary}
                isEdit={isEdit}
                isSinapi={changeableData?.externalBaseCode}
              />
            </Div>
          )}
          <Div $fullWidth>
            <EditableInput
              id="input-name"
              value={name}
              onChange={newName => {
                setName(newName);
              }}
              disabled={isCustomer || linkedItem || readOnly}
              initEdit={!data?.id}
              placeholder={!data?.id ? 'Nome da composição' : '-'}
              style={{
                color: name === '' || name === null || name === undefined ? colors.neutral400 : colors.neutral800
              }}
              className="form-component-name"
              textArea
            />
          </Div>
          {CompositionComponent()}
          <DrawerTabs sections={sections} selectedTab={selectedTab} onTabChange={setSelectedTab} />
        </Div>
        {!isTemplate && data?.id && (
          <DrawerComments
            show={showComponents.showComments}
            onToggle={value => {
              setShowComponents({ ...showComponents, showComments: value });
            }}
            data={changeableData}
            model={model}
            tooltipText="Os comentários são compartilhados entre os módulos de Orçamento e Planejamento.
            Tudo que você comentar aqui também será apresentado no Planejamento"
            isMobile={_isMobile}
          />
        )}
      </Div>
      {editOrCreateComposition === true || showEditLibraryItemModal ? (
        <EditCompositionModal
          idCompany={idCompany}
          model="item"
          id={data?.idItem}
          parentIsComposition
          onlyLibraryFields={showEditLibraryItemModal}
          isTemplate={isTemplate}
          libraryProps={{ ...libraryProps, isLibrary: true }}
          onClose={() => {
            if (showEditLibraryItemModal) {
              setShowEditLibraryItemModal(false);
            } else if (editOrCreateComposition) {
              setEditOrCreateComposition(null);
            }
          }}
          onAfterSubmit={item => {
            if (editOrCreateComposition) {
              if (!isEdit) {
                setListItems(prev => [...prev, parseToListItem(item)]);
                handleLoad(false);
              } else {
                handleCreate({
                  values: {
                    type: itemTypeEnum.composition,
                    idParent: _id,
                    idItem: item?.id,
                    id: item.id
                  },
                  refresh: false,
                  noLoading: true
                }).then(res => {
                  if (!res?.error) {
                    toast.success('Item adicionado na composição com sucesso.');
                    handleLoad(false);
                  }
                });
              }
            }
            if (showEditLibraryItemModal) {
              handleLoad();
              setShowEditLibraryItemModal(false);
            }
          }}
        />
      ) : null}
      {hasSupplier && !hideSupplier && editSupplierId && (
        <EditOrCreateCustomerOrSupplier
          id={editSupplierId}
          model="supplier"
          onClose={() => {
            setEditSupplierId(null);
          }}
          setData={newSupplier => setChangeableData(prev => ({ ...prev, supplier: newSupplier }))}
        />
      )}
    </Drawer>
  );
};

EditCompositionModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  idReference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  initialValues: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
  onAfterSubmit: PropTypes.func,
  model: PropTypes.string,
  readOnly: PropTypes.bool,
  tab: PropTypes.string,
  isTemplate: PropTypes.bool,
  width: PropTypes.number,
  groupedReference: PropTypes.instanceOf(Object),
  renderColumns: PropTypes.instanceOf(Object),
  idCompany: PropTypes.number,
  libraryProps: PropTypes.instanceOf(Object),
  onlyLabor: PropTypes.bool,
  parentIsComposition: PropTypes.bool,
  hideAttachments: PropTypes.bool,
  hideComments: PropTypes.bool,
  options: PropTypes.instanceOf(Object),
  drawerType: PropTypes.string,
  onlyLibraryFields: PropTypes.bool
};

export default memo(EditCompositionModal);
